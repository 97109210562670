import { Route, Routes } from 'react-router-dom';
import App from '../features/app/App';
import { Dashboard } from '../features/dashboard/Dashboard';
import PurchaseOrders from '../features/purchaseOrders/PurchaseOrders';
import TradingPartner from '../features/tradingPartner/TradingPartner';
import Invoices from '../features/invoices/Invoices';
import Shipments from '../features/shipments/Shipments';
import Acknowledgements from '../features/acknowledgements/Acknowledgements';
import DocumentTypeTransactions from '../features/documentTypeTransactions/DocumentTypeTransactions';
import Exceptions from '../features/exceptions/Exceptions';
import { TradingPartners } from '../features/tradingPartners/TradingPartners';
import { Transactions } from '../features/transactions/Transactions';
import { NotificationRecipients } from '../features/notificationRecipients/NotificationRecipients';
import { ClientTransactions } from '../features/clientTransactions/ClientTransactions';
import { ProtectedRoute } from '../components/atoms/ProtectedRoute';
import { useAuth0 } from '@auth0/auth0-react';
import { sec } from '../utils/security';
import { APTEAN_IAM_CLIENT_ID } from '../utils/Constants';
import { useAuth } from 'react-oidc-context';
import UserManagement from '../features/userManagement/UserManagement';
import { ClientBillingList } from '../features/billing/ClientBillingList';

export interface RouteItem {
  name: string;
  path: string;
  i18n: string;
  parentName?: string;
}

export const routes: RouteItem[] = [
  { name: 'dashboard', path: '/', i18n: 'navigation.dashboard' },
  { name: 'tradingPartners', path: 'trading-partners', i18n: 'navigation.tradingPartners' },
  { name: 'exceptions', path: 'exceptions', i18n: 'navigation.exceptions' },
  { name: 'transactionSearch', path: 'transactions', i18n: 'navigation.transactionSearch' },
  { name: 'purchaseOrders', path: 'purchase-orders', i18n: 'navigation.purchaseOrders', parentName: 'CoreTransactions' },
  { name: 'invoices', path: 'invoices', i18n: 'navigation.invoices', parentName: 'CoreTransactions' },
  { name: 'shipments', path: 'shipments', i18n: 'navigation.shipments', parentName: 'CoreTransactions' },
  { name: 'acknowledgements', path: 'acknowledgements', i18n: 'navigation.acknowledgements', parentName: 'CoreTransactions' },
  {
    name: 'warehouseShippingOrder',
    path: 'warehouse-shipping-order',
    i18n: 'navigation.warehouseShippingOrder',
    parentName: '3PLTransactions',
  },
  {
    name: 'warehouseShippingAdvice',
    path: 'warehouse-shipping-advice',
    i18n: 'navigation.warehouseShippingAdvice',
    parentName: '3PLTransactions',
  },
  {
    name: 'warehouseInventoryAdvice',
    path: 'warehouse-inventory-advice',
    i18n: 'navigation.warehouseInventoryAdvice',
    parentName: '3PLTransactions',
  },
  { name: 'stockTransfer', path: 'stock-transfer', i18n: 'navigation.stockTransfer', parentName: '3PLTransactions' },
  { name: 'stockReceipt', path: 'stock-receipt', i18n: 'navigation.stockReceipt', parentName: '3PLTransactions' },
  {
    name: 'purchaseOrderOutbound',
    path: 'purchase-orders-outbound',
    i18n: 'navigation.purchaseOrderOutbound',
    parentName: 'VendorTransactions',
  },
  {
    name: 'invoiceInbound',
    path: 'invoices-inbound',
    i18n: 'navigation.invoiceInbound',
    parentName: 'VendorTransactions',
  },
  {
    name: 'shipmentInbound',
    path: 'shipments-inbound',
    i18n: 'navigation.shipmentInbound',
    parentName: 'VendorTransactions',
  },
  {
    name: 'acknowledgementInbound',
    path: 'acknowledgements-inbound',
    i18n: 'navigation.acknowledgementInbound',
    parentName: 'VendorTransactions',
  },
  // {
  //   name: 'billing',
  //   path: 'client-billing',
  //   i18n: 'navigation.billing',
  //   parentName: 'Admin',
  // },
  { 
    name: 'notificationRecipients', 
    path: 'notification-recipients', 
    i18n: 'navigation.notificationRecipients', 
    parentName: 'Admin' },
  {
    name: 'clientTransactionNotifications',
    path: 'client-transactions',
    i18n: 'navigation.clientTransactionNotifications',
    parentName: 'Admin',
  },
];

export const RoutesCollection = (isApteanSSO?: boolean) => {
  let mainRouteElement = isApteanSSO ? (
    <App />
  ) : (
    <ProtectedRoute
      component={App}
      onRedirecting={() => {
        return <div>Redirecting you to login...</div>;
      }}
    />
  );
  return (
    <Routes>
      <Route path="/" element={mainRouteElement}>
        <Route index element={<Dashboard />} />
        <Route path="user-management" element={<UserManagement />} />
        <Route path="trading-partners">
          <Route index element={<TradingPartners />} />
          <Route path="tradingPartner-:tradingPartnerId">
            <Route index element={<TradingPartner />} />
            <Route path="order-:orderNumber">
              <Route index element={<Transactions />} />
            </Route>
          </Route>
        </Route>
        <Route path="purchase-orders">
          <Route index element={<PurchaseOrders timePeriod="today" />} />
          <Route path="date-:activityDate">
            <Route index element={<PurchaseOrders timePeriod="today" />} />
          </Route>
          <Route path="order-:orderNumber">
            <Route index element={<Transactions />} />
          </Route>
          {
            // <Route path="order-:purchaseOrderId">
            //   <Route index element={<OrderStatus />} />
            //   <Route path="raw-order-detail" element={<OrderDetail />} />
            //   <Route path="date-:activityDate">
            //     <Route index element={<OrderStatus />} />
            //     <Route path="raw-order-detail" element={<OrderDetail />} />
            //   </Route>
            // </Route>
          }
          <Route path="exceptions-filtered">
            <Route index element={<Exceptions filtered />} />
          </Route>
        </Route>
        <Route path="invoices">
          <Route index element={<Invoices timePeriod="today" />} />
          <Route path="date-:activityDate">
            <Route index element={<Invoices timePeriod="today" />} />
          </Route>
          <Route path=":order">
            <Route index element={<Invoices timePeriod="today" />} />
          </Route>
          <Route path="date-:activityDate/:order">
            <Route index element={<Invoices timePeriod="today" />} />
          </Route>
          <Route path="order-:orderNumber">
            <Route index element={<Transactions />} />
          </Route>
          <Route path="exceptions-filtered">
            <Route index element={<Exceptions filtered />} />
          </Route>
        </Route>
        <Route path="shipments">
          <Route index element={<Shipments />} />
          <Route path="date-:activityDate">
            <Route index element={<Shipments />} />
          </Route>
          <Route path=":order">
            <Route index element={<Shipments />} />
          </Route>
          <Route path="date-:activityDate/:order">
            <Route index element={<Shipments />} />
          </Route>
          <Route path="order-:orderNumber">
            <Route index element={<Transactions />} />
          </Route>
          <Route path="exceptions-filtered">
            <Route index element={<Exceptions filtered />} />
          </Route>
        </Route>
        <Route path="acknowledgements">
          <Route index element={<Acknowledgements />} />
          <Route path="date-:activityDate">
            <Route index element={<Acknowledgements />} />
          </Route>
          <Route path=":order">
            <Route index element={<Acknowledgements />} />
          </Route>
          <Route path="date-:activityDate/:order">
            <Route index element={<Acknowledgements />} />
          </Route>
          <Route path="order-:orderNumber">
            <Route index element={<Transactions />} />
          </Route>
        </Route>
        <Route path="warehouse-shipping-order">
          <Route index element={<DocumentTypeTransactions documentType="warehouseShippingOrder" />} />
          <Route path="date-:activityDate">
            <Route index element={<DocumentTypeTransactions documentType="warehouseShippingOrder" />} />
          </Route>
          <Route path=":order">
            <Route index element={<DocumentTypeTransactions documentType="warehouseShippingOrder" />} />
          </Route>
          <Route path="date-:activityDate/:order">
            <Route index element={<DocumentTypeTransactions documentType="warehouseShippingOrder" />} />
          </Route>
          <Route path="order-:orderNumber">
            <Route index element={<Transactions />} />
          </Route>
          <Route path="exceptions-filtered">
            <Route index element={<Exceptions filtered />} />
          </Route>
        </Route>
        <Route path="warehouse-shipping-advice">
          <Route index element={<DocumentTypeTransactions documentType="warehouseShippingAdvice" />} />
          <Route path="date-:activityDate">
            <Route index element={<DocumentTypeTransactions documentType="warehouseShippingAdvice" />} />
          </Route>
          <Route path=":order">
            <Route index element={<DocumentTypeTransactions documentType="warehouseShippingAdvice" />} />
          </Route>
          <Route path="date-:activityDate/:order">
            <Route index element={<DocumentTypeTransactions documentType="warehouseShippingAdvice" />} />
          </Route>
          <Route path="order-:orderNumber">
            <Route index element={<Transactions />} />
          </Route>
          <Route path="exceptions-filtered">
            <Route index element={<Exceptions filtered />} />
          </Route>
        </Route>
        <Route path="warehouse-inventory-advice">
          <Route index element={<DocumentTypeTransactions documentType="warehouseInventoryAdjustmentAdvice" />} />
          <Route path="date-:activityDate">
            <Route index element={<DocumentTypeTransactions documentType="warehouseInventoryAdjustmentAdvice" />} />
          </Route>
          <Route path=":order">
            <Route index element={<DocumentTypeTransactions documentType="warehouseInventoryAdjustmentAdvice" />} />
          </Route>
          <Route path="date-:activityDate/:order">
            <Route index element={<DocumentTypeTransactions documentType="warehouseInventoryAdjustmentAdvice" />} />
          </Route>
          <Route path="order-:orderNumber">
            <Route index element={<Transactions />} />
          </Route>
          <Route path="exceptions-filtered">
            <Route index element={<Exceptions filtered />} />
          </Route>
        </Route>
        <Route path="stock-transfer">
          <Route index element={<DocumentTypeTransactions documentType="stockTransfer" />} />
          <Route path="date-:activityDate">
            <Route index element={<DocumentTypeTransactions documentType="stockTransfer" />} />
          </Route>
          <Route path=":order">
            <Route index element={<DocumentTypeTransactions documentType="stockTransfer" />} />
          </Route>
          <Route path="date-:activityDate/:order">
            <Route index element={<DocumentTypeTransactions documentType="stockTransfer" />} />
          </Route>
          <Route path="order-:orderNumber">
            <Route index element={<Transactions />} />
          </Route>
          <Route path="exceptions-filtered">
            <Route index element={<Exceptions filtered />} />
          </Route>
        </Route>
        <Route path="stock-receipt">
          <Route index element={<DocumentTypeTransactions documentType="stockReceipt" />} />
          <Route path="date-:activityDate">
            <Route index element={<DocumentTypeTransactions documentType="stockReceipt" />} />
          </Route>
          <Route path=":order">
            <Route index element={<DocumentTypeTransactions documentType="stockReceipt" />} />
          </Route>
          <Route path="date-:activityDate/:order">
            <Route index element={<DocumentTypeTransactions documentType="stockReceipt" />} />
          </Route>
          <Route path="order-:orderNumber">
            <Route index element={<Transactions />} />
          </Route>
          <Route path="exceptions-filtered">
            <Route index element={<Exceptions filtered />} />
          </Route>
        </Route>
        <Route path="purchase-orders-outbound">
          <Route index element={<DocumentTypeTransactions documentType="purchaseOrderOutbound" displayValueColumn />} />
          <Route path="date-:activityDate">
            <Route index element={<DocumentTypeTransactions documentType="purchaseOrderOutbound" displayValueColumn />} />
          </Route>
          <Route path=":order">
            <Route index element={<DocumentTypeTransactions documentType="purchaseOrderOutbound" displayValueColumn />} />
          </Route>
          <Route path="date-:activityDate/:order">
            <Route index element={<DocumentTypeTransactions documentType="purchaseOrderOutbound" displayValueColumn />} />
          </Route>
          <Route path="order-:orderNumber">
            <Route index element={<Transactions />} />
          </Route>
          <Route path="exceptions-filtered">
            <Route index element={<Exceptions filtered />} />
          </Route>
        </Route>
        <Route path="invoices-inbound">
          <Route index element={<DocumentTypeTransactions documentType="invoiceInbound" displayValueColumn />} />
          <Route path="date-:activityDate">
            <Route index element={<DocumentTypeTransactions documentType="invoiceInbound" displayValueColumn />} />
          </Route>
          <Route path=":order">
            <Route index element={<DocumentTypeTransactions documentType="invoiceInbound" displayValueColumn />} />
          </Route>
          <Route path="date-:activityDate/:order">
            <Route index element={<DocumentTypeTransactions documentType="invoiceInbound" displayValueColumn />} />
          </Route>
          <Route path="order-:orderNumber">
            <Route index element={<Transactions />} />
          </Route>
          <Route path="exceptions-filtered">
            <Route index element={<Exceptions filtered />} />
          </Route>
        </Route>
        <Route path="shipments-inbound">
          <Route index element={<DocumentTypeTransactions documentType="shipmentInbound" />} />
          <Route path="date-:activityDate">
            <Route index element={<DocumentTypeTransactions documentType="shipmentInbound" />} />
          </Route>
          <Route path=":order">
            <Route index element={<DocumentTypeTransactions documentType="shipmentInbound" />} />
          </Route>
          <Route path="date-:activityDate/:order">
            <Route index element={<DocumentTypeTransactions documentType="shipmentInbound" />} />
          </Route>
          <Route path="order-:orderNumber">
            <Route index element={<Transactions />} />
          </Route>
          <Route path="exceptions-filtered">
            <Route index element={<Exceptions filtered />} />
          </Route>
        </Route>
        <Route path="acknowledgements-inbound">
          <Route index element={<DocumentTypeTransactions documentType="acknowledgementInbound" />} />
          <Route path="date-:activityDate">
            <Route index element={<DocumentTypeTransactions documentType="acknowledgementInbound" />} />
          </Route>
          <Route path=":order">
            <Route index element={<DocumentTypeTransactions documentType="acknowledgementInbound" />} />
          </Route>
          <Route path="date-:activityDate/:order">
            <Route index element={<DocumentTypeTransactions documentType="acknowledgementInbound" />} />
          </Route>
          <Route path="order-:orderNumber">
            <Route index element={<Transactions />} />
          </Route>
          <Route path="exceptions-filtered">
            <Route index element={<Exceptions filtered />} />
          </Route>
        </Route>
        <Route path="exceptions">
          <Route index element={<Exceptions />} />
          <Route path="order-:orderNumber">
            <Route index element={<Transactions />} />
          </Route>
          <Route path="trading-partners">
            <Route index element={<TradingPartners />} />
            <Route path=":tradingPartnerId" />
          </Route>
        </Route>
        <Route path="transactions">
          <Route index element={<Transactions />} />
          <Route path="order-:orderNumber">
            <Route index element={<Transactions />} />
          </Route>
          <Route path="exceptions-filtered">
            <Route index element={<Exceptions filtered />} />
          </Route>
        </Route>
        {
          // can't exclude by role here as page will error on refresh,
          // so logic is added to screen directly to redirect if not
          // role==="admin"
        }
        <Route path="client-billing">
          <Route index element={<ClientBillingList />} />
        </Route>
        <Route path="notification-recipients">
          <Route index element={<NotificationRecipients />} />
        </Route>
        <Route path="client-transactions">
          <Route index element={<ClientTransactions />} />
        </Route>
      </Route>
    </Routes>
  );
};

export const RouteDefinitionsLegacy = () => {
  const { isLoading, error, getAccessTokenSilently } = useAuth0();
  sec.setAccessTokenSilently(getAccessTokenSilently);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  return RoutesCollection(false);
};

export const RouteDefinitions = () => {
  const auth = useAuth();

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }
  if (!auth.isAuthenticated) {
    auth.signinRedirect();
    return <div>Redirecting...</div>;
  }

  const clientAuthId = auth.user?.profile?.aud ? auth.user?.profile?.aud : null;
  const potentialEmail = auth.user?.profile?.email ? auth.user?.profile?.email : null;
  if (!potentialEmail || clientAuthId !== APTEAN_IAM_CLIENT_ID) {
    return <div>Not Authenticated</div>;
  }

  return RoutesCollection(true);
};
