import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Grid, useMediaQuery, Theme, ToggleButton, Switch } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import { useDataGridNoDataText } from '../../utils/hooks/useDataGridNoDataText';

import { Column, IColumnProps, IDataGridOptions } from 'devextreme-react/data-grid';
import dxDataGrid, { ContentReadyEvent } from 'devextreme/ui/data_grid';

import { PageTitle } from '../../components/atoms/PageTitle';
import { LinkCell } from '../../components/atoms/LinkCell';
import { TableTemplate } from '../../components/organisms/TableTemplate';
import { MobileDataCardRow } from '../../components/atoms/MobileDataCardRow';
import { MobileDataCard } from '../../components/molecules/MobileDataCard';
import CardSummaryTemplate from '../../components/organisms/CardSummaryTemplate';

import { selectTenantId } from '../app/AppSlice';
import { selectExceptionFilters } from './ExceptionsSlice';
import { setTradingPartnerName } from '../tradingPartner/TradingPartnerSlice';
import { useGetV1ExceptionsByTenantIdQuery, ExceptionResource } from '../../api/exceptions.api';
import { getDocumentTypeName, capitalizeWithUnderscoreRemoval, buildAIContentDescription } from '../../utils/helpers/functions';
import { ExceptionStatus } from '../../utils/types/type-declarations';
import { Helmet } from 'react-helmet-async';

interface ExceptionsProps {
  filtered?: boolean;
};

const Exceptions: FC<ExceptionsProps> = (props) => {
  const { filtered=false } = props;
  const dispatch = useAppDispatch();
  const isMobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { t: translate } = useTranslation();

  const [isCustomLoading, setIsCustomLoading] = useState(false);
  const [filterByResolvedStatus, setFilterByResolvedStatus] = useState(false);
  const [exceptionsOffset, setExceptionsOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [exceptionsInUse, setExceptionsInUse] = useState<{ items: ExceptionResource[], total: number }>({ items: [], total: 0 });
  const [selectedDataRow, setSelectedDataRow] = useState<ExceptionResource | undefined>(undefined);
  
  const tenantId = useAppSelector(selectTenantId);
  const filters = useAppSelector(selectExceptionFilters);

  // Query for all open exceptions regardless of date
  const { data: exceptionsData, isLoading, isFetching } = useGetV1ExceptionsByTenantIdQuery(
    {
      tenantId: tenantId as string,
      limit: pageSize,
      offset: exceptionsOffset,
      orderNumber: filtered ? filters?.orderNumberFilter : undefined,
      docType: filtered ? filters?.docTypeFilter : undefined,
      tradingPartnerId: filtered ? filters?.tradingPartnerIdFilter : undefined,
      status: filterByResolvedStatus ? ExceptionStatus.ResolvedError : undefined,
    },
    {
      skip: !tenantId,
    },
  );

  useEffect(() => {
    setExceptionsInUse({ items: exceptionsData?.items || [], total: exceptionsData?.totalCount || 0 });
    
  }, [exceptionsData]);

  
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }

  const intlDateTimeFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });

  const dataGridColumns: IColumnProps[] = [
    {
      dataField: 'tradingPartnerName',
      caption: translate('grids.tradingPartner'),
      minWidth: '150px',
      cellRender: (params) => <LinkCell params={{ ...params, data: { id: params.data.tradingPartnerId }, to: '/trading-partners' }} extraPreText='tradingPartner-' onCellClick={() => {
        dispatch(setTradingPartnerName(params.data.tradingPartnerName));
      }} />
    },
    {
      dataField: 'documentType',
      caption: translate('grids.documentType'),
      minWidth: '150px',
      calculateCellValue: (rowData: ExceptionResource) => {
        return getDocumentTypeName(rowData.documentType);
      }
    },
    {
      dataField: 'documentNumber',
      caption: translate('grids.documentReference'),
      minWidth: '100px',
      visible: false //hiding this column for now as typically don't have this value
    },
    {
      dataField: 'purchaseOrderNumber',
      caption: translate('grids.orderNumber'),
      minWidth: '125px',
      cellRender: (params) => {
        if (params.value)
          return <LinkCell params={{ value: params.value, data: { id: params.data.purchaseOrderNumber }, to: '/exceptions' }} extraPreText="order-" />
        else return params.value

      },
    },
    {
      dataField: 'date',
      caption: translate('grids.date'),
      minWidth: '100px',
      calculateCellValue: (rowData: { date: string }) => new Date(rowData.date),
      format: options
    },
    {
      dataField: 'status',
      caption: translate('grids.status'),
      minWidth: '100px',
      calculateCellValue: (rowData: { status: string }) => { return !!rowData.status ? capitalizeWithUnderscoreRemoval(rowData.status, " ") : '' },

    },
    {
      dataField: 'originalFileName',
      caption: translate('grids.fileName'),
      minWidth: '200px',
      //calculateCellValue: (rowData: { originalFileName: string }) => { return rowData.originalFileName },
    },
    // {
    //   dataField: 'description',
    //   caption: translate('grids.description'),
    //   calculateCellValue: (rowData: { description: string }) => { return !!rowData.description ? rowData.description.charAt(0).toUpperCase() + rowData.description.slice(1) : '' },
    // },
  ];


  /* 
    Override of grid function to ContentReadyEvent
    of the grid to be able to call our own spinner
    to display during load 
  */
  const onContentReady = (e: ContentReadyEvent) => {
    // get the DataGrid instance
    let dataGrid = e.component as dxDataGrid;
    
    // when data is being loaded or fetched, 
    // begin custom loading to display spinner 
    // to indicate grid is loading 
    if ((isLoading || isFetching)) {
        if (!isCustomLoading)  {
            dataGrid.beginCustomLoading('');
            setIsCustomLoading(true); 
        }
    } else if (isCustomLoading) {
        // when the rows are actually loaded and we are still in custom loading,
        // then call to end loading, which hides the spinner 
        dataGrid.endCustomLoading();
        setIsCustomLoading(false); 
        // when paging, if user has scrolled to bottom, move scroll back to top
        var scrollable = dataGrid.getScrollable();  
        //scroll to top  
        scrollable.scrollTo(0);
    }
  };

  const gridOptions: IDataGridOptions = {
    dataSource: exceptionsData?.items,
    columnAutoWidth: true,
    rowAlternationEnabled: true,
    wordWrapEnabled: true,
    height: '75vh',
    keyExpr: "id",
    sorting: {
      mode: 'none',
    },
    pager: {
      visible: false,
    },
    paging: {
        enabled: false,
    },
    noDataText: useDataGridNoDataText(isLoading || isFetching),
    onContentReady: onContentReady,
  };

  let paginationInfo = {
    totalItems: exceptionsInUse.total,
    currentPage: (exceptionsOffset / pageSize),
    pageSize: pageSize,
    onPageChange: (event: any, page: number) => {
        setExceptionsOffset(page * pageSize);
    },
    pageSizeOptions: [10, 25, 50, 100, 200],
    onPageSizeChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setPageSize(parseInt(event.target.value, 10));
        setExceptionsOffset(0);
    }
  };

  const getCards = () => {
    // skip column for po number because it is in the title and skip file name because it is too long
    // to display in this mobile view
    return exceptionsData?.items?.map((exception: ExceptionResource, i) => (
      <MobileDataCard key={i} title={!!(exception?.purchaseOrderNumber) ? (exception?.purchaseOrderNumber) : ''} to={exception.purchaseOrderNumber ? `\order-${exception.purchaseOrderNumber}` : undefined}>
          {dataGridColumns.map((column, index) =>
            !(index === 3 || index === 6) ?
              <MobileDataCardRow
                key={index}
                title={column.caption}
                value={column.calculateCellValue ? column.calculateCellValue(exception) : ''}
                valueFormatter={column.format ? intlDateTimeFormatter : undefined}
                linkTo={
                  column.dataField === 'tradingPartnerName' ?
                    column.cellRender ?
                      column.cellRender({ data: { tradingPartnerId: exception.tradingPartnerId }, value: exception.tradingPartnerName })
                      : undefined
                    : undefined
                }
              /> : ''
          )}
        </MobileDataCard>
    ));
  };

  const getGridColumns = () => {
    return dataGridColumns.map((col, index) => (
      <Column key={index} {...col} />
    ));
  };

  const getDisplayText = (): string => {
      return translate('exceptions.title');
  };

  const getDisplaySubText = (): string => {
    return filterByResolvedStatus ? translate('exceptions.resolved') : translate('exceptions.open');  
  }

  const onFilterStatusChanged = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFilterByResolvedStatus(checked);
  };

  const getFilterStatusSwitch = () => {
    return (
        <Grid mb={1}>
          {translate('exceptions.open')} <Switch checked={filterByResolvedStatus} onChange={onFilterStatusChanged}/> {translate('exceptions.resolved')}
        </Grid>
    );
  }; 

  return (
    <>
      <Helmet>
        <meta name="ai:viewId" content="exceptions"></meta>
        <meta name="ai:viewDescription" content={buildAIContentDescription(`${getDisplayText()} - ${getDisplaySubText()}`)}></meta>
      </Helmet>
      {isMobileScreen ? (
        <Grid>
          {getFilterStatusSwitch()}
          <CardSummaryTemplate
              isBusy={isLoading || isFetching}
              cards={getCards()}
              pageTitleText={getDisplayText()}
              gridPagination={paginationInfo} 
          />
        </Grid>
      ) : (
          <Grid>
              <Grid mb={1} container justifyContent="space-between">
                  <PageTitle title={getDisplayText()} />
              </Grid>
              {getFilterStatusSwitch()}
              <TableTemplate gridOptions={gridOptions} gridPagination={paginationInfo} useLessRowPadding>
                {getGridColumns()}
              </TableTemplate>
          </Grid>
      )}
    </>
  );
};

export default Exceptions;
