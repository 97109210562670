import { Interval } from 'luxon';
import { RootState } from '../../app/store';
import { DatePeriod } from '../../static/datePeriods';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TradePartner } from '../../utils/types/type-declarations';
import { PurchaseOrderResource } from '../../api/purchaseOrders.api';
import { getDateFilterFromLocalStorage, getDatePeriodString, getDateRangeForPeriod } from '../../utils/helpers/functions';
import { ToastConfig } from '../../utils/Constants';
import { PortalUserCustomerResource, PortalUserResource, UserRole } from '../../api/users.api';

export interface AppState {
  navigationMenuOpen: boolean;
  searchTerm: string;
  displaySearchResults: boolean;
  searchResults: PurchaseOrderResource[];
  tradingPartners: TradePartner[];
  searchResultsDialogOpen: boolean;
  selectedOrder: PurchaseOrderResource | null;
  dateFilterModalOpen: boolean;
  dateFilterSelection: DatePeriod;
  dateFilterInterval: string;
  dateFilterTitle: string;
  viewerUser?: PortalUserResource;
  tenantId?: string;
  selectedCustomer?: PortalUserCustomerResource | null;
  customerId?: string | null;
  customerName: string;
  tenantName: string;
  globalSearchDrawerOpen: boolean;
  userCustomerTenantRole?: UserRole;
  toastConfig?: ToastConfig;
  userHasSingleCustomerSingleTenant: boolean;
  isUserCustomerAdmin: boolean;
  isUserCustomerTenantAdmin?: boolean | undefined;
}

const initialState: AppState = {
  navigationMenuOpen: false,
  searchTerm: '',
  searchResults: [],
  selectedOrder: null,
  tradingPartners: [],
  displaySearchResults: false,
  searchResultsDialogOpen: false,
  dateFilterModalOpen: false,
  dateFilterSelection: getDateFilterFromLocalStorage().period || DatePeriod.Quarter,
  dateFilterTitle: getDateFilterFromLocalStorage().title || getDatePeriodString(DatePeriod.Quarter),
  dateFilterInterval: getDateFilterFromLocalStorage().interval?.toISO() || getDateRangeForPeriod(DatePeriod.Quarter).toISO(),
  globalSearchDrawerOpen: false,
  tenantId: undefined,
  customerName: '',
  tenantName: '',
  userCustomerTenantRole: undefined,
  toastConfig: undefined,
  viewerUser: undefined,
  userHasSingleCustomerSingleTenant: false,
  isUserCustomerAdmin: false,
  isUserCustomerTenantAdmin: undefined,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    toggleNavigationMenu: state => {
      // eslint-disable-next-line no-param-reassign
      state.navigationMenuOpen = !state.navigationMenuOpen;
    },
    toggleSearchResultsDialog: state => {
      // eslint-disable-next-line no-param-reassign
      state.searchResultsDialogOpen = !state.searchResultsDialogOpen;
    },
    changeSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setSearchResults: (state, action: PayloadAction<PurchaseOrderResource[]>) => {
      state.searchResults = action.payload;
    },
    setSelectedOrder: (state, action: PayloadAction<PurchaseOrderResource | null>) => {
      state.selectedOrder = action.payload;
    },
    clearSearchResults: state => {
      state.searchResults = [];
    },
    setDisplaySearchResults: (state, action: PayloadAction<boolean>) => {
      state.displaySearchResults = action.payload;
    },
    setViewerUser: (state, action: PayloadAction<PortalUserResource>) => {
      state.viewerUser = action.payload;
    },
    setCustomer: (state, action: PayloadAction<PortalUserCustomerResource | null>) => {
      state.selectedCustomer = action.payload;
      if (action.payload) {
        state.customerId = action.payload.portalCustomerId;
        state.customerName = action.payload.portalCustomer?.name ?? '';
        state.isUserCustomerAdmin = action.payload.role === 'admin';
      } else {
        state.customerId = undefined;
        state.customerName = '';
        state.isUserCustomerAdmin = false;
      }
    },
    setCustomerId: (state, action: PayloadAction<string | undefined>) => {
      state.customerId = action.payload;
    },
    setTenantId: (state, action: PayloadAction<string | undefined>) => {
      state.tenantId = action.payload;
    },
    setTenantName: (state, action: PayloadAction<string>) => {
      state.tenantName = action.payload;
    },
    setUserCustomerTenantRole: (state, action: PayloadAction<UserRole | undefined>) => {
      if (action.payload) {
        state.userCustomerTenantRole = action.payload;
        state.isUserCustomerTenantAdmin = action.payload === 'admin';
      } else {
        state.userCustomerTenantRole = undefined;
        state.isUserCustomerTenantAdmin = undefined;
      }
    },
    setUserHasSingleCustomerSingleTenant: (state, action: PayloadAction<boolean>) => {
      state.userHasSingleCustomerSingleTenant = action.payload;
    },
    fetchDateFilterModalOpen: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.dateFilterModalOpen = action.payload;
    },
    fetchDateFilterSelection: (state, action: PayloadAction<DatePeriod>) => {
      // eslint-disable-next-line no-param-reassign
      state.dateFilterSelection = action.payload;
    },
    fetchDateFilterInterval: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.dateFilterInterval = action.payload;
    },
    fetchDateFilterTitle: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.dateFilterTitle = action.payload;
    },
    fetchGlobalSearchDrawerOpen: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.globalSearchDrawerOpen = action.payload;
    },
    setToastConfig: (state, action: PayloadAction<ToastConfig | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.toastConfig = action.payload;
  },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {},
});

export const selectSearchTerm = (state: RootState): string => state.app.searchTerm;
export const selectTradingPartners = (state: RootState) => state.app.tradingPartners;
export const selectNavigationMenuOpen = (state: RootState) => state.app.navigationMenuOpen;
export const selectSearchResults = (state: RootState): PurchaseOrderResource[] => state.app.searchResults;
export const selectSearchResultsDialogOpen = (state: RootState) => state.app.searchResultsDialogOpen;
export const selectDisplaySearchResults = (state: RootState): boolean => state.app.displaySearchResults;
export const selectSelectedPurchaseOrder = (state: RootState): PurchaseOrderResource | null => state.app.selectedOrder;
export const selectViewerUser = (state: RootState): PortalUserResource | undefined => state.app.viewerUser;
export const selectCustomer = (state: RootState) => state.app.selectedCustomer;
export const selectCustomerId = (state: RootState) => state.app.customerId;
export const selectCustomerName = (state: RootState): string => state.app.customerName;
export const selectTenantId = (state: RootState): string | undefined => state.app.tenantId;
export const selectTenantName = (state: RootState): string => state.app.tenantName;
export const selectUserCustomerTenantRole = (state: RootState): UserRole | undefined => state.app.userCustomerTenantRole;
export const selectDateFilterTitle = (state: RootState) => state.app.dateFilterTitle;
export const selectDateFilterSelection = (state: RootState) => state.app.dateFilterSelection;
export const selectDateFilterModalOpen = (state: RootState) => state.app.dateFilterModalOpen;
export const selectGlobalSearchDrawerOpen = (state: RootState): boolean => state.app.globalSearchDrawerOpen;
export const selectDateFilterInterval = (state: RootState) => Interval.fromISO(state.app.dateFilterInterval);
export const selectToastConfig = (state: RootState): ToastConfig | undefined => state.app.toastConfig;
export const selectUserHasSingleCustomerSingleTenant = (state: RootState): boolean => state.app.userHasSingleCustomerSingleTenant;
export const selectIsUserCustomerAdmin = (state: RootState) => state.app.isUserCustomerAdmin;
export const selectIsUserCustomerTenantAdmin = (state: RootState) => state.app.isUserCustomerTenantAdmin;

export const {
  setTenantId,
  setCustomer,
  setCustomerId,
  setTenantName,
  setSearchResults,
  setSelectedOrder,
  setUserCustomerTenantRole,
  changeSearchTerm,
  clearSearchResults,
  toggleNavigationMenu,
  fetchDateFilterTitle,
  fetchDateFilterInterval,
  setDisplaySearchResults,
  fetchDateFilterModalOpen,
  fetchDateFilterSelection,
  toggleSearchResultsDialog,
  fetchGlobalSearchDrawerOpen,
  setToastConfig,
  setViewerUser, 
  setUserHasSingleCustomerSingleTenant
} = appSlice.actions;

export default appSlice.reducer;
