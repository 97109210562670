import { Grid } from '@mui/material';
import { NormalizedDocumentType, PeriodicActivityResource, TransactionTotalResource, useGetV1DashboardTopTradingPartnersQuery } from '../../api/dashboard.api';
import { PageTitleProps } from '../atoms/PageTitle';
import { DataCardProps } from '../molecules/DataCard';
import TopTradingPartnersChart from '../molecules/TopTradingPartnersChart';
import DashboardTemplate from '../molecules/DashboardTemplate';
import ActivityCoreChart from './ActivityCoreChart';
import { Interval } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getDocTypeAmount, getDocTypeCount, getDocTypeCurrency, mapCoreActivityData, mapTopTradingPartners } from '../../features/dashboard/DashboardAPI';
import { getSummaryTotalDisplayValue } from '../../utils/helpers/currencyFormatUtil';



interface DashboardCoreTemplateProps {
  isBusy?: boolean;
  tenantId?: string;
  dateFilterInterval: Interval;
  dateFilterTitle: string;
  transactionsData?: TransactionTotalResource[];
  periodicActivityData?: PeriodicActivityResource[];
}

const DashboardCoreTemplate: React.FC<DashboardCoreTemplateProps> = props => {
  const { tenantId, dateFilterInterval, dateFilterTitle, transactionsData, periodicActivityData, isBusy=false } = props;
  
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  
  const TOP_TRADING_PARTNERS_CHART_COUNT = 4;
  
  const { data: topTradingPartnersData, isLoading: isTradingPartnersLoading, isFetching: isTradingPartnersFetching } = useGetV1DashboardTopTradingPartnersQuery(
    {
      tenantId: tenantId as string,
      startDate: dateFilterInterval.start.toISO(),
      endDate: dateFilterInterval.end.toISO(),
      count: TOP_TRADING_PARTNERS_CHART_COUNT,
    },
    {
      skip: !tenantId,
    },
  );

  const getCurrencyDisplay = (docType: NormalizedDocumentType) => {
    let currency = getDocTypeCurrency(transactionsData, docType);
    let total = getDocTypeAmount(transactionsData, docType);
    let formattedDisplay = getSummaryTotalDisplayValue(total, currency);
    return formattedDisplay;
  };

  const purchaseOrders: PageTitleProps = {
    title: translate('purchaseOrders.title'),
  };
  const invoices: PageTitleProps = {
    title: translate('invoices.title'),
  };
  const shipments: PageTitleProps = {
    title: translate('shipments.title'),
  };
  const acknowledgements: PageTitleProps = {
    title: translate('acknowledgements.title'),
  };

  const purchaseOrdersCard: DataCardProps = {
    header: dateFilterTitle,
    label1: translate('purchaseOrders.title') as string,
    value1: `${getDocTypeCount(transactionsData, 'purchaseOrder')}`,
    label2: translate('cardSummary.totalValue') as string,
    value2: getCurrencyDisplay('purchaseOrder'),
    onClick: () => {
      navigate('/purchase-orders');
    },
  };
  const invoicesCard: DataCardProps = {
    header: dateFilterTitle,
    label1: translate('invoices.title') as string,
    value1: `${getDocTypeCount(transactionsData, 'invoice')}`,
    label2: translate('cardSummary.totalValue') as string,
    value2: getCurrencyDisplay('invoice'),
    onClick: () => {
      navigate('/invoices');
    },
  };
  const shipmentsCard: DataCardProps = {
    header: dateFilterTitle,
    label1: translate('shipments.title') as string,
    value1: `${getDocTypeCount(transactionsData, 'shipment')}`,
    onClick: () => {
      navigate('/shipments');
    },
    keepDashStyle: true
  };
  const acknowledgmentsCard: DataCardProps = {
    header: dateFilterTitle,
    label1: translate('acknowledgements.title') as string,
    value1: `${getDocTypeCount(transactionsData, 'acknowledgement')}`,
    onClick: () => {
      navigate('/acknowledgements');
    },
    keepDashStyle: true
  };

  const topTradingPartners = mapTopTradingPartners(topTradingPartnersData);
  const activityData = mapCoreActivityData(periodicActivityData);

  const onChartClick = () => {
    navigate('/trading-partners');
  };

  const onTradingPartnerClick = (id: string) => {
    navigate(`/trading-partners/tradingPartner-${id}`);
  };

  const onActivityClick = (docType: NormalizedDocumentType, activityDate?: string) => {
    const datePath = activityDate ? `/date-${activityDate}` : "";
    
    let navigatePath = "";
    if (docType === "purchaseOrder") {
      navigatePath = '/purchase-orders';
    }
    else if (docType === "invoice") {
      navigatePath = '/invoices';
    }
    else if (docType === "shipment") {
      navigatePath = '/shipments';
    }
    else if (docType === "acknowledgement") {
      navigatePath = '/acknowledgements';
    }

    if (navigatePath) {
      // navigate to the given docType's list
      navigate(`${navigatePath}${datePath}`);
    }
  };

  const getChartContent = () => {
    return (
      <>
        <Grid item container xs={12} md={6} justifyContent="center">
          <TopTradingPartnersChart
            data={topTradingPartners}
            onChartClick={onChartClick}
            onTradingPartnerClick={onTradingPartnerClick}
          />
        </Grid>
        <Grid item container xs={12} md={6} justifyContent="center">
          <ActivityCoreChart 
            data={activityData} 
            dateFilterTitle={dateFilterTitle} 
            onActivityClick={onActivityClick}
          />
        </Grid>
      </>
    );
  };

  return (
      <DashboardTemplate
        isBusy={isBusy || isTradingPartnersLoading || isTradingPartnersFetching}
        pageTitles={[purchaseOrders, invoices, shipments, acknowledgements]}
        dataCards={[purchaseOrdersCard, invoicesCard, shipmentsCard, acknowledgmentsCard]}
        charts={getChartContent()}
      />
  );
};

export default DashboardCoreTemplate;
