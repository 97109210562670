import { Grid } from '@mui/material';
import { NormalizedDocumentType, PeriodicActivityResource, TransactionTotalResource, useGetV1DashboardPeriodicActivityQuery, useGetV1DashboardTopTradingPartnersQuery } from '../../api/dashboard.api';
import { PageTitleProps } from '../atoms/PageTitle';
import ActivityChart from '../molecules/ActivityChart';
import { DataCardProps } from '../molecules/DataCard';
import TopTradingPartnersChart from '../molecules/TopTradingPartnersChart';
import DashboardTemplate from '../molecules/DashboardTemplate';
import { Interval } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getDocTypeAmount, getDocTypeCount, getDocTypeCurrency, map3plActivityData, mapCoreActivityData, mapTopTradingPartners } from '../../features/dashboard/DashboardAPI';
import { getSummaryTotalDisplayValue } from '../../utils/helpers/currencyFormatUtil';
import Activity3plChart from './Activity3plChart';


interface Dashboard3plTemplateProps {
  isBusy?: boolean;
  dateFilterTitle: string;
  transactionsData?: TransactionTotalResource[];
  periodicActivityData?: PeriodicActivityResource[];
}

const Dashboard3plTemplate: React.FC<Dashboard3plTemplateProps> = props => {
  const { dateFilterTitle, transactionsData, periodicActivityData, isBusy=false } = props;
  
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const warehouseShippingOrders: PageTitleProps = {
    title: translate('navigation.warehouseShippingOrder'),
  };
  const warehouseShippingAdvice: PageTitleProps = {
    title: translate('navigation.warehouseShippingAdvice'),
  };
  const warehouseInventoryAdvice: PageTitleProps = {
    title: translate('navigation.warehouseInventoryAdvice'),
  };
  const stockTransfers: PageTitleProps = {
    title: translate('navigation.stockTransfer'),
  };
  const stockReceipts: PageTitleProps = {
    title: translate('navigation.stockReceipt'),
  };
    
  const warehouseShippingOrderCard: DataCardProps = {
    header: dateFilterTitle,
    label1: translate('navigation.warehouseShippingOrder') as string,
    value1: `${getDocTypeCount(transactionsData, 'warehouseShippingOrder')}`,
    onClick: () => {
      navigate('/warehouse-shipping-order');
    },
    keepDashStyle: true,
    style: { height: '100px' }
  };

  const warehouseShippingAdviceCard: DataCardProps = {
    header: dateFilterTitle,
    label1: translate('navigation.warehouseShippingAdvice') as string,
    value1: `${getDocTypeCount(transactionsData, 'warehouseShippingAdvice')}`,
    onClick: () => {
      navigate('/warehouse-shipping-advice');
    },
    keepDashStyle: true,
    style: { height: '100px' }
  };

  const warehouseInventoryAdviceCard: DataCardProps = {
    header: dateFilterTitle,
    label1: translate('navigation.warehouseInventoryAdvice') as string,
    value1: `${getDocTypeCount(transactionsData, 'warehouseInventoryAdjustmentAdvice')}`,
    onClick: () => {
      navigate('/warehouse-inventory-advice');
    },
    keepDashStyle: true,
    style: { height: '100px' }
  };

  const stockTransfersCard: DataCardProps = {
    header: dateFilterTitle,
    label1: translate('navigation.stockTransfer') as string,
    value1: `${getDocTypeCount(transactionsData, 'stockTransfer')}`,
    onClick: () => {
      navigate('/stock-transfer');
    },
    keepDashStyle: true,
    style: { height: '100px' }
  };

  const stockReceiptsCard: DataCardProps = {
    header: dateFilterTitle,
    label1: translate('navigation.stockReceipt') as string,
    value1: `${getDocTypeCount(transactionsData, 'stockReceipt')}`,
    onClick: () => {
      navigate('/stock-receipt');
    },
    keepDashStyle: true,
    style: { height: '100px' }
  };

  const activityData = map3plActivityData(periodicActivityData);

  const onActivityClick = (docType: NormalizedDocumentType, activityDate?: string) => {
    const datePath = activityDate ? `/date-${activityDate}` : "";
    
    let navigatePath = "";
    if (docType === "warehouseShippingOrder") {
      navigatePath = '/warehouse-shipping-order';
    }
    else if (docType === "warehouseShippingAdvice") {
      navigatePath = '/warehouse-shipping-advice';
    }
    else if (docType === "warehouseInventoryAdjustmentAdvice") {
      navigatePath = '/warehouse-inventory-advice';
    }
    else if (docType === "stockTransfer") {
      navigatePath = '/stock-transfer';
    }
    else if (docType === "stockReceipt") {
      navigatePath = '/stock-receipt';
    }

    if (navigatePath) {
      // navigate to the given docType's list
      navigate(`${navigatePath}${datePath}`);
    }
  };

  const getChartContent = () => {
    return (
      <Grid item container xs={12} justifyContent="center">
        <Grid item xs={9}>
          <Activity3plChart 
            data={activityData} 
            dateFilterTitle={dateFilterTitle} 
            onActivityClick={onActivityClick}
          />
        </Grid>
      </Grid>
    );
  };

  return (
      <DashboardTemplate
        isBusy={isBusy}
        pageTitles={[warehouseShippingOrders, warehouseShippingAdvice, warehouseInventoryAdvice, stockTransfers, stockReceipts]}
        dataCards={[warehouseShippingOrderCard, warehouseShippingAdviceCard, warehouseInventoryAdviceCard, stockTransfersCard, stockReceiptsCard]}
        charts={getChartContent()}
      />
  );
};

export default Dashboard3plTemplate;
