import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useDataGridNoDataText } from '../../utils/hooks/useDataGridNoDataText';
import { ToastSeverity } from '../../utils/Constants';

import { Grid, Theme, useMediaQuery } from '@mui/material';
import DataGrid, { Column, IColumnProps, IDataGridOptions } from 'devextreme-react/data-grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { GetV1CustomersEditClientTransactionApiArg, useGetV1CustomersClientTransactionsQuery } from '../../api/customers.api';
import { selectIsUserCustomerTenantAdmin, selectTenantId, setToastConfig } from '../app/AppSlice';
import { clearSave, selectSaveStatus, selectError } from './ClientTransactionsSlice';
import { NotificationType, ClientTransactionResourceExtended, saveClientTransaction } from './ClientTransactionsAPI';

import { MobileDataCardRow } from '../../components/atoms/MobileDataCardRow';
import { PageTitle } from '../../components/atoms/PageTitle';
import { MobileDataCard } from '../../components/molecules/MobileDataCard';
import { TableTemplate } from '../../components/organisms/TableTemplate';
import CardSummaryTemplate from '../../components/organisms/CardSummaryTemplate';
import { Helmet } from 'react-helmet-async';
import { buildAIContentDescription } from '../../utils/helpers/functions';


export const ClientTransactions = () => {
  const isMobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const gridRef = useRef<DataGrid | null>(null);

  const [transactionNotificationsData, setTransactionNotificationsData] = useState<ClientTransactionResourceExtended[] | undefined>(undefined);
    
  const tenantId = useAppSelector(selectTenantId);
  const isUserTenantAdmin = useAppSelector(selectIsUserCustomerTenantAdmin);
  const saveStatus = useAppSelector(selectSaveStatus);
  const saveError = useAppSelector(selectError);
  
  // including refetchOnMountOrArgChange to prevent caching of the list since
  // changes could be made in EDI directly that would need refetching of data
  // to be brought into Portal
  const { refetch, data: clientTransactions, isLoading, isFetching } = useGetV1CustomersClientTransactionsQuery(
    {
      tenantId: tenantId as string,
    },
    { 
      refetchOnMountOrArgChange: true,
      skip: !tenantId
    }
  );
  
  const { t: translate } = useTranslation();
  
  useEffect(() => {
    // once roles are loaded, validate the current user has the
    // admin role to view this screen
    // if not, redirect to home screen
    if (isUserTenantAdmin === false) {
      navigate("/");
    }
  }, [isUserTenantAdmin]);

  useEffect(() => {
    // once the client transactions are loaded, extend the data
    // to include the notification type for display in the grid
    // as well as for the dropdown on edit
    const clientTransactionsExtended = clientTransactions?.map((transaction) => {
      let notifyType: NotificationType = NotificationType.disabled;
      if (transaction?.bulkNotify === true) { 
        notifyType = NotificationType.bulkNotify;
      } else if (transaction?.notify === true) {
        notifyType = NotificationType.notify;
      }
      
      const extendedTransaction: ClientTransactionResourceExtended = {
        ...transaction,
        // Add extended properties here
        notificationType: notifyType
      };
      return extendedTransaction;
    });
    setTransactionNotificationsData(clientTransactionsExtended);
  }, [clientTransactions]);

  useEffect(() => {
    if (saveStatus) {
      if (saveStatus.wasSuccessful === true) {
        dispatch(setToastConfig({
          message: saveStatus.message as string,
          severity: ToastSeverity.Success
        }));
        // need to refetch to bring the saved row into the grid
        refetch();
      } else {
        dispatch(setToastConfig({
          message: saveStatus.message as string,
          severity: ToastSeverity.Error
        }));
      } 
      resetSaveStatus();
    } else if(saveError) {
      dispatch(setToastConfig({
        message: saveError.message,
        severity: ToastSeverity.Error
      }));
      resetSaveStatus();
    }
  },[saveStatus, saveError])

  const resetSaveStatus = () => {
    dispatch(clearSave());
  };

  const onSaveClick = (selectedRow: any) => {
    if (selectedRow) {
      var rowToSave = selectedRow as ClientTransactionResourceExtended;
      
      dispatch(saveClientTransaction({
        clientTransactionId: rowToSave.clientTransactionId,
        shouldNotify: rowToSave.notificationType === NotificationType.notify,
        shouldBulkNotify: rowToSave.notificationType === NotificationType.bulkNotify, 
      } as GetV1CustomersEditClientTransactionApiArg))
    }
  };

  const cellOnValueChanged = async (cellEditor: any, onChangeArgs: any) => {
    // since a value changed, check if it was a valid change by triggering validation
    let isCellValid = false;
    await cellEditor.component.getController('validating').validate().done((result: boolean) => {
      //check result. It should be true or false.
      isCellValid = result; 
    }); 

    // after awaiting validation, use the result to enable/disable save button
    // to prevent saving invalid data, using the key from the currently edited cell
    let rowKey = cellEditor.row.key;
    enableDisableSave(isCellValid, rowKey);
  };

  const enableDisableSave = (isRowValid: boolean, rowKey: string) => {
    // using the key, get its containing row
    let grid = gridRef.current?.instance;
    let rowIndex = grid?.getRowIndexByKey(rowKey) ?? -1;
    if (rowIndex >= 0) {
      // then get the command column of that row and find the save command
      let commandsElement = grid?.getCellElement(rowIndex, "commandColumn");
      if (commandsElement) {
        // save will be the first command
        let saveLink = commandsElement.children[0];
        
        // add or remove the disabled class based on validation result
        if (isRowValid) {
          saveLink.classList.remove("dx-state-disabled");
        } else {
          saveLink.classList.add("dx-state-disabled");
        }
      }
    }
  };

  /* 
    Override of grid function to capture onChange event
    of a cell editor to be able to call our own function
    for value change handling
  */
  const onEditorPreparing = (e: any) => {
    const defaultValueChangeHandler = e.editorOptions.onValueChanged;

    e.editorOptions.onValueChanged = function(args: any) {
        defaultValueChangeHandler(args); // Execute the default handler
        cellOnValueChanged(e, args); // Execute custom code
    };

    if (e.parentType === "dataRow" && e.dataField === "notificationType") {
      e.editorOptions.placeholder = `${translate('clientTransactions.select')}...`;
      // turn off search to prevent typing in the dropdown
      e.editorOptions.searchEnabled = false;  
    }
  };

  var notificationOptions = [{
    "value": NotificationType.notify,
    "display": translate('clientTransactions.notify')
  }, {
      "value": NotificationType.bulkNotify,
      "display": translate('clientTransactions.bulkNotify')
  }, {
      "value": NotificationType.disabled,
      "display": translate('clientTransactions.disabled')
  }];

  const calculateName = (rowData: ClientTransactionResourceExtended) => (rowData.transactionName);
  
  const calculateDirection = (rowData: ClientTransactionResourceExtended) => (rowData.transactionDirection ? translate(`clientTransactions.${rowData.transactionDirection}`) : undefined);
  
  const calculateNotificationDisplay = (rowData: ClientTransactionResourceExtended) => {
    let textToDisplay = "";
    notificationOptions.forEach((option) => {
      if (option.value === rowData.notificationType) {
        textToDisplay = option.display;
      }
    });
    return textToDisplay;
  };

  const cellRenderIsActive = (params: any) => {
    const { data } = params;
    return cellRenderBoolean(data?.isTransactionActive);
  };  

  const cellRenderBoolean = (value: boolean | null | undefined) => {
    if (value === true) {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><CheckCircleIcon color="success" /></div>
    }
    return (<div></div>);
  };

  const dataGridColumns: IColumnProps[] = [
    {
      dataField: 'transactionName',
      caption: translate('grids.name'),
      calculateDisplayValue: calculateName,
      allowSorting: true,
      allowEditing: false,
      sortIndex: 0,
      sortOrder: 'desc',
      alignment: 'left',
    },
    { 
      dataField: 'transactionDirection',
      caption: translate('clientTransactions.direction'),
      calculateDisplayValue: calculateDirection,
      allowSorting: true,
      allowEditing: false,
      alignment: 'left',
    },
    { 
      dataField: 'isTransactionActive',
      dataType: 'boolean',
      allowEditing: false,
      caption: translate('grids.active'),
      alignment: 'center',
      cellRender: cellRenderIsActive,
    },
    {
      dataField: 'notificationType',
      caption: translate('clientTransactions.notifications'),
      alignment: 'left',
      calculateDisplayValue: calculateNotificationDisplay,
      lookup: {
        dataSource: notificationOptions,
        displayExpr: "display",
        valueExpr: "value"
      }
    },
    {
      type: 'buttons',
      name: 'commandColumn',
      visible: true,
      minWidth: '80px',
      buttons: [ 
        { 
          name: 'edit',
          hint: translate('grids.edit'),
        },
        {
          name: 'save',
          hint: translate('grids.save'),
          onClick(e: any) {
            onSaveClick(e.row.data);
          },
        },
        { 
          name: 'cancel',
          hint: translate('grids.cancel'),
        },
      ]
    },
  ];

  // calculate the height of the grid based on the content area
  // to allow for scrolling of the grid with the header fixed
  const getGridHeight = () => {
    let contentHeight = document.getElementById('list-content-area')?.clientHeight || 0;
    let titleHeight = document.getElementById('list-title-container')?.clientHeight || 0;
    
    let remainingHeightArea = contentHeight - titleHeight;
    if (remainingHeightArea > 0) {
      return `${remainingHeightArea}px`;
    }
    return '100%';
  };

  const gridOptions: IDataGridOptions = {
    dataSource: transactionNotificationsData,
    loadPanel: {
      enabled: true,
      showIndicator: true,
    },
    keyExpr: 'clientTransactionId',
    rowAlternationEnabled: true,
    height: getGridHeight(),
    scrolling: {
      mode: 'virtual',
    },
    sorting: {
      mode: 'single',
    },
    editing: {
      mode: 'row',
      allowUpdating: true,
      useIcons: true,
    },
    noDataText: useDataGridNoDataText(isLoading || isFetching),
    onEditorPreparing: onEditorPreparing,
  };

  const getDisplayText = (): string => {
    return translate('clientTransactions.title');
  };

  const getMobileCards = () => {
    return transactionNotificationsData?.map((clientTransaction: ClientTransactionResourceExtended) => 
    (
      <MobileDataCard key={clientTransaction?.clientTransactionId}>
        {dataGridColumns.map((column, index) =>
          (
            <MobileDataCardRow
              key={index}
              title={column.caption}
              value={''}
              linkTo={column.calculateDisplayValue ? column.calculateDisplayValue(clientTransaction) : column.cellRender ? column.cellRender({data: clientTransaction}) : undefined}
            />
          ),
        )}
      </MobileDataCard>
    ));
  };

  const getMobileTemplate = () => {
    if (isMobileScreen) {
        return (
            <Grid>
                <CardSummaryTemplate
                    isBusy={isLoading || isFetching}
                    cards={getMobileCards()}
                    pageTitleText={getDisplayText()} />
            </Grid>
        );
    }
    return undefined;
  };

  return (
    <>
    <Helmet>
      <meta name="ai:viewId" content="transactionNotifications"></meta>
      <meta name="ai:viewDescription" content={buildAIContentDescription(translate('clientTransactions.title'))}></meta>
    </Helmet>
    {isMobileScreen ? (
      getMobileTemplate()
    ) : (
      <Grid id='list-content-area' height={'100%'}>
        <Grid id='list-title-container' container mb={1} justifyContent="space-between" alignItems="center">
          <PageTitle title={getDisplayText()} gutterBottom={false} />
        </Grid>
        <TableTemplate ref={gridRef} gridOptions={gridOptions}>
            {dataGridColumns.map((col, i) => (
              <Column key={i} {...col} />
            ))}
        </TableTemplate>
      </Grid>
    )}
    </>
  );
  
};
