import { emptySplitApi as api } from './empty.api';
export const addTagTypes = ['Customer', 'CustomerUsers'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      getV1Customers: build.query<GetV1CustomersApiResponse, GetV1CustomersApiArg>({
        query: () => ({ url: `/v1/customers` }),
        providesTags: ['Customer'],
      }),
      getV1CustomersByTenantIdTransactions: build.query<
        GetV1CustomersByTenantIdTransactionsApiResponse,
        GetV1CustomersByTenantIdTransactionsApiArg
      >({
        query: queryArg => ({
          url: `/v1/customers/${queryArg.tenantId}/transactions`,
          params: {
            startDate: queryArg.startDate,
            endDate: queryArg.endDate,
            orderNumber: queryArg.orderNumber,
            docNumber: queryArg.docNumber,
            tradingPartnerId: queryArg.tradingPartnerId,
            docType: queryArg.docType,
            transactionType: queryArg.transactionType,
            purchaseOrderId: queryArg.purchaseOrderId,
            tradingPartnerName: queryArg.tradingPartnerName,
            hasException: queryArg.hasException,
            erpOrderNumber: queryArg.erpOrderNumber,
            isDocumentResent: queryArg.isDocumentResent,
            offset: queryArg.offset,
            limit: queryArg.limit,
            returnAllRecords: queryArg.returnAllRecords,
          },
        }),
        providesTags: ['Customer'],
      }),
      getV1CustomersByTenantIdExportTransactions: build.query<
        GetV1CustomersByTenantIdExportTransactionsApiResponse,
        GetV1CustomersByTenantIdExportTransactionsApiArg
      >({
        query: queryArg => ({
          url: `/v1/customers/${queryArg.tenantId}/export-transactions`,
          params: {
            startDate: queryArg.startDate,
            endDate: queryArg.endDate,
            orderNumber: queryArg.orderNumber,
            docNumber: queryArg.docNumber,
            tradingPartnerId: queryArg.tradingPartnerId,
            docType: queryArg.docType,
            transactionType: queryArg.transactionType,
            purchaseOrderId: queryArg.purchaseOrderId,
            tradingPartnerName: queryArg.tradingPartnerName,
            hasException: queryArg.hasException,
            erpOrderNumber: queryArg.erpOrderNumber,
            isDocumentResent: queryArg.isDocumentResent,
          },
        }),
        providesTags: ['Customer'],
      }),
      getV1CustomersByTenantIdTransactionSummary: build.query<
        GetV1CustomersByTenantIdTransactionSummaryApiResponse,
        GetV1CustomersByTenantIdTransactionSummaryApiArg
      >({
        query: queryArg => ({
          url: `/v1/customers/${queryArg.tenantId}/transaction-summary`,
          params: { startDate: queryArg.startDate, endDate: queryArg.endDate },
        }),
        providesTags: ['Customer'],
      }),
      getV1CustomersClientBilling: build.query<GetV1CustomersClientBillingApiResponse, GetV1CustomersClientBillingApiArg>({
        query: queryArg => ({ url: `/v1/customers/client-billing`, params: { tenantId: queryArg.tenantId } }),
        providesTags: ['Customer'],
      }),
      getV1CustomersClientTransactions: build.query<
        GetV1CustomersClientTransactionsApiResponse,
        GetV1CustomersClientTransactionsApiArg
      >({
        query: queryArg => ({
          url: `/v1/customers/client-transactions`,
          params: { tenantId: queryArg.tenantId, transactionDirection: queryArg.transactionDirection },
        }),
        providesTags: ['Customer'],
      }),
      getV1CustomersEditClientTransaction: build.query<
        GetV1CustomersEditClientTransactionApiResponse,
        GetV1CustomersEditClientTransactionApiArg
      >({
        query: queryArg => ({
          url: `/v1/customers/edit-client-transaction`,
          params: {
            clientTransactionId: queryArg.clientTransactionId,
            shouldNotify: queryArg.shouldNotify,
            shouldBulkNotify: queryArg.shouldBulkNotify,
            userEmailAddress: queryArg.userEmailAddress,
          },
        }),
        providesTags: ['Customer'],
      }),
      getV1CustomersNotificationRecipients: build.query<
        GetV1CustomersNotificationRecipientsApiResponse,
        GetV1CustomersNotificationRecipientsApiArg
      >({
        query: queryArg => ({ url: `/v1/customers/notification-recipients`, params: { tenantId: queryArg.tenantId } }),
        providesTags: ['Customer'],
      }),
      getV1CustomersDeleteNotificationRecipients: build.query<
        GetV1CustomersDeleteNotificationRecipientsApiResponse,
        GetV1CustomersDeleteNotificationRecipientsApiArg
      >({
        query: queryArg => ({
          url: `/v1/customers/delete-notification-recipients`,
          params: { notificationRecipientsId: queryArg.notificationRecipientsId },
        }),
        providesTags: ['Customer'],
      }),
      getV1CustomersSaveNotificationRecipients: build.query<
        GetV1CustomersSaveNotificationRecipientsApiResponse,
        GetV1CustomersSaveNotificationRecipientsApiArg
      >({
        query: queryArg => ({
          url: `/v1/customers/save-notification-recipients`,
          params: {
            tenantId: queryArg.tenantId,
            description: queryArg.description,
            recipients: queryArg.recipients,
            clientId: queryArg.clientId,
            receivesNotifications: queryArg.receivesNotifications,
            receivesErrors: queryArg.receivesErrors,
            notificationType: queryArg.notificationType,
            notificationRecipientsId: queryArg.notificationRecipientsId,
          },
        }),
        providesTags: ['Customer'],
      }),
      getV1CustomersByCustomerIdUsersAndUserId: build.query<
        GetV1CustomersByCustomerIdUsersAndUserIdApiResponse,
        GetV1CustomersByCustomerIdUsersAndUserIdApiArg
      >({
        query: queryArg => ({ url: `/v1/customers/${queryArg.customerId}/users/${queryArg.userId}` }),
        providesTags: ['CustomerUsers'],
      }),
      deleteV1CustomersByCustomerIdUsersAndUserId: build.mutation<
        DeleteV1CustomersByCustomerIdUsersAndUserIdApiResponse,
        DeleteV1CustomersByCustomerIdUsersAndUserIdApiArg
      >({
        query: queryArg => ({ url: `/v1/customers/${queryArg.customerId}/users/${queryArg.userId}`, method: 'DELETE' }),
        invalidatesTags: ['CustomerUsers'],
      }),
      putV1CustomersByCustomerIdUsersAndUserId: build.mutation<
        PutV1CustomersByCustomerIdUsersAndUserIdApiResponse,
        PutV1CustomersByCustomerIdUsersAndUserIdApiArg
      >({
        query: queryArg => ({
          url: `/v1/customers/${queryArg.customerId}/users/${queryArg.userId}`,
          method: 'PUT',
          body: queryArg.updateUserResource,
        }),
        invalidatesTags: ['CustomerUsers'],
      }),
      getV1CustomersByCustomerIdUsers: build.query<GetV1CustomersByCustomerIdUsersApiResponse, GetV1CustomersByCustomerIdUsersApiArg>({
        query: queryArg => ({ url: `/v1/customers/${queryArg.customerId}/users` }),
        providesTags: ['CustomerUsers'],
      }),
      postV1CustomersByCustomerIdUsers: build.mutation<
        PostV1CustomersByCustomerIdUsersApiResponse,
        PostV1CustomersByCustomerIdUsersApiArg
      >({
        query: queryArg => ({ url: `/v1/customers/${queryArg.customerId}/users`, method: 'POST', body: queryArg.createUserResource }),
        invalidatesTags: ['CustomerUsers'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type GetV1CustomersApiResponse = /** status 200 Success */ CustomerResource[];
export type GetV1CustomersApiArg = void;
export type GetV1CustomersByTenantIdTransactionsApiResponse = /** status 200 Success */ TransactionResourcePaginatedResponse;
export type GetV1CustomersByTenantIdTransactionsApiArg = {
  tenantId: string;
  startDate?: string;
  endDate?: string;
  orderNumber?: string;
  docNumber?: string;
  tradingPartnerId?: string;
  docType?: NormalizedDocumentType;
  transactionType?: string;
  purchaseOrderId?: string;
  tradingPartnerName?: string;
  hasException?: boolean;
  erpOrderNumber?: string;
  isDocumentResent?: boolean;
  offset?: number;
  limit?: number;
  returnAllRecords?: boolean;
};
export type GetV1CustomersByTenantIdExportTransactionsApiResponse = /** status 200 Success */ ApiResponseResource;
export type GetV1CustomersByTenantIdExportTransactionsApiArg = {
  tenantId: string;
  startDate?: string;
  endDate?: string;
  orderNumber?: string;
  docNumber?: string;
  tradingPartnerId?: string;
  docType?: NormalizedDocumentType;
  transactionType?: string;
  purchaseOrderId?: string;
  tradingPartnerName?: string;
  hasException?: boolean;
  erpOrderNumber?: string;
  isDocumentResent?: boolean;
};
export type GetV1CustomersByTenantIdTransactionSummaryApiResponse = /** status 200 Success */ TransactionSummaryResource[];
export type GetV1CustomersByTenantIdTransactionSummaryApiArg = {
  tenantId: string;
  startDate: string;
  endDate: string;
};
export type GetV1CustomersClientBillingApiResponse = /** status 200 Success */ BillingAggregatesResource[];
export type GetV1CustomersClientBillingApiArg = {
  tenantId: string;
};
export type GetV1CustomersClientTransactionsApiResponse = /** status 200 Success */ ClientTransactionResource[];
export type GetV1CustomersClientTransactionsApiArg = {
  tenantId: string;
  transactionDirection?: TransactionDirection;
};
export type GetV1CustomersEditClientTransactionApiResponse = /** status 200 Success */ EdiActionResultApiResponseResource;
export type GetV1CustomersEditClientTransactionApiArg = {
  clientTransactionId: string;
  shouldNotify: boolean;
  shouldBulkNotify: boolean;
  userEmailAddress?: string;
};
export type GetV1CustomersNotificationRecipientsApiResponse = /** status 200 Success */ NotificationRecipientsResource[];
export type GetV1CustomersNotificationRecipientsApiArg = {
  tenantId: string;
};
export type GetV1CustomersDeleteNotificationRecipientsApiResponse = /** status 200 Success */ EdiActionResultApiResponseResource;
export type GetV1CustomersDeleteNotificationRecipientsApiArg = {
  notificationRecipientsId: string;
};
export type GetV1CustomersSaveNotificationRecipientsApiResponse = /** status 200 Success */ EdiActionResultApiResponseResource;
export type GetV1CustomersSaveNotificationRecipientsApiArg = {
  tenantId: string;
  description: string;
  recipients: string;
  clientId?: string;
  receivesNotifications?: boolean;
  receivesErrors?: boolean;
  notificationType?: NotificationType;
  notificationRecipientsId?: string;
};
export type GetV1CustomersByCustomerIdUsersAndUserIdApiResponse = /** status 200 Success */ UserForCustomer;
export type GetV1CustomersByCustomerIdUsersAndUserIdApiArg = {
  customerId: string;
  userId: string;
};
export type DeleteV1CustomersByCustomerIdUsersAndUserIdApiResponse = /** status 204 No Content */ undefined;
export type DeleteV1CustomersByCustomerIdUsersAndUserIdApiArg = {
  customerId: string;
  userId: string;
};
export type PutV1CustomersByCustomerIdUsersAndUserIdApiResponse = /** status 200 Success */ UserForCustomer;
export type PutV1CustomersByCustomerIdUsersAndUserIdApiArg = {
  customerId: string;
  userId: string;
  updateUserResource: UpdateUserResource;
};
export type GetV1CustomersByCustomerIdUsersApiResponse = /** status 200 Success */ UserForCustomer[];
export type GetV1CustomersByCustomerIdUsersApiArg = {
  customerId: string;
};
export type PostV1CustomersByCustomerIdUsersApiResponse = /** status 201 Created */ UserForCustomer;
export type PostV1CustomersByCustomerIdUsersApiArg = {
  customerId: string;
  createUserResource: CreateUserResource;
};
export type CustomerResource = {
  id?: string;
  createdBy?: string | null;
  createdTimestamp?: string;
  modifiedBy?: string | null;
  modifiedTimestamp?: string;
  name?: string | null;
  tenantId?: string;
};
export type NormalizedDocumentType =
  | 'unknown'
  | 'acknowledgement'
  | 'applicationAdvice'
  | 'control'
  | 'creditDebitAdjustment'
  | 'deliveryJustInTime'
  | 'deliverySchedule'
  | 'inventoryAdvice'
  | 'invoice'
  | 'organizationalRelationships'
  | 'payment'
  | 'productTransferResaleReport'
  | 'purchaseOrder'
  | 'purchaseOrderChange'
  | 'remittanceAdvice'
  | 'requestForQuotation'
  | 'requestForRoutingInstructions'
  | 'salesOrderStatus'
  | 'salesOrderStatusRequest'
  | 'shipment'
  | 'shippingSchedule'
  | 'stockReceipt'
  | 'stockTransfer'
  | 'taxcon'
  | 'textMessage'
  | 'warehouseInventoryAdjustmentAdvice'
  | 'warehouseShippingAdvice'
  | 'warehouseShippingOrder'
  | 'purchaseOrderOutbound'
  | 'invoiceInbound'
  | 'shipmentInbound'
  | 'acknowledgementInbound';
export type DocumentAcknowledgementStatus = 'unacknowledged' | 'accepted' | 'rejected' | 'partial_Rejected' | 'not_Applicable';
export type TransactionResource = {
  orderNumber?: string | null;
  tradingPartnerName?: string | null;
  documentType?: NormalizedDocumentType;
  documentNumber?: string | null;
  transactionType?: string | null;
  value?: number | null;
  currency?: string | null;
  date?: string;
  lines?: number | null;
  quantity?: number | null;
  hasExceptions?: boolean | null;
  documentAcknowledgementStatus?: DocumentAcknowledgementStatus;
  documentAcknowledgedTime?: string | null;
  lastResendDate?: string | null;
  resendCount?: number | null;
  erpOrderNumber?: string | null;
  id?: string;
  purchaseOrderId?: string | null;
  tradingPartnerId?: string;
  internalTransactionId?: string | null;
  exceptions?: {
    [key: string]: string;
  } | null;
};
export type TransactionResourcePaginatedResponse = {
  offset?: number;
  totalCount?: number;
  items?: TransactionResource[] | null;
};
export type Error = {
  code?: string | null;
  message?: string | null;
};
export type ApiResponseResource = {
  data?: any | null;
  errors?: Error[] | null;
};
export type CurrencySummaryResource = {
  currency?: string | null;
  quantity?: number;
  amount?: number;
};
export type TransactionSummaryResource = {
  tradingPartnerId?: string;
  tradingPartnerName?: string | null;
  orderSummary?: CurrencySummaryResource[] | null;
  shipmentQuantity?: number | null;
  acknowledgementQuantity?: number | null;
  invoiceSummary?: CurrencySummaryResource[] | null;
};
export type BillingAggregatesResource = {
  billingAggregatesId?: string;
  internalClientCode?: string | null;
  year?: number;
  month?: number;
  billingId?: string;
  totalDocuments?: number;
  totalLines?: number;
  currencyCode?: string | null;
  documentCharges?: number;
  lineCharges?: number;
  totalCharges?: number;
  serviceFee?: number;
  comments?: string | null;
};
export type TransactionDirection = 'inbound' | 'outbound';
export type ClientTransactionResource = {
  clientTransactionId?: string;
  clientId?: string;
  transactionId?: string;
  pipelineId?: string | null;
  isActive?: boolean | null;
  notify?: boolean | null;
  bulkNotify?: boolean | null;
  erpTransactionSchemaId?: string | null;
  notificationFormatterId?: string | null;
  fileNamePattern?: string | null;
  transactionName?: string | null;
  customerTenantId?: string | null;
  isTransactionActive?: boolean | null;
  transactionDirection?: TransactionDirection;
};
export type EdiActionResult = {
  wasSuccessful?: boolean;
  data?: string | null;
  dataDescription?: string | null;
  message?: string | null;
};
export type EdiActionResultApiResponseResource = {
  data?: EdiActionResult;
  errors?: Error[] | null;
};
export type NotificationType = 'email' | 'sms';
export type NotificationRecipientsResource = {
  notificationRecipientsId?: string;
  tenantId?: string;
  clientId?: string;
  description?: string | null;
  recipients?: string | null;
  notificationType?: NotificationType;
  receivesNotifications?: boolean | null;
  receivesErrors?: boolean | null;
};
export type UserRole = 'unknown' | 'admin' | 'editor' | 'reader' | 'primary';
export type PortalSubscriptionResource = {
  portalSubscriptionId?: string;
  portalCustomerId?: string;
  domainService?: string | null;
  tenantId?: string;
  tenantName?: string | null;
  createdTime?: string;
};
export type PortalUserCustomerSubscriptionResource = {
  portalUserCustomerSubscriptionId?: string;
  portalUserCustomerId?: string;
  portalSubscriptionId?: string;
  role?: UserRole;
  portalSubscription?: PortalSubscriptionResource;
};
export type UserForCustomer = {
  id?: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  role?: UserRole;
  subscriptions?: PortalUserCustomerSubscriptionResource[] | null;
};
export type ApiErrorCode = 'unknown' | 'notFound' | 'validation' | 'forbidden';
export type ApiErrorDetail = {
  code?: ApiErrorCode;
  target?: string | null;
  message?: string | null;
};
export type ApiError = {
  code?: ApiErrorCode;
  message?: string | null;
  domain?: string | null;
  details?: ApiErrorDetail[] | null;
};
export type UpdateUserResource = {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  customerRole?: UserRole;
  subscriptionRole?: UserRole;
  subscriptions?: PortalSubscriptionResource[] | null;
};
export type CreateUserResource = {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  customerRole?: UserRole;
  subscriptionRole?: UserRole;
  subscriptions?: PortalSubscriptionResource[] | null;
};
export const {
  useGetV1CustomersQuery,
  useGetV1CustomersByTenantIdTransactionsQuery,
  useGetV1CustomersByTenantIdExportTransactionsQuery,
  useGetV1CustomersByTenantIdTransactionSummaryQuery,
  useGetV1CustomersClientBillingQuery,
  useGetV1CustomersClientTransactionsQuery,
  useGetV1CustomersEditClientTransactionQuery,
  useGetV1CustomersNotificationRecipientsQuery,
  useGetV1CustomersDeleteNotificationRecipientsQuery,
  useGetV1CustomersSaveNotificationRecipientsQuery,
  useGetV1CustomersByCustomerIdUsersAndUserIdQuery,
  useDeleteV1CustomersByCustomerIdUsersAndUserIdMutation,
  usePutV1CustomersByCustomerIdUsersAndUserIdMutation,
  useGetV1CustomersByCustomerIdUsersQuery,
  usePostV1CustomersByCustomerIdUsersMutation,
} = injectedRtkApi;
