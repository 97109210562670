import axios from "axios";
import { AppDispatch } from "../../app/store";
import { getAccessToken } from "../../api/empty.api";
import { setHtmlDocumentStatus, setDocument, setRawEdiDocumentStatus, setRawEdi, setRawEdiErrored, setDocumentName, setDocumentError, setTransactionResendError, setTransactionResendFileName, setTransactionResendFileStatus, setAcknowledgementDocumentStatus } from "./PurchaseOrdersSlice";
import { GetV1PurchaseOrdersAcknowledgementDocumentApiArg, GetV1PurchaseOrdersByTenantIdHtmlDocumentApiArg, GetV1PurchaseOrdersByTenantIdRawEdiDocumentApiArg, GetV1PurchaseOrdersByTenantIdResendDocumentApiArg } from "../../api/purchaseOrders.api";


/** OBSOLETE! **/
// This function can NOT be used as is.  The backend has removed the endpoint since it was no longer functional.
export const getPurchaseOrderRawEDI = (tenantId: string, fileName: string) => 
async (dispatch: AppDispatch): Promise<void> =>{
    // We don't want to cache the response like the autogenerated queries will, so manually get the file.
    let url = `${process.env.REACT_APP_EDI_PORTAL_API_ENDPOINT}/v1/purchase-orders/${tenantId}/file`;
      const token = await getAccessToken();
      if (token) {
        axios({
          method: 'get',
          url: url as string,
          headers: { Authorization: `Bearer ${token}` },
          params: {
            fileId: fileName ? fileName : ''
          }
        })
        .then (response => {
            dispatch(setRawEdi(response.data as string));
        })
        .catch(ex => {
            dispatch(setRawEdiErrored(true));
        })
      }
};

export const getRawEDIDocument = (options: GetV1PurchaseOrdersByTenantIdRawEdiDocumentApiArg) =>
async (dispatch: AppDispatch): Promise<void> => {
  let url = `${process.env.REACT_APP_EDI_PORTAL_API_ENDPOINT}/v1/purchase-orders/${options.tenantId}/raw-edi-document`;
  const token = await getAccessToken();
      if (token) {
        axios({
          method: 'get',
          url: url as string,
          headers: { Authorization: `Bearer ${token}` },
          params: {
            tenantId: options.tenantId,
            transactionId: options.transactionId,
            internalTransactionId: options.internalTransactionId
          }
        })
        .then (response => {
          if (response && response.data) {
            dispatch(setRawEdiDocumentStatus(response.data));
            if (response.data.wasSuccessful === true) {
              dispatch(setDocument(response.data.data as string));
              dispatch(setDocumentName(response.data.dataDescription as string));
            }
          }
      })
      .catch(ex => {
          dispatch(setDocumentError(ex));
      })
    }
};

export const getHtmlDocument = (options: GetV1PurchaseOrdersByTenantIdHtmlDocumentApiArg) =>
async (dispatch: AppDispatch): Promise<void> => {
  let url = `${process.env.REACT_APP_EDI_PORTAL_API_ENDPOINT}/v1/purchase-orders/${options.tenantId}/html-document`;
  const token = await getAccessToken();
      if (token) {
        axios({
          method: 'get',
          url: url as string,
          headers: { Authorization: `Bearer ${token}` },
          params: {
            tenantId: options.tenantId,
            transactionId: options.transactionId,
            internalTransactionId: options.internalTransactionId
          }
        })
        .then (response => {
          if (response && response.data) {
            dispatch(setHtmlDocumentStatus(response.data));
            if (response.data.wasSuccessful === true) {
              dispatch(setDocument(response.data.data as string));
              dispatch(setDocumentName(response.data.dataDescription as string));
            }
          }
      })
      .catch(ex => {
        dispatch(setDocumentError(ex));
      })
    }
};

export const getAcknowledgementDocument = (args: GetV1PurchaseOrdersAcknowledgementDocumentApiArg) =>
  async (dispatch: AppDispatch): Promise<void> => {
    let url = `${process.env.REACT_APP_EDI_PORTAL_API_ENDPOINT}/v1/purchase-orders/acknowledgement-document`;
    const token = await getAccessToken();
        if (token) {
          axios({
            method: 'get',
            url: url as string,
            headers: { Authorization: `Bearer ${token}` },
            params: {
              internalTransactionId: args.internalTransactionId
            }
          })
          .then (response => {
            if (response && response.data) {
              dispatch(setAcknowledgementDocumentStatus(response.data));
              if (response.data.wasSuccessful === true) {
                dispatch(setDocument(response.data.data as string));
                //dispatch(setDocumentName(response.data.dataDescription as string));
              }
            }
        })
        .catch(ex => {
          dispatch(setDocumentError(ex));
        })
      }
  };

export const resendDocument = (options: GetV1PurchaseOrdersByTenantIdResendDocumentApiArg) =>
  async (dispatch: AppDispatch): Promise<void> => {
    let url = `${process.env.REACT_APP_EDI_PORTAL_API_ENDPOINT}/v1/purchase-orders/${options.tenantId}/resend-document`;
  const token = await getAccessToken();
      if (token) {
        axios({
          method: 'get',
          url: url as string,
          headers: { Authorization: `Bearer ${token}` },
          params: {
            tenantId: options.tenantId,
            transactionId: options.transactionId,
            internalTransactionId: options.internalTransactionId
          }
        })
        .then (response => {
          if (response && response.data) {
            dispatch(setTransactionResendFileStatus(response.data));
          }
      })
      .catch(ex => {
        dispatch(setTransactionResendError(ex));
      })
    }
  };