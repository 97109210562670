import React from 'react';
import { ArgumentAxis, CommonSeriesSettings, Font, Label, Legend, Series, Size, Title, Tooltip } from 'devextreme-react/chart';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useTheme } from '@mui/system';
import { Chart } from 'devextreme-react';
import { EventInfo } from 'devextreme/events';
import { createStyles, makeStyles } from '@mui/styles';
import { getFormattedDateTimeString } from '../../utils/helpers/dateTimeUtil';
import { Activity3plChartData, ActivityCoreChartData, ActivityVendorChartData } from '../../features/dashboard/DashboardAPI';


const useStyles = makeStyles(() =>
  createStyles({
    chartContainer: {
      width: '100%',
      height: '100%',
    },
  }),
);

export interface ActivityChartSeriesProps {
  valueField: string;
  name: string;
};

export interface ActivityChartProps {
  dataSource: ActivityCoreChartData[] | ActivityVendorChartData[] | Activity3plChartData[];
  id: string;
  dateFilterTitle: string;
  series: ActivityChartSeriesProps[];
  colors: string[];
  onPointClick: (pointInfo: any) => void;
};

const ActivityChart: React.FC<ActivityChartProps> = props => {
  const { dataSource, id, dateFilterTitle, series, colors, onPointClick } = props;
  const theme = useTheme();  
  const classes = useStyles();
  const { t: translate } = useTranslation();
  
  const onDrawn = (eventInfo: EventInfo<any>) => {
    // add cursor pointer to chart points
    eventInfo.element.querySelectorAll(".dxc-markers rect").forEach((el: any) => {  
      el.style.cursor = "pointer";  
    });    
  };

  const onPointHoverChanged = (pointInfo: any) => {
    // doing hide/show of tooltip rather than just enabling in the Tooltip
    // element to prevent a tooltip from showing when value is 0
    const point = pointInfo.target;
    if (point) {
      if (point.isHovered()) {
        if (point.value > 0) {
          point.showTooltip();
        } else {
          point.hideTooltip();
        }
      } else {
        point.hideTooltip();
      }
    }
  };

  const customizeTooltip = (pointInfo: any) => {
    // format the iso date string to a readable short date string
    var formattedArgument = getFormattedDateTimeString(pointInfo.argument, { format: DateTime.DATE_SHORT });
    return {
      text: `${pointInfo.seriesName}:<br/>${formattedArgument}<br/>${pointInfo.valueText}`,
    };
  };

  const dateFormatter = (object: any) => {
    if (object.value) {
      
      // format the iso date string to a readable medium date string
      let formattedValue = getFormattedDateTimeString(object.value, { format: DateTime.DATE_MED });
      return formattedValue;
    } 
    return object.valueText;
  };

  const mapSeries = () => {
    return series.map((seriesProps: ActivityChartSeriesProps) => {
      return (
        <Series valueField={seriesProps.valueField} name={seriesProps.name} />
      );
    });
  };

  return (
    <Chart 
      id={id}
      className={classes.chartContainer}
      dataSource={dataSource} 
      palette={colors}
      onDrawn={onDrawn}
      onPointHoverChanged={onPointHoverChanged} 
      onPointClick={onPointClick}
    >
      
      <Title text={`${translate('dashboard.activityGraphHeader')} ${dateFilterTitle}`} horizontalAlignment="center">
        <Font color={theme.palette.header.main} weight={400} size="1.5rem" />
      </Title>
      <ArgumentAxis>
        <Label customizeText={dateFormatter}>
          <Font color={theme.palette.header.main} size="1rem" weight={500} />
        </Label>
      </ArgumentAxis>
      <CommonSeriesSettings argumentField="date" type="bar"/>
      {mapSeries()}
      <Tooltip
        customizeTooltip={customizeTooltip}
      />
      <Legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="right" />
    </Chart>
  );
};

export default ActivityChart;
