import { CircularProgress, Grid, Paper, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { PageTitle, PageTitleProps } from '../atoms/PageTitle';
import DataCard, { DataCardProps } from './DataCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    progressSpinnerWrapper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      width: '60px',
      height: '60px',
    },
  }),
);

interface DashboardTemplateProps {
  isBusy?: boolean;
  //PageHeaderProps
  pageTitles: PageTitleProps[];
  //DataCardProps
  dataCards: DataCardProps[];
  // ChartProps
  charts?: React.ReactNode;
}

const DashboardTemplate: React.FC<DashboardTemplateProps> = props => {
  const { pageTitles, dataCards, isBusy=false } = props;
  const classes = useStyles();
  const mdCardCols = (pageTitles?.length > 3 && dataCards?.length > 3) ? 6 : ((pageTitles?.length > 2 && dataCards?.length > 2) ? 4 : 6);
  const lgCardCols = (pageTitles?.length > 4 && dataCards?.length > 4) ? 4 : ((pageTitles?.length === 4 && dataCards?.length === 4) ? 3 : ((pageTitles?.length === 3 && dataCards?.length === 3) ? 4 : 6));

  const getCardsWithTitles = () => {
    return pageTitles.map((title, index) => {
      return (
        <Grid item container xs={12} sm={12} md={mdCardCols} lg={lgCardCols} key={index}>
          <Grid item container spacing={1} xs={12} justifyContent={{ xs: 'center', md: 'flex-start' }}>
            <Grid item xs={12}>
              <PageTitle {...title} />
              <DataCard {...dataCards[index]} />
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };
  
  return (
    <Grid container className={classes.root} columnSpacing={{ xs: 0, md: 6, lg: 12 }} rowSpacing={3}>
      {getCardsWithTitles()}
      {props.charts}
      {(isBusy) &&
      <Paper elevation={8} className={classes.progressSpinnerWrapper}>
        <CircularProgress
          aria-label={'progress spinner'}
          size={48}
          sx={{
            padding: '6px'
          }}
        />
      </Paper>
      }   
    </Grid>
  );
};

export default DashboardTemplate;
