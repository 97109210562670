import { Button, Container, Grid, Theme, useMediaQuery } from '@mui/material';
import DataGrid, { Column, IColumnProps, IDataGridOptions } from 'devextreme-react/data-grid';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useDataGridNoDataText } from '../../utils/hooks/useDataGridNoDataText';

import AddIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {GetV1CustomersDeleteNotificationRecipientsApiArg, GetV1CustomersSaveNotificationRecipientsApiArg, NotificationRecipientsResource, useGetV1CustomersNotificationRecipientsQuery } from '../../api/customers.api';
import { MobileDataCardRow } from '../../components/atoms/MobileDataCardRow';
import { PageTitle } from '../../components/atoms/PageTitle';
import { MobileDataCard } from '../../components/molecules/MobileDataCard';
import { TableTemplate } from '../../components/organisms/TableTemplate';
import DeleteDialog from '../../components/organisms/DeleteDialog';
import { selectIsUserCustomerTenantAdmin, selectTenantId, setToastConfig } from '../app/AppSlice';
import { clearDelete, clearSave, toggleDeleteDialog, setSelectedRecipient, selectSelectedRecipient, selectDeleteStatus, selectDeleteError, selectDeleteDialogOpen, selectSaveStatus, selectError } from './NotificationRecipientsSlice';
import { deleteRecipient, saveRecipient } from './NotificationRecipientsAPI';
import { ToastSeverity } from '../../utils/Constants';
import { Helmet } from 'react-helmet-async';
import { buildAIContentDescription } from '../../utils/helpers/functions';


export const NotificationRecipients = () => {
  const isMobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const gridRef = useRef<DataGrid | null>(null);
   
  const tenantId = useAppSelector(selectTenantId);
  const isUserTenantAdmin = useAppSelector(selectIsUserCustomerTenantAdmin);
  const selectedDataRow = useAppSelector(selectSelectedRecipient);
  const deleteStatus = useAppSelector(selectDeleteStatus);
  const deleteError = useAppSelector(selectDeleteError);
  const saveStatus = useAppSelector(selectSaveStatus);
  const saveError = useAppSelector(selectError);
  const openDeleteDialog = useAppSelector(selectDeleteDialogOpen);
  
  // including refetchOnMountOrArgChange to prevent caching of the list since
  // changes could be made in EDI directly that would need refetching of data
  // to be brought into Portal
  const { refetch, data: notificationRecipients, isLoading, isFetching } = useGetV1CustomersNotificationRecipientsQuery(
    {
      tenantId: tenantId as string,
    },
    { 
      refetchOnMountOrArgChange: true,
      skip: !tenantId
    }
  );
  
  const { t: translate } = useTranslation();

  
  useEffect(() => {
    // once roles are loaded, validate the current user has the
    // admin role to view this screen
    // if not, redirect to home screen
    if (isUserTenantAdmin === false) {
      navigate("/");
    }
  }, [isUserTenantAdmin]);

  useEffect(() => {
    if (deleteStatus) {
      if (deleteStatus.wasSuccessful === true) {
        dispatch(setToastConfig({
          message: deleteStatus.message as string,
          severity: ToastSeverity.Success
        }));
        refetch();
      } else {
        dispatch(setToastConfig({
          message: deleteStatus.message as string,
          severity: ToastSeverity.Error
        }));
      } 
      resetDeleteStatus();
    } else if(deleteError) {
      dispatch(setToastConfig({
        message: deleteError.message,
        severity: ToastSeverity.Error
      }));
      resetDeleteStatus();
    }
  },[deleteStatus, deleteError])

  useEffect(() => {
    if (saveStatus) {
      if (saveStatus.wasSuccessful === true) {
        dispatch(setToastConfig({
          message: saveStatus.message as string,
          severity: ToastSeverity.Success
        }));
        // need to refetch to bring the saved row into the grid
        refetch();
      } else {
        dispatch(setToastConfig({
          message: saveStatus.message as string,
          severity: ToastSeverity.Error
        }));
      } 
      resetSaveStatus();
    } else if(saveError) {
      dispatch(setToastConfig({
        message: saveError.message,
        severity: ToastSeverity.Error
      }));
      resetSaveStatus();
    }
  },[saveStatus, saveError])

  const calculateRecipient = (rowData: NotificationRecipientsResource) => (rowData.recipients ? rowData.recipients : undefined);
  const calculateDescription = (rowData: NotificationRecipientsResource) => (rowData.description ? rowData.description : undefined);
  const cellRenderNotifications = (params: any) => {
    const { data } = params;
    return cellRenderBoolean(data?.receivesNotifications);
  };
  const cellRenderErrors = (params: any) => {
    const { data } = params;
    return cellRenderBoolean(data?.receivesErrors);
  };  
  const cellRenderBoolean = (value: boolean | null | undefined) => {
    if (value === true) {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><CheckCircleIcon color="success" /></div>
    }
    return (<div></div>);
  };

  const resetSaveStatus = () => {
    dispatch(clearSave());
  }

  const resetDeleteStatus = () => {
    dispatch(clearDelete());
    deleteDialogCloseHandler();
  }

  const clearSelectedRow = () => {
    dispatch(setSelectedRecipient(null));
  }
  
  const addNewOnclick = async () => {
    let gridInstance = gridRef.current?.instance;

    // trigger the insert of a new row in the grid to
    // add in-inline
    await gridInstance?.addRow();

    // once row is added, it will be the first index
    // use that to get the rowKey to pass to do 
    // initial disable of save button
    let rowKey = gridInstance?.getKeyByRowIndex(0);
    enableDisableSave(false, rowKey);
  };

  const onDeleteClick = (selectedRow: any) => {
    // save off the selected row to use for opening the 
    // dialog for confirming the delete
    if (selectedRow) {
      dispatch(setSelectedRecipient(selectedRow as NotificationRecipientsResource));
      dispatch(toggleDeleteDialog());
    }
  };

  const deleteDialogCloseHandler = () => {
    dispatch(toggleDeleteDialog());
    clearSelectedRow();
  };

  const onDeleteDialogConfirm = () => {
    // delete the selected recipient
    if (selectedDataRow) {
      dispatch(deleteRecipient({
        notificationRecipientsId: selectedDataRow.notificationRecipientsId
      } as GetV1CustomersDeleteNotificationRecipientsApiArg))
    }
  };

  const onSaveClick = (selectedRow: any) => {
    if (selectedRow) {
      var rowToSave = selectedRow as NotificationRecipientsResource;
      
      dispatch(saveRecipient({
        tenantId: rowToSave.tenantId ?? tenantId,
        clientId: rowToSave.clientId,
        notificationRecipientsId: rowToSave.notificationRecipientsId,
        recipients: rowToSave.recipients,
        description: rowToSave.description,
        receivesNotifications: rowToSave.receivesNotifications,
        receivesErrors: rowToSave.receivesErrors,
        notificationType: rowToSave.notificationType
      } as GetV1CustomersSaveNotificationRecipientsApiArg))
    }
  };

  const cellOnValueChanged = async (cellEditor: any, onChangeArgs: any) => {
    // since a value changed, check if it was a valid change by triggering validation
    let isCellValid = false;
    await cellEditor.component.getController('validating').validate().done((result: boolean) => {
      //check result. It should be true or false.
      isCellValid = result; 
    }); 

    // after awaiting validation, use the result to enable/disable save button
    // to prevent saving invalid data, using the key from the currently edited cell
    let rowKey = cellEditor.row.key;
    enableDisableSave(isCellValid, rowKey);
  };

  const enableDisableSave = (isRowValid: boolean, rowKey: string) => {
    // using the key, get its containing row
    let grid = gridRef.current?.instance;
    let rowIndex = grid?.getRowIndexByKey(rowKey) ?? -1;
    if (rowIndex >= 0) {
      // then get the command column of that row and find the save command
      let commandsElement = grid?.getCellElement(rowIndex, "commandColumn");
      if (commandsElement) {
        // save will be the first command
        let saveLink = commandsElement.children[0];
        
        // add or remove the disabled class based on validation result
        if (isRowValid) {
          saveLink.classList.remove("dx-state-disabled");
        } else {
          saveLink.classList.add("dx-state-disabled");
        }
      }
    }
  };

  /* 
    Override of grid function to capture onChange event
    of a cell editor to be able to call our own function
    for value change handling
  */
  const onEditorPreparing = (e: any) => {
    const defaultValueChangeHandler = e.editorOptions.onValueChanged;

    e.editorOptions.onValueChanged = function(args: any) {
        defaultValueChangeHandler(args); // Execute the default handler
        cellOnValueChanged(e, args); // Execute custom code
    };
  };

  const dataGridColumns: IColumnProps[] = [
    {
      dataField: 'recipients',
      caption: translate('grids.recipient'),
      calculateCellValue: calculateRecipient,
      allowSorting: true,
      sortIndex: 0,
      sortOrder: 'desc',
      alignment: 'left',
      validationRules: [
        {
          type: 'required',
        }, 
        {
          type: 'email',
          message: 'Recipient must be a valid email format.'
        }
      ],
    },
    { 
      dataField: 'description',
      caption: translate('grids.description'),
      calculateCellValue: calculateDescription,
      allowSorting: true,
      alignment: 'left',
      validationRules: [
        { 
          type: 'required' 
        },
        {
          type: 'stringLength',
          max: 255,
          message: "Description is limited to 255 chars."
        }
      ],
    },
    {
      dataField: 'receivesNotifications',
      dataType: 'boolean',
      caption: translate('grids.notifications'),
      alignment: 'center',
      cellRender: cellRenderNotifications,
    },
    { 
      dataField: 'receivesErrors',
      dataType: 'boolean',
      caption: translate('grids.errors'),
      alignment: 'center',
      cellRender: cellRenderErrors,
    },
    {
      type: 'buttons',
      name: 'commandColumn',
      visible: true,
      minWidth: '80px',
      buttons: [ 
        { 
          name: 'edit',
          hint: translate('grids.edit'),
        },
        {
          name: 'delete',
          hint: translate('grids.delete'),
          onClick(e: any) {
            onDeleteClick(e.row.data);
          },
        },
        {
          name: 'save',
          hint: translate('grids.save'),
          onClick(e: any) {
            onSaveClick(e.row.data);
          },
        },
        { 
          name: 'cancel',
          hint: translate('grids.cancel'),
        },
      ]
    },
  ];

  const gridOptions: IDataGridOptions = {
    dataSource: notificationRecipients,
    loadPanel: {
      enabled: true,
      showIndicator: true,
    },
    keyExpr: 'notificationRecipientsId',
    rowAlternationEnabled: true,
    height: 'auto',
    scrolling: {
      mode: 'virtual',
    },
    sorting: {
      mode: 'single',
    },
    editing: {
      mode: 'row',
      allowUpdating: true,
      allowDeleting: true,
      useIcons: true,
    },
    noDataText: useDataGridNoDataText(isLoading || isFetching),
    onEditorPreparing: onEditorPreparing,
  };

  const mapRecipients = (recipient: NotificationRecipientsResource | undefined) => (
    <MobileDataCard key={recipient?.notificationRecipientsId}>
      {dataGridColumns.map((column, index) =>
        index === 0 || index === 1 ? (
          <MobileDataCardRow
            key={index}
            title={column.calculateCellValue ? column.calculateCellValue(recipient) : ''}
            value={''}
          />
        ) : (
          <MobileDataCardRow
            key={index}
            title={column.caption}
            value={''}
            linkTo={column.cellRender ? column.cellRender({data: recipient}) : undefined}
          />
        ),
      )}
    </MobileDataCard>
  );

  const getDisplayText = (): string => {
    return translate('notificationRecipients.title');
  };

  const getDeleteDialogMessage = () => {
    let message = `${translate('notificationRecipients.deleteDialogConfirmation')} '${selectedDataRow?.description}'?`;
    return message;
  }
  
  return (
    <Container component="section" disableGutters maxWidth={false}>
      <Helmet>
        <meta name="ai:viewId" content="notificationRecipients"></meta>
        <meta name="ai:viewDescription" content={buildAIContentDescription(translate('notificationRecipients.title'))}></meta>
      </Helmet>
      <Grid container spacing={1} justifyContent="space-between" mb={isMobileScreen ? 2 : 1}>
        <Grid item>
          <PageTitle title={getDisplayText()} gutterBottom={false} />
        </Grid>
        {
          // not allowing crud operations on mobile
        }
        {!isMobileScreen && (
        <Grid item display="flex" justifyContent="flex-end">
          <Button startIcon={<AddIcon />} variant="contained" onClick={addNewOnclick}>
            {translate('notificationRecipients.new')}
          </Button>
        </Grid>
        )}
      </Grid>
      {!isMobileScreen ? (
        <TableTemplate ref={gridRef} gridOptions={gridOptions}>
          {dataGridColumns.map((col, i) => (
            <Column key={i} {...col} />
          ))}
        </TableTemplate>
      ) : (
        <Grid container direction="column" spacing={1}>
          {notificationRecipients?.map(mapRecipients)}
        </Grid>
      )}
      <DeleteDialog
          isOpen={openDeleteDialog}
          id={selectedDataRow?.notificationRecipientsId ?? ''}
          heading={translate('notificationRecipients.deleteDialogHeading')}
          message={getDeleteDialogMessage()}
          onConfirm={onDeleteDialogConfirm}
          onReject={deleteDialogCloseHandler}
          errorMessage={deleteError?.message}
      />
    </Container>
  );
};
