import React from 'react';
import { NormalizedDocumentType } from '../../api/dashboard.api';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/system';
import ActivityChart from '../molecules/ActivityChart';
import { ActivityVendorChartData } from '../../features/dashboard/DashboardAPI';



export interface ActivityVendorChartProps {
  data: ActivityVendorChartData[];
  dateFilterTitle: string;
  onActivityClick: (docType: NormalizedDocumentType, activityDate?: string, ) => void;
};

const ActivityVendorChart: React.FC<ActivityVendorChartProps> = props => {
  const { data, dateFilterTitle, onActivityClick } = props;
  const { t: translate } = useTranslation();
  const theme = useTheme();  
  const colors = [theme.palette.chartOrders.main, theme.palette.chartShipments.main, theme.palette.chartInvoices.main, theme.palette.chartAcknowledgements.main];
  
  const onPointClick = (pointInfo: any) => {
    const point = pointInfo.target;
    if (point) {
      if (point.isSelected()) {
        // unselect
        point.clearSelection();
      } else {
        // set selected
        point.select();

        // get the selected series
        var selectedValueFields = point.series?.getValueFields();
        if (!selectedValueFields || selectedValueFields.length === 0) {
          return;
        }
        var selectedValueField = selectedValueFields[0];
        
        // get the x-axis (argument) value to open the selected series detail for that value
        var isoActivityDate = point.argument;
        
        // open order list for specific date
        if (selectedValueField === "orders") {
          onActivityClick("purchaseOrderOutbound", isoActivityDate);
        } else if (selectedValueField === "invoices") {
          onActivityClick("invoiceInbound", isoActivityDate);
        } else if (selectedValueField === "shipments") {
          onActivityClick("shipmentInbound", isoActivityDate);
        } else if (selectedValueField === "acknowledgements") {
          onActivityClick("acknowledgementInbound", isoActivityDate);
        }
      }
    }
  }

  return (
    <ActivityChart 
      id="activityChart" 
      dataSource={data} 
      dateFilterTitle={dateFilterTitle}
      onPointClick={onPointClick}
      colors={colors}
      series={[
        { valueField: "orders", name: translate('grids.orders') }, 
        { valueField: "shipments", name: translate('grids.shipments') }, 
        { valueField: "invoices", name: translate('grids.invoices') },
        { valueField: "acknowledgements", name: translate('grids.acknowledgements') }
      ]}
    />
  );
};

export default ActivityVendorChart;
