import { CardActionArea, Grid, Paper, Theme, Typography, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dataCard: {
      padding: theme.spacing(1, 2),
      height: '110px',
      borderRadius: '8px',
      maxWidth: '100vw',
      ...(theme.palette.mode === 'light') ? {} : {borderColor: 'rgba(255,255,255,0.4)'},
    },
    dataContentColumn1: {
      flexBasis: '0%',
      flexGrow: '1',
      alignItems: 'center',
    },
    dataContentColumn2: {
      flexBasis: '0%',
      flexGrow: '1',
    },
    clickableDataCard: {
      padding: 0,
      height: '110px',
      borderRadius: '8px',
      maxWidth: '100vw',      
      ...(theme.palette.mode === 'light') ? {} : {borderColor: 'rgba(255,255,255,0.4)'},
    },
    clickableDataCardAction: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(1, 2),
      alignItems: 'flex-start',
    },
    headerText: {
      fontSize: 18,
      fontWeight: 500,
      color: theme.palette.header.main,
    },
    singleHeaderText: {
      fontSize: 20,
      fontWeight: 400,
      color: theme.palette.header.main,
      textAlign: 'center',
      marginTop: theme.spacing(1.5),
    },
    valueText: {
      maxWidth: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    labelText: {
      color: theme.palette.header.main,
      fontSize: 16,
      marginTop: theme.spacing(2),
    },
  }),
);

export interface DataCardProps {
  header?: string;
  label1?: string;
  value1: string;
  label2?: string;
  value2?: string;
  onClick?: () => void;
  style?: object;
  keepDashStyle?: boolean;
}

const DataCard: React.FC<DataCardProps> = props => {
  const classes = useStyles();
  const { header, label1, value1, label2, value2, onClick, style, keepDashStyle } = props;
  const isSingleDisplay = header && value1 && ((!label2 && !value2) && !keepDashStyle);
  const clickableCardContent = (children: ReactNode) => {
    if (onClick) {
      return (
        <CardActionArea className={classes.clickableDataCardAction} onClick={onClick}>
          {children}
        </CardActionArea>
      );
    }
    return children;
  };

  const getCardWidth = () => {
    if (keepDashStyle) {
      // we are a dashboard single entry, 
      return 268;
    } else {
      return label2 || value2 ? 268 : 177;
    }
  }
  const dataCardContent = (
    <Grid container direction="column" rowSpacing={1} sx={{height: '100%', flexWrap: 'unset'}}>
      
      <Grid className={classes.dataContentColumn2} container item xs={12}>
        <Grid item container xs={12} justifyContent="center" alignItems="center" flexDirection="column">
          <Grid item xs alignSelf="center">
            <Typography
              className={classes.valueText}
              variant="h4"
              sx={{
                fontSize: isSingleDisplay ? '54px' : '34px',
                fontWeight: isSingleDisplay ? 500 : 700,
                color: keepDashStyle || !isSingleDisplay ? '' : '#276EF1',
              }}
            >
              {value1}
            </Typography>
          </Grid>
          {value2 && (
          <Grid item xs alignSelf="center">
            <Typography
              className={classes.valueText}
              variant="h4"
              sx={{  height: '36px', fontSize: '27px', fontWeight: 400, color: '#34C759', marginTop: 2  }}
            >
              {/* Note to UX: how do we want to address text overflow? */}
              {value2}
            </Typography>
          </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Paper
      className={onClick ? classes.clickableDataCard : classes.dataCard}
      variant="outlined"
      sx={{ width: getCardWidth() }}
      style={style ? style : {}}
    >
      {clickableCardContent(dataCardContent)}
    </Paper>
  );
};

export default DataCard;
