import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { useEffect } from 'react';
import { StorageKeyFrontChannelLogoutRequestedTime } from '../../utils/Constants';

const FrontChannelLogout: React.FC = () => {
  const { t: translate } = useTranslation();
  let logoutTime = DateTime.now().toUTC().toISO();
  localStorage.setItem(StorageKeyFrontChannelLogoutRequestedTime, logoutTime);
  // need to dispatch the event to trigger the logout
  window.dispatchEvent(new StorageEvent("storage", { key: StorageKeyFrontChannelLogoutRequestedTime, newValue: logoutTime }));
  
  useEffect(() => {
    window.close();
  }, []);

  return (
    <Box
      sx={{ backgroundColor: 'black', minWidth: '100vw', minHeight: '100vh', padding: 4 }}
      justifyContent="center"
      display="flex"
      alignItems="center"
    >
      <Box>
        <Card sx={{ backgroundColor: '#34363a', color: 'white' }}>
          <CardContent>
            <Typography>{translate('app.loggingOut')}</Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default FrontChannelLogout;
