import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from "../../app/store";

export enum DashboardType {
  core='core',
  threePL='threePL',
  vendor='vendor'
}

export interface DashboardsState {
  selectedDashboard: DashboardType;
}

const initialState: DashboardsState = {
  selectedDashboard: DashboardType.core,
}

export const slice = createSlice({
    name: 'dashboards',
    initialState,
    reducers: {
        clear: state => {
            // eslint-disable-next-line no-param-reassign
            state.selectedDashboard = DashboardType.core;
        },
        setSelectedDashboard: (state, action: PayloadAction<DashboardType>) => {
          // eslint-disable-next-line no-param-reassign
          state.selectedDashboard = action.payload;
        },
    },
    extraReducers: builder => {},
  });

  export const selectDashboard = (state: RootState): DashboardType => state.dashboards.selectedDashboard;
  
  export const {
      clear,
      setSelectedDashboard
  } =  slice.actions;

export default slice.reducer;