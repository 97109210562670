export const breadcrumbNameMap: { [key: string]: string } = {
  '/trading-partners': 'breadcrumbs.tradingPartners',
  '/trading-partners/order-': 'breadcrumbs.transactionHistory',
  '/trading-partners/tradingPartner-/order-': 'breadcrumbs.transactionHistory',
  '/trading-partners/tradingPartner-/order-/raw-order-detail': 'breadcrumbs.rawEdi',
  '/purchase-orders': 'breadcrumbs.purchaseOrders',
  '/purchase-orders/order-': 'breadcrumbs.transactionHistory',
  '/purchase-orders/order-/raw-order-detail': 'breadcrumbs.rawEdi',
  '/purchase-orders/exceptions-filtered': 'breadcrumbs.exceptions',
  '/exceptions': 'breadcrumbs.exceptions',
  '/exceptions/order-/raw-order-detail': 'breadcrumbs.rawEdi',
  '/exceptions/order-': 'breadcrumbs.transactionHistory',
  '/transactions': 'breadcrumbs.transactionSearch',
  '/transactions/order-': 'breadcrumbs.transactionHistory',
  '/transactions/exceptions-filtered': 'breadcrumbs.exceptions',
  '/invoices': 'breadcrumbs.invoices',
  '/invoices/order-': 'breadcrumbs.transactionHistory',
  '/invoices/exceptions-filtered': 'breadcrumbs.exceptions',
  '/shipments': 'breadcrumbs.shipments',
  '/shipments/order-': 'breadcrumbs.transactionHistory',
  '/shipments/exceptions-filtered': 'breadcrumbs.exceptions',
  '/acknowledgements': 'breadcrumbs.acknowledgements',
  '/acknowledgements/order-': 'breadcrumbs.transactionHistory',
  '/warehouse-shipping-order': 'breadcrumbs.warehouseShippingOrder',
  '/warehouse-shipping-order/order-': 'breadcrumbs.transactionHistory',
  '/warehouse-shipping-order/exceptions-filtered': 'breadcrumbs.exceptions',
  '/warehouse-shipping-advice': 'breadcrumbs.warehouseShippingAdvice',
  '/warehouse-shipping-advice/order-': 'breadcrumbs.transactionHistory',
  '/warehouse-shipping-advice/exceptions-filtered': 'breadcrumbs.exceptions',
  '/warehouse-inventory-advice': 'breadcrumbs.warehouseInventoryAdvice',
  '/warehouse-inventory-advice/order-': 'breadcrumbs.transactionHistory',
  '/warehouse-inventory-advice/exceptions-filtered': 'breadcrumbs.exceptions',
  '/stock-transfer': 'breadcrumbs.stockTransfer',
  '/stock-transfer/order-': 'breadcrumbs.transactionHistory',
  '/stock-transfer/exceptions-filtered': 'breadcrumbs.exceptions',
  '/stock-receipt': 'breadcrumbs.stockReceipt',
  '/stock-receipt/order-': 'breadcrumbs.transactionHistory',
  '/stock-receipt/exceptions-filtered': 'breadcrumbs.exceptions',
  '/purchase-orders-outbound': 'breadcrumbs.purchaseOrderOutbound',
  '/purchase-orders-outbound/order-': 'breadcrumbs.transactionHistory',
  '/purchase-orders-outbound/exceptions-filtered': 'breadcrumbs.exceptions',
  '/invoices-inbound': 'breadcrumbs.invoiceInbound',
  '/invoices-inbound/order-': 'breadcrumbs.transactionHistory',
  '/invoices-inbound/exceptions-filtered': 'breadcrumbs.exceptions',
  '/shipments-inbound': 'breadcrumbs.shipmentInbound',
  '/shipments-inbound/order-': 'breadcrumbs.transactionHistory',
  '/shipments-inbound/exceptions-filtered': 'breadcrumbs.exceptions',
  '/acknowledgements-inbound': 'breadcrumbs.acknowledgementInbound',
  '/acknowledgements-inbound/order-': 'breadcrumbs.transactionHistory',
  '/acknowledgements-inbound/exceptions-filtered': 'breadcrumbs.exceptions',
  '/notification-recipients': 'breadcrumbs.notificationRecipients',
  '/client-transactions': 'breadcrumbs.clientTransactionNotifications',
  '/client-billing': 'breadcrumbs.billing',
  '/user-management': 'breadcrumbs.userManagement',
};
