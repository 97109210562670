import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, IconButton, Menu, MenuItem, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import DownloadIcon from '@mui/icons-material/FileOpenOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TransactionResource } from '../../api/customers.api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    downloadIconButton: {
      padding: '0',
      '&:hover': {
        color: theme.palette.primary.dark,
        fontWeight: 'bold'
      }
    },
    arrowIconButton: {
      padding: '0',
      '&:hover': {
        color: theme.palette.primary.dark,
        fontWeight: 'bold'
      }
    },
  }),
);
  
interface DownloadSplitButtonProps {
  onDownloadHtmlClick: () => void;
  onDownloadRawEdiClick: () => void;
  onDownload997Click?: () => void;
}
  
export const DownloadSplitButton: React.FC<DownloadSplitButtonProps> = (props: DownloadSplitButtonProps) => {
    const classes = useStyles();
    const { t: translate } = useTranslation();
    const { onDownloadHtmlClick, onDownloadRawEdiClick, onDownload997Click } = props;
    
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isDownloadMenuOpen = Boolean(anchorEl);

    const onDownloadMenuClick = (event: React.MouseEvent<HTMLButtonElement>, selectedRow?: TransactionResource) => {
      // this button could be inside of a grid that could have a click event,
      // stopPropagation to prevent that click event from firing
      event.stopPropagation();

      setAnchorEl(event.currentTarget);
    };

    const handleDownloadMenuClose = (event: React.MouseEvent<any>) => {
      // this button could be inside of a grid that could have a click event,
      // stopPropagation to prevent that click event from firing
      event.stopPropagation();
      
      let buttonDataSet = (event.target as HTMLElement).dataset;
      
      if (buttonDataSet.operation === "html") {
        onDownloadHtmlClick();
      }
      else if (buttonDataSet.operation === "raw") {
        onDownloadRawEdiClick();
      }
      else if (buttonDataSet.operation === "997" && onDownload997Click) {
        onDownload997Click();
      }
      
      setAnchorEl(null);
    };

    const translatedDownloadHtml = translate('grids.downloadHtml');
    return (
      <>
        <ButtonGroup variant="text" aria-label={`${translate('grids.downloadButtonGroup')}`}>
          <IconButton
              aria-label={translatedDownloadHtml}
              title={translatedDownloadHtml}
              color="primary"
              size="medium"
              className={classes.downloadIconButton}
              onClick={onDownloadHtmlClick}
          >
            <DownloadIcon />
          </IconButton >
          <IconButton
              id="options-button"
              aria-label={`${translate('grids.downloadOptionsMenu')}`}
              aria-haspopup="menu"
              title={`${translate('grids.downloadOptions')}`}
              color="primary"
              size="small"
              className={classes.arrowIconButton}
              onClick={onDownloadMenuClick}
          >
            <ArrowDropDownIcon />
          </IconButton >
          
        </ButtonGroup>
        <Menu
            id="options-menu"
            anchorEl={anchorEl}
            open={isDownloadMenuOpen}
            onClose={handleDownloadMenuClose}
        >
            <MenuItem
                data-operation="html"
                onClick={handleDownloadMenuClose}
                >
                {translatedDownloadHtml}
            </MenuItem>
            <MenuItem
                data-operation="raw"
                onClick={handleDownloadMenuClose}
            >
              {translate('grids.downloadRawEdi')}
            </MenuItem>
            {onDownload997Click !== undefined &&
            <MenuItem
                data-operation="997"
                onClick={handleDownloadMenuClose}
            >
              {translate('grids.download997')}
            </MenuItem>
            }
        </Menu> 
      </>
    );
};
  
