import { Action, combineReducers, configureStore, PreloadedState, ThunkAction } from '@reduxjs/toolkit';

import appReducer from '../features/app/AppSlice';
import purchaseOrdersReducer from '../features/purchaseOrders/PurchaseOrdersSlice';
import tradingPartnerReducer from '../features/tradingPartner/TradingPartnerSlice';
import invoicesReducer from '../features/invoices/InvoicesSlice';
import shipmentsReducer from '../features/shipments/ShipmentsSlice';
import acknowledgementsReducer from '../features/acknowledgements/AcknowledgementsSlice';
import documentTypeTransactionsReducer from '../features/documentTypeTransactions/DocumentTypeTransactionsSlice';
import notificationRecipientsReducer from '../features/notificationRecipients/NotificationRecipientsSlice';
import exceptionsReducer from '../features/exceptions/ExceptionsSlice';
import transactionsReducer from '../features/transactions/TransactionsSlice';
import clientTransactionsReducer from '../features/clientTransactions/ClientTransactionsSlice';
import dashboardReducer from '../features/dashboard/DashboardSlice';
import { emptySplitApi } from '../api/empty.api';

const rootReducer = combineReducers({
  app: appReducer,
  purchaseOrders: purchaseOrdersReducer,
  tradingPartner: tradingPartnerReducer,
  invoices: invoicesReducer,
  shipments: shipmentsReducer,
  acknowledgements: acknowledgementsReducer,
  notificationRecipients: notificationRecipientsReducer,
  exceptions: exceptionsReducer,
  transactions: transactionsReducer,
  clientTransactions: clientTransactionsReducer,
  documentTypeTransactions: documentTypeTransactionsReducer,
  dashboards: dashboardReducer,

  // RTK Query reducers
  [emptySplitApi.reducerPath]: emptySplitApi.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(emptySplitApi.middleware),
  });

export type AppDispatch = AppStore['dispatch'];
export type AppStore = ReturnType<typeof setupStore>;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
