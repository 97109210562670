import { emptySplitApi as api } from './empty.api';
export const addTagTypes = ['Exception'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      getV1ExceptionsByTenantId: build.query<GetV1ExceptionsByTenantIdApiResponse, GetV1ExceptionsByTenantIdApiArg>({
        query: queryArg => ({
          url: `/v1/exceptions/${queryArg.tenantId}`,
          params: {
            offset: queryArg.offset,
            limit: queryArg.limit,
            returnAllRecords: queryArg.returnAllRecords,
            startDate: queryArg.startDate,
            endDate: queryArg.endDate,
            status: queryArg.status,
            orderNumber: queryArg.orderNumber,
            tradingPartnerId: queryArg.tradingPartnerId,
            docType: queryArg.docType,
          },
        }),
        providesTags: ['Exception'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type GetV1ExceptionsByTenantIdApiResponse = /** status 200 Success */ ExceptionResourcePaginatedResponse;
export type GetV1ExceptionsByTenantIdApiArg = {
  tenantId: string;
  offset?: number;
  limit?: number;
  returnAllRecords?: boolean;
  startDate?: string;
  endDate?: string;
  status?: ExceptionStatus;
  orderNumber?: string;
  tradingPartnerId?: string;
  docType?: NormalizedDocumentType;
};
export type NormalizedDocumentType =
  | 'unknown'
  | 'acknowledgement'
  | 'applicationAdvice'
  | 'control'
  | 'creditDebitAdjustment'
  | 'deliveryJustInTime'
  | 'deliverySchedule'
  | 'inventoryAdvice'
  | 'invoice'
  | 'organizationalRelationships'
  | 'payment'
  | 'productTransferResaleReport'
  | 'purchaseOrder'
  | 'purchaseOrderChange'
  | 'remittanceAdvice'
  | 'requestForQuotation'
  | 'requestForRoutingInstructions'
  | 'salesOrderStatus'
  | 'salesOrderStatusRequest'
  | 'shipment'
  | 'shippingSchedule'
  | 'stockReceipt'
  | 'stockTransfer'
  | 'taxcon'
  | 'textMessage'
  | 'warehouseInventoryAdjustmentAdvice'
  | 'warehouseShippingAdvice'
  | 'warehouseShippingOrder'
  | 'purchaseOrderOutbound'
  | 'invoiceInbound'
  | 'shipmentInbound'
  | 'acknowledgementInbound';
export type ExceptionStatus = 'unknown' | 'error' | 'resolved_Error';
export type ExceptionResource = {
  id?: string;
  createdBy?: string | null;
  createdTimestamp?: string;
  modifiedBy?: string | null;
  modifiedTimestamp?: string;
  customerId?: string;
  tradingPartnerId?: string;
  purchaseOrderId?: string | null;
  tradingPartnerName?: string | null;
  documentType?: NormalizedDocumentType;
  transactionType?: string | null;
  purchaseOrderNumber?: string | null;
  documentNumber?: string | null;
  exceptionType?: string | null;
  description?: string | null;
  status?: ExceptionStatus;
  date?: string;
  internalTransactionId?: string | null;
  originalFileName?: string | null;
};
export type ExceptionResourcePaginatedResponse = {
  offset?: number;
  totalCount?: number;
  items?: ExceptionResource[] | null;
};
export const { useGetV1ExceptionsByTenantIdQuery } = injectedRtkApi;
