import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { NormalizedDocumentType } from '../../api/dashboard.api';
import ActivityChart from '../molecules/ActivityChart';
import { Activity3plChartData } from '../../features/dashboard/DashboardAPI';

export interface Activity3plChartProps {
  data: Activity3plChartData[];
  dateFilterTitle: string;
  onActivityClick: (docType: NormalizedDocumentType, activityDate?: string, ) => void;
};

const Activity3plChart: React.FC<Activity3plChartProps> = props => {
  const { data, dateFilterTitle, onActivityClick } = props;
  const { t: translate } = useTranslation();
  const theme = useTheme();  
  const colors = [theme.palette.chartOrders.main, theme.palette.chartShipments.main, theme.palette.chartInvoices.main, theme.palette.chartTransfers.main, theme.palette.chartReceipts.main];

  const onPointClick = (pointInfo: any) => {
    const point = pointInfo.target;
    if (point) {
      if (point.isSelected()) {
        // unselect
        point.clearSelection();
      } else {
        // set selected
        point.select();

        // get the selected series
        var selectedValueFields = point.series?.getValueFields();
        if (!selectedValueFields || selectedValueFields.length === 0) {
          return;
        }
        var selectedValueField = selectedValueFields[0];
        
        // get the x-axis (argument) value to open the selected series detail for that value
        var isoActivityDate = point.argument;
        
        // open order list for specific date
        if (selectedValueField === "shippingOrders") {
          onActivityClick("warehouseShippingOrder", isoActivityDate);
        } else if (selectedValueField === "shippingAdvice") {
          onActivityClick("warehouseShippingAdvice", isoActivityDate);
        } else if (selectedValueField === "inventoryAdvice") {
          onActivityClick("warehouseInventoryAdjustmentAdvice", isoActivityDate);
        } else if (selectedValueField === "transfers") {
          onActivityClick("stockTransfer", isoActivityDate);
        } else if (selectedValueField === "receipts") {
          onActivityClick("stockReceipt", isoActivityDate);
        }
      }
    }
  };

  return (
    <ActivityChart 
      id="activity3plChart" 
      dataSource={data} 
      dateFilterTitle={dateFilterTitle}
      onPointClick={onPointClick}
      colors={colors}
      series={[
        { valueField: "shippingOrders", name: translate('navigation.warehouseShippingOrder') }, 
        { valueField: "shippingAdvice", name: translate('navigation.warehouseShippingAdvice') }, 
        { valueField: "inventoryAdvice", name: translate('navigation.warehouseInventoryAdvice') },
        { valueField: "transfers", name: translate('navigation.stockTransfer') },
        { valueField: "receipts", name: translate('navigation.stockReceipt') }
      ]}
    />
  );
};

export default Activity3plChart;
