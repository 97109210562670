//import axios from "axios";

export const stringDisplay = (data: string | null | undefined, propertyName: string) => {
    if (data) {
        return data;
    }
    return '';
}

export const numberDisplay = (data: number | null | undefined, propertyName: string) => {
    if (data === 0) {
        return 0;
    }
    if (data) {
        return data;
    }
    return 0;
}