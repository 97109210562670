import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../app/hooks';
import { useDataGridNoDataText } from '../../utils/hooks/useDataGridNoDataText';
import ExportIcon from '@mui/icons-material/IosShare';
import LaunchIcon from '@mui/icons-material/Launch';
import { Button, Container, Divider, Grid, Theme, useMediaQuery } from '@mui/material';
import DataGrid, { Column, IColumnProps, IDataGridOptions } from 'devextreme-react/data-grid';
import dxDataGrid, { ContentReadyEvent } from 'devextreme/ui/data_grid';

import { TransactionSummaryResource, useGetV1CustomersByTenantIdTransactionSummaryQuery } from '../../api/customers.api';
import { LinkCell } from '../../components/atoms/LinkCell';
import { LinkRouter } from '../../components/atoms/LinkRouter';
import { MobileDataCardRow } from '../../components/atoms/MobileDataCardRow';
import { PageTitle } from '../../components/atoms/PageTitle';
import { MobileDataCard } from '../../components/molecules/MobileDataCard';
import { TableTemplate } from '../../components/organisms/TableTemplate';
import CardSummaryTemplate from '../../components/organisms/CardSummaryTemplate';

import { getDataGridRowCurrencyDisplayValue } from '../../utils/helpers/currencyFormatUtil';
import { exportDataGridToCSV } from '../../utils/helpers/fileUtil';
import { selectTenantId, selectDateFilterInterval, selectDateFilterTitle } from '../app/AppSlice';
import { Helmet } from 'react-helmet-async';
import { buildAIContentDescription } from '../../utils/helpers/functions';

export const cellRender = (params: any) => (
  <LinkCell
    params={{ ...params, data: { id: params.data.tradingPartnerId }, to: `/trading-partners` }}
    extraPreText="tradingPartner-"
  />
);

export const TradingPartners = () => {
  const isMobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const gridRef = useRef<DataGrid | null>(null);
  const { t: translate } = useTranslation();

  const dateFilterTitle = useAppSelector(selectDateFilterTitle);
  const dateFilterInterval = useAppSelector(selectDateFilterInterval);
  const tenantId = useAppSelector(selectTenantId);

  const [isCustomLoading, setIsCustomLoading] = useState(false);
    

  const { data: tradingPartners, isLoading, isFetching } = useGetV1CustomersByTenantIdTransactionSummaryQuery(
    {
      tenantId: tenantId as string,
      startDate: dateFilterInterval.start.toISO(),
      endDate: dateFilterInterval.end.toISO(),
    },
    {
      skip: !tenantId,
    },
  );

  const calculateTotalOrderNo = (rowData: TransactionSummaryResource) =>
    (rowData.orderSummary && rowData?.orderSummary[0]?.quantity) || undefined;
  const calculateTotalShipmentNo = (rowData: TransactionSummaryResource) => rowData.shipmentQuantity;
  const calculateTotalAcknowledgementNo = (rowData: TransactionSummaryResource) => (rowData.acknowledgementQuantity && rowData.acknowledgementQuantity > 0 ? rowData.acknowledgementQuantity: undefined);
  const calculateTotalInvoiceNo = (rowData: TransactionSummaryResource) =>
    (rowData.invoiceSummary && rowData.invoiceSummary[0]?.quantity) || undefined;

  const calculateFormattedTotalOrderValue = (rowData: TransactionSummaryResource) => {
    // format as currency using specific row currency if have it
    if (rowData.orderSummary) {
      let displayValue = getDataGridRowCurrencyDisplayValue(rowData.orderSummary[0]?.amount, rowData.orderSummary[0]?.currency);
      return displayValue;
    }
    return undefined;
  };
  const calculateFormattedTotalInvoiceValue = (rowData: TransactionSummaryResource) => {
    // format as currency using specific row currency if have it
    if (rowData.invoiceSummary) {
      let displayValue = getDataGridRowCurrencyDisplayValue(rowData.invoiceSummary[0]?.amount, rowData.invoiceSummary[0]?.currency);
      return displayValue;
    }
    return undefined;
  };
  
  const exportToCSV = () => {
    let gridComponent = gridRef.current?.instance;
    exportDataGridToCSV('Trading Partner Activity Report', 'trading-partner-activity-report.csv', gridComponent);
  };

  const dataGridColumns: IColumnProps[] = [
    {
      dataField: 'tradingPartnerName',
      caption: translate('grids.tradingPartner'),
      cellRender,
    },
    { 
      caption: translate('grids.orders'),
      calculateCellValue: calculateTotalOrderNo,
      allowSorting: true 
    },
    {
      caption: translate('grids.orderValue'),
      calculateCellValue: calculateFormattedTotalOrderValue,
      allowSorting: true,
      sortIndex: 0,
      sortOrder: 'desc',
    },
    { 
      caption: translate('grids.acknowledgements'),
      calculateCellValue: calculateTotalAcknowledgementNo, 
      allowSorting: true 
    },
    { 
      caption: translate('grids.shipments'),
      calculateCellValue: calculateTotalShipmentNo, 
      allowSorting: true 
    },
    { 
      caption: translate('grids.invoices'), 
      calculateCellValue: calculateTotalInvoiceNo, 
      allowSorting: true 
    },
    { 
      caption: translate('grids.invoiceValue'), 
      calculateCellValue: calculateFormattedTotalInvoiceValue, 
      allowSorting: true 
    },
  ];

  /* 
    Override of grid function to ContentReadyEvent
    of the grid to be able to call our own spinner
    to display during load 
  */
  const onContentReady = (e: ContentReadyEvent) => {
    // get the DataGrid instance
    let dataGrid = e.component as dxDataGrid;
    
    // when data is being loaded or fetched, 
    // begin custom loading to display spinner 
    // to indicate grid is loading 
    if ((isLoading || isFetching)) {
        if (!isCustomLoading)  {
            dataGrid.beginCustomLoading('');
            setIsCustomLoading(true); 
        }
    } else if (isCustomLoading) {
        // when the rows are actually loaded and we are still in custom loading,
        // then call to end loading, which hides the spinner 
        dataGrid.endCustomLoading();
        setIsCustomLoading(false); 
        // when paging, if user has scrolled to bottom, move scroll back to top
        var scrollable = dataGrid.getScrollable();  
        //scroll to top  
        scrollable.scrollTo(0);
    }
  };

  const gridOptions: IDataGridOptions = {
    dataSource: tradingPartners,
    keyExpr: 'tradingPartnerId',
    rowAlternationEnabled: true,
    height: '70vh',
    scrolling: {
      mode: 'virtual',
    },
    sorting: {
      mode: 'single',
    },
    pager: {
      visible: false,
    },
    paging: {
      enabled: false,
    },
    noDataText: useDataGridNoDataText(isLoading || isFetching),
    onContentReady: onContentReady,
  };

  const getCards = () => {
    let partners = tradingPartners;
    return partners?.map((partner: TransactionSummaryResource | undefined, i: number) => (
      <MobileDataCard key={i}>
        {dataGridColumns.map((column, index) =>
          index === 0 ? (
            <LinkRouter
              key={index}
              fontSize="1rem"
              fontWeight="medium"
              to={`/trading-partners/tradingPartner-${partner?.tradingPartnerId}`}
            >
              {partner?.tradingPartnerName}
              <LaunchIcon sx={{ marginLeft: 0.25, fontSize: '0.875rem' }} color="primary" fontSize="small" />
              <Divider sx={{ marginBlock: 0.25 }} />
            </LinkRouter>
          ) : (
            <MobileDataCardRow
              key={index}
              title={column.caption}
              value={column.calculateCellValue ? column.calculateCellValue(partner) : ''}
            />
          ),
        )}
      </MobileDataCard>
    ));
  };

  const getDisplayText = (): string => {
    return `${translate('tradingPartners.title')} (${dateFilterTitle})`;
  };

  return (
    <>
    <Helmet>
      <meta name="ai:viewId" content="tradingPartners"></meta>
      <meta name="ai:viewDescription" content={buildAIContentDescription(translate('tradingPartners.title'))}></meta>
    </Helmet>
    {isMobileScreen ? (
      <CardSummaryTemplate
        isBusy={isLoading || isFetching}
        pageTitleText={getDisplayText()}
        cards={getCards()}
      />
    ) :
    (
      <Container component="section" disableGutters maxWidth={false}>
        <Grid container spacing={1} justifyContent="space-between" mb={isMobileScreen ? 2 : 1}>
          <Grid item>
            <PageTitle title={getDisplayText()} gutterBottom={false} />
          </Grid>
          <Grid item xs={isMobileScreen} display="flex" justifyContent="flex-end">
            <Button startIcon={<ExportIcon />} variant="contained" onClick={exportToCSV}>
              {translate('grids.export')}
            </Button>
          </Grid>
        </Grid>
        <TableTemplate ref={gridRef} gridOptions={gridOptions}>
          {dataGridColumns.map((col, i) => (
            <Column key={i} {...col} />
          ))}
        </TableTemplate>
      </Container>
    )}
    </>
  );
};
