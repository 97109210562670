import { Container, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { useGetV1DashboardPeriodicActivityQuery, useGetV1DashboardTransactionTotalQuery } from '../../api/dashboard.api';
import { clearException, clearOrder } from '../purchaseOrders/PurchaseOrdersSlice';
import { selectTenantId, selectDateFilterInterval, selectDateFilterTitle } from '../app/AppSlice';
import { DashboardType, selectDashboard, setSelectedDashboard } from './DashboardSlice';

import DashboardCoreTemplate from '../../components/organisms/DashboardCoreTemplate';
import Dashboard3plTemplate from '../../components/organisms/Dashboard3plTemplate';
import DashboardVendorTemplate from '../../components/organisms/DashboardVendorTemplate';
import { buildAIContentDescription } from '../../utils/helpers/functions';
import { StorageKeyActiveDashboard } from '../../utils/Constants';
import { getTenantLocalStorageItem, setTenantLocalStorageItem } from '../../utils/ViewerUtility';

export const Dashboard = () => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  
  const tenantId = useAppSelector(selectTenantId);
  const dateFilterInterval = useAppSelector(selectDateFilterInterval);
  const dateFilterTitle = useAppSelector(selectDateFilterTitle);
  const dashboardTemplate = useAppSelector(selectDashboard);
 
  const { data: transactionsData, isLoading, isFetching } = useGetV1DashboardTransactionTotalQuery(
    {
      tenantId: tenantId as string,
      startDate: dateFilterInterval.start.toISO(),
      endDate: dateFilterInterval.end.toISO(),
    },
    {
      skip: !tenantId,
    },
  );

  const { data: activityQueryData, isLoading: isActivityLoading, isFetching: isActivityFetching } = useGetV1DashboardPeriodicActivityQuery(
    {
      tenantId: tenantId as string,
      startDate: dateFilterInterval.start.toISO(),
      endDate: dateFilterInterval.end.toISO(),
    },
    {
      skip: !tenantId,
    },
  );

  useEffect(() => {
    dispatch(clearOrder());
    dispatch(clearException());
  }, []);

  useEffect(() => {
    if (tenantId) {
      let activeDashboard = getTenantLocalStorageItem(tenantId, StorageKeyActiveDashboard);
      if (!activeDashboard) {
        setTenantLocalStorageItem(tenantId, StorageKeyActiveDashboard, DashboardType.core.toString());
        activeDashboard = DashboardType.core.toString();
      }
      dispatch(setSelectedDashboard(activeDashboard as unknown as DashboardType));
    }
  }, [tenantId]);

  const onTemplateChange = (event: SelectChangeEvent<string>) => {
    let currentValue = event.target.value;
    setTenantLocalStorageItem(tenantId as string, StorageKeyActiveDashboard, currentValue);
    dispatch(setSelectedDashboard(currentValue as DashboardType));
  };

  const getDashboardTemplateList = () => {
    let items = [];        
    const core = <MenuItem key="1" value={DashboardType.core.toString()}>{translate('dashboard.coreDashboard')}</MenuItem>;
    const threePL = <MenuItem key="2" value={DashboardType.threePL.toString()}>{translate('dashboard.3plDashboard')}</MenuItem>;
    const vendor = <MenuItem key="3" value={DashboardType.vendor.toString()}>{translate('dashboard.vendorDashboard')}</MenuItem>;
    items.push(core);
    items.push(threePL);
    items.push(vendor);
    return items;
  };
  
  const getTitle = () => {
    switch (dashboardTemplate) {
      case DashboardType.core:
          return translate('dashboard.coreDashboard');
      case DashboardType.threePL:
          return translate('dashboard.3plDashboard');
      case DashboardType.vendor:
          return translate('dashboard.vendorDashboard');
    }
    return translate('dashboard.coreDashboard');
  };

  const getTemplateSwitch = () => {
    return (
      <Grid item sx={{width: '250px'}} >
          <FormControl variant="standard" fullWidth disabled={isLoading || isFetching || isActivityLoading || isActivityFetching}>
              <Select
                  id="dashboard-template-select"
                  value={dashboardTemplate.toString()}
                  color='primary'
                  variant='outlined'
                  sx={{fontSize:'1.5rem', height: '40px'}}
                  MenuProps={{
                      'aria-label': `${translate("breadcrumbs.dashboard")}`,
                  }}
                  onChange={onTemplateChange}
                  data-cy="dashboard-template-select"
              >
                  {getDashboardTemplateList()}
              </Select>
          </FormControl>
      </Grid>
    );
  }; 
  
  return (
    <Container maxWidth={false} disableGutters>
      <Helmet>
        <meta name="ai:viewId" content="dashboard"></meta>
        <meta name="ai:viewDescription" content={buildAIContentDescription(getTitle())}></meta>
      </Helmet>
      <Grid container mb={3} gap={3} direction="row" justifyContent="flex-start" alignItems="center">
        {getTemplateSwitch()}
        <Typography variant="h5">{dateFilterTitle}</Typography>
      </Grid>
      { dashboardTemplate === DashboardType.core && 
        <DashboardCoreTemplate
          isBusy={isLoading || isFetching || isActivityLoading || isActivityFetching}
          tenantId={tenantId}
          dateFilterInterval={dateFilterInterval}
          transactionsData={transactionsData}
          periodicActivityData={activityQueryData}
          dateFilterTitle={dateFilterTitle}
        />
      }
      { dashboardTemplate === DashboardType.threePL && 
        <Dashboard3plTemplate
          isBusy={isLoading || isFetching || isActivityLoading || isActivityFetching}
          transactionsData={transactionsData}
          periodicActivityData={activityQueryData}
          dateFilterTitle={dateFilterTitle}
        />
      }
      { dashboardTemplate === DashboardType.vendor && 
        <DashboardVendorTemplate
          isBusy={isLoading || isFetching || isActivityLoading || isActivityFetching}
          tenantId={tenantId}
          dateFilterInterval={dateFilterInterval}
          transactionsData={transactionsData}
          periodicActivityData={activityQueryData}
          dateFilterTitle={dateFilterTitle}
        />
      }
    </Container>
  );
};
