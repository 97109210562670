import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TableTemplate } from './TableTemplate';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Column, IColumnProps, IDataGridOptions } from 'devextreme-react/data-grid';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme, Typography } from '@mui/material';
import {
  setSelectedOrder,
  selectSearchTerm,
  changeSearchTerm,
  clearSearchResults,
  selectSearchResults,
  setDisplaySearchResults,
  toggleSearchResultsDialog,
  selectSearchResultsDialogOpen,
} from '../../features/app/AppSlice';
import { PurchaseOrderResource } from '../../api/purchaseOrders.api';
import { createStyles, makeStyles } from '@mui/styles';
import { Helmet } from 'react-helmet-async';
import { buildAIContentDescription } from '../../utils/helpers/functions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orderNumberHighlight: {
      color: theme.palette.primary.main,

      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
);

export const SearchResultsModal = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();  
  const { t: translate } = useTranslation();
  const searchTerm = useAppSelector(selectSearchTerm);
  const searchResults = useAppSelector(selectSearchResults);
  const isModalOpen = useAppSelector(selectSearchResultsDialogOpen);

  const toggleDialogState = (): void => {
    // dispatch(setSelectedOrder(null));
    dispatch(toggleSearchResultsDialog());
    dispatch(changeSearchTerm(''));
    dispatch(clearSearchResults());
  };

  const gridCols: IColumnProps[] = [
    {
      dataField: 'number',
      caption: translate('grids.orderNumber'),
      cellRender: params => (
        <Typography variant="body2" className={styles.orderNumberHighlight}>
          {params.value}
        </Typography>
      ),
    },
    {
      dataField: 'erpOrderNumber',
      caption: translate('grids.erpOrderNumber'),
      cellRender: params => (
        <Typography variant="body2">
          {params.value}
        </Typography>
      ),
    },
    {
      dataField: 'tradingPartnerName',
      caption: translate('grids.tradingPartner'),
    },
  ];

  const gridOptions: IDataGridOptions = {
    dataSource: searchResults,
    rowAlternationEnabled: true,
    height: '35vh',
    scrolling: {
      mode: 'virtual',
    },
    sorting: {
      mode: 'single',
    },
    selection: {
      mode: 'single',
    },
    onSelectionChanged: (e: { selectedRowsData: PurchaseOrderResource[] }): void => {
      const selectedRow = e.selectedRowsData[0];
      dispatch(setSelectedOrder(selectedRow));
      dispatch(toggleSearchResultsDialog());
      dispatch(changeSearchTerm(''));
      dispatch(setDisplaySearchResults(true));
      navigate('/transactions');
    },
    onRowPrepared: event => {
      event.rowElement.style.cursor = 'pointer';
    },
  };

  return (
    <Dialog open={isModalOpen}>
      <Helmet>
        <meta name="ai:viewId" content="userManagement"></meta>
        <meta name="ai:viewDescription" content={buildAIContentDescription(translate('searchResultsDialog.findOrder'))}></meta>
      </Helmet>
      <DialogTitle>{translate('searchResultsDialog.findOrder')}</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          {translate('searchResultsDialog.searchResultsFor')} <strong>'{searchTerm}'</strong>
        </Typography>
        <TableTemplate gridOptions={gridOptions}>
          {gridCols.map((col, i) => (
            <Column key={i} {...col} />
          ))}
        </TableTemplate>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={toggleDialogState}>
          {translate('searchResultsDialog.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
