import { HelmetProvider } from 'react-helmet-async';
import { Interval } from 'luxon';
import { useCallback, useContext, useEffect, useState } from 'react';
import { CloseOutlined } from '@mui/icons-material';
import { createStyles, makeStyles } from '@mui/styles';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { Box, Grid, IconButton, Theme, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { DatePeriod } from '../../static/datePeriods';
import { ThemeModeContext } from '../stylingProvider/StylingProvider';

import { LinkRouter } from '../../components/atoms/LinkRouter';
import { BreadCrumbs } from '../../components/molecules/BreadCrumbs';
import CustomersBreadCrumbs from '../../components/molecules/CustomersBreadCrumbs';
import { ExceptionsBadge } from '../../components/molecules/ExceptionsBadge';
import { DateFilter } from '../../components/organisms/DateFilter';
import { Header } from '../../components/organisms/Header';
import { NavigationMenu } from '../../components/organisms/NavigationMenu';
import { SearchResultsModal } from '../../components/organisms/SearchResultsModal';
import { CustomersHeader } from '../../components/organisms/CustomersHeader';
import SnackbarPrompt, { SnackbarPromptOptions } from '../../components/atoms/SnackbarPrompt';
import { NotificationBanner } from '../../components/molecules/NotificationBanner';
import Customers from '../customers/Customers';

import { getDateRangeForPeriod, getDatePeriodI18nKey } from '../../utils/helpers/functions';
import { getShortDateStringFromDateTime } from '../../utils/helpers/dateTimeUtil';
import { isApteanSSOProvider } from '../../utils/security';
import { getDarkModeForUser, getLanguageForUser } from '../../utils/ViewerUtility';
import { IP_IAM_INDICATOR, QUERY_PARAM_IP, QUERY_PARAM_TENANT_ID, StorageKeyTenantId, ToastSeverity } from '../../utils/Constants';

import {
  setTenantId,
  fetchDateFilterTitle,
  toggleNavigationMenu,
  selectDateFilterTitle,
  fetchDateFilterInterval,
  selectDateFilterInterval,
  fetchDateFilterModalOpen,
  selectNavigationMenuOpen,
  fetchDateFilterSelection,
  selectDateFilterModalOpen,
  selectDateFilterSelection,
  fetchGlobalSearchDrawerOpen,
  selectGlobalSearchDrawerOpen,
  selectTenantId,
  selectCustomerName,
  selectTenantName,
  selectToastConfig,
  setToastConfig,
  setViewerUser,
  setUserHasSingleCustomerSingleTenant,
  setCustomer,
  setTenantName,
  setUserCustomerTenantRole,
  selectCustomerId,
} from './AppSlice';
import { useGetV1UsersQuery, PortalUserCustomerResource, PortalUserResource } from '../../api/users.api';
import { useGetV1ExceptionsByTenantIdQuery } from '../../api/exceptions.api';
import { useGetV1DashboardSystemNotificationQuery } from '../../api/dashboard.api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    app: {
      height: '100vh',
      width: '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
    },
    canvas: {
      flex: '1 1 auto',
      overflow: 'auto',
      height: 'calc(100% - 120px)',
      [theme.breakpoints.up('xl')]: {
        padding: theme.spacing(1, 12),
      },
      [theme.breakpoints.down('xl')]: {
        padding: theme.spacing(5),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
      },
    },
    banner: {
      height: 'calc(100% - 170px)',
    },
    exceptionNotificationBar: {
      background: theme.palette.exceptionNotificationBar.light,
    },
    iconButton: {
      position: 'absolute',
      right: theme.spacing(2),
      color: theme.palette.exceptionNotificationBar.main,
    },
  }),
);


const App: React.FC = () => {
  const helmetContext = {};
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const navMenuOpen = useAppSelector(selectNavigationMenuOpen);
  const dateFilterTitle = useAppSelector(selectDateFilterTitle);
  const dateFilterInterval = useAppSelector(selectDateFilterInterval);
  const dateFilterSelection = useAppSelector(selectDateFilterSelection);
  const dateFilterModalOpen = useAppSelector(selectDateFilterModalOpen);
  const globalSearchDrawerOpen = useAppSelector(selectGlobalSearchDrawerOpen);
  const tenantId = useAppSelector(selectTenantId);
  const tenantName = useAppSelector(selectTenantName);
  const customerId = useAppSelector(selectCustomerId);
  const customerName = useAppSelector(selectCustomerName);
  const toastConfig = useAppSelector(selectToastConfig);
  const [isExceptionBadgeActive, setExceptionBadgeActive] = useState(false);

  const { data: user, isFetching, isLoading, isUninitialized } = useGetV1UsersQuery({});
  const { data: notification } = useGetV1DashboardSystemNotificationQuery();
  // Query for all open exceptions regardless of date
  const { data: exceptionsData } = useGetV1ExceptionsByTenantIdQuery({ tenantId: tenantId as string, returnAllRecords: true }, { skip: !tenantId });

  const { i18n, t: translate } = useTranslation();
  const themeMode = useTheme().palette.mode;
  const colorMode = useContext(ThemeModeContext);

  const [snackbarPromptOpen, setSnackbarPromptOpen] = useState(false);
  const [snackbarPromptOptions, setSnackbarPromptOptions] = useState({} as SnackbarPromptOptions);

  const browserDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  
  const tryGetCustomer = (user: PortalUserResource, tenantIdToMatch: string) => {
    // find the customer that has the edi subscription matching the tenantId
    const customer = user.portalUserCustomers?.find(customer => {
      return !!customer.portalUserCustomerSubscriptions?.find(sub => {
        return sub.portalSubscription && sub.portalSubscription.tenantId === tenantIdToMatch;
      });
    });
    return customer;
  };

  const setCustomerProps = useCallback((customer: PortalUserCustomerResource | undefined) => {
    if (customer) {
      dispatch(setCustomer(customer));

      const userCustomerSubscription = customer.portalUserCustomerSubscriptions?.find(sub => {
          return sub.portalSubscription?.tenantId === tenantId;
      });
      if (userCustomerSubscription) {
          const customerTenantSubscription = userCustomerSubscription.portalSubscription;
          if (customerTenantSubscription?.tenantName) {
              dispatch(setTenantName(customerTenantSubscription.tenantName));
          }
          // user role for this tenant is found in the user customer subscription
          dispatch(setUserCustomerTenantRole(userCustomerSubscription.role));
      }
    }
  },[dispatch, tenantId]);

  const tryGetAndSetTenant = useCallback((user: PortalUserResource) => {
    if (!user.portalUserCustomers?.length || !user.portalUserCustomers[0].portalUserCustomerSubscriptions?.length) {
      localStorage.removeItem(StorageKeyTenantId);
    } else {
      // If the user has only one customer and that customer has only one subscription, 
      // set the tenantId to that subscription,
      // which should trigger the dashboard to load with that tenant
      if (user.portalUserCustomers?.length === 1 && user.portalUserCustomers[0].portalUserCustomerSubscriptions?.length === 1 && user.portalUserCustomers[0].portalUserCustomerSubscriptions[0].portalSubscription) {
        dispatch(setUserHasSingleCustomerSingleTenant(true));
        let customerTenantId = user.portalUserCustomers[0].portalUserCustomerSubscriptions[0].portalSubscription.tenantId;
        localStorage.setItem(StorageKeyTenantId, customerTenantId as string);
        dispatch(setTenantId(customerTenantId));
      } else {
        // Look for tenantId in search params, then local storage
        let paramOrStorageTenantId = searchParams.get(QUERY_PARAM_TENANT_ID);
        if (!paramOrStorageTenantId) {
          paramOrStorageTenantId = localStorage.getItem(StorageKeyTenantId);
        }  
        if (paramOrStorageTenantId) {
          // first see if the user has a customer with this tenantId
          let customer = tryGetCustomer(user, paramOrStorageTenantId);
          // if the user has a customer with this tenantId, store the tenantId
          if (customer) {
            localStorage.setItem(StorageKeyTenantId, paramOrStorageTenantId);
            dispatch(setTenantId(paramOrStorageTenantId));
          }
        }
      }
    }
    
  },[dispatch, searchParams]);
  
  useEffect(() => {
    if (user) {
      dispatch(setViewerUser(user));
      if (tenantId) {
        // find the customer that has the edi subscription matching the tenantId
        const customer = tryGetCustomer(user, tenantId);
        setCustomerProps(customer);
      }
      else {
        // If no tenantId, try to retrieve it through several different avenues
        // If still no tenant found, then the Customers landing page will be shown
        tryGetAndSetTenant(user);
      }
    }
  }, [dispatch, tenantId, user, setCustomerProps, tryGetAndSetTenant]);

  useEffect(() => {
    if (exceptionsData && exceptionsData.items && exceptionsData.items.length > 0) {
      setExceptionBadgeActive(true);
    } else {
      setExceptionBadgeActive(false);
    }
  }, [exceptionsData]);

  useEffect(() => {
    //get and set starting language
    if (user?.portalUserId) {
      const userLang = getLanguageForUser(user?.portalUserId);
      const darkMode = getDarkModeForUser(user?.portalUserId);
      if (userLang) {
        i18n.changeLanguage(userLang);
      }
      if (darkMode) {
        setDarkMode(darkMode);
      } else {
        // No cookie or localstorage for darkmode (if browser pref is dark use that)
        if (browserDarkMode && themeMode !== 'dark') {
          colorMode.toggleColorMode();
        }
      }
    }
  }, [user?.portalUserId]);

  const setDarkMode = (mode: string) => {
    if (mode === "true") {
      // Set Dark Mode
      if (themeMode !== "dark") {
        colorMode.toggleColorMode();
      }
    } else {
      if (themeMode !== "light") {
        colorMode.toggleColorMode();
      }
    }
  } 

  useEffect(() => {
    refreshDateFilterTitle();
  }, [i18n.language]);

  useEffect(() => {
    if (toastConfig && toastConfig.message) {
        let severity = ToastSeverity.Success;
        if (toastConfig.severity) {
            severity = toastConfig.severity;
        }
        setSnackbarPromptOptions({
            anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
            message: toastConfig.message,
            autoHideDuration: 2500,
            transitionDuration: 500,
            severity: severity
        });
        setSnackbarPromptOpen(true);
    }
  }, [toastConfig]);

  const handleOnClose = () => {
    setExceptionBadgeActive(false);
  };
  const handleNavMenuClick = () => {
    dispatch(toggleNavigationMenu());
  };
  
  const toggleDateFilterModal = () => {
    dispatch(fetchDateFilterModalOpen(!dateFilterModalOpen));
  };

  const handleDateFilterChange = (period: DatePeriod, interval: Interval | undefined) => {
    dispatch(fetchDateFilterSelection(period));
    localStorage.setItem('dateFilter', period);
    if (period !== DatePeriod.Custom) {
      dispatch(fetchDateFilterTitle(translate(getDatePeriodI18nKey(period))));
      dispatch(fetchDateFilterInterval(getDateRangeForPeriod(period).toISO()));
    }
    if (period === DatePeriod.Custom && interval) {
      const dateRangeString = `${getShortDateStringFromDateTime(interval.start)} - ${getShortDateStringFromDateTime(interval.end)}`;
      dispatch(fetchDateFilterInterval(interval.toISO()));
      dispatch(fetchDateFilterTitle(dateRangeString));
      localStorage.setItem('dateFilterInterval', interval.toISO());
    }
  };

  const refreshDateFilterTitle = () => {
    handleDateFilterChange(dateFilterSelection, dateFilterInterval);
  };


  const toggleGlobalSearchDrawer = () => {
    dispatch(fetchGlobalSearchDrawerOpen(!globalSearchDrawerOpen));
  };

  const handleSnackBarClose = () => {
    setSnackbarPromptOpen(false);
    dispatch(setToastConfig());
  };

  const getHeaderHeight = () => {
    const headerHeight = document.getElementById('primary-app-bar')?.clientHeight || 0;
    return `${headerHeight}px`;
  };

  const getTenantHomeContent = () => {
    return (
      <>
        <Header
          viewerUser={user}
          handleNavMenuClick={handleNavMenuClick}
          dateFilterTitle={dateFilterTitle}
          handleDateFilterButtonClick={toggleDateFilterModal}
          globalSearchDrawerOpen={globalSearchDrawerOpen}
          toggleGlobalSearchDrawer={toggleGlobalSearchDrawer}
          companyName={tenantName ?? customerName}
        />
        <DateFilter
          open={dateFilterModalOpen}
          handleClose={toggleDateFilterModal}
          dateFilterSelection={dateFilterSelection}
          handleDateFilterChange={handleDateFilterChange}
          customDateFilterInterval={dateFilterInterval}
        />
        <NavigationMenu open={navMenuOpen} handleClose={handleNavMenuClick} appHeaderHeight={getHeaderHeight()}/>
        {notification?.message && (
          <NotificationBanner notification={notification} />
        )}
        {location.pathname === '/' && isExceptionBadgeActive && (
          <Grid container className={classes.exceptionNotificationBar} justifyContent="center" alignItems="center">
            <LinkRouter to="/exceptions">
              <ExceptionsBadge openExceptionsCount={exceptionsData?.totalCount || 0} />
            </LinkRouter>
            <IconButton className={classes.iconButton} title="close" onClick={handleOnClose}>
              <CloseOutlined />
            </IconButton>
          </Grid>
        )}
        <BreadCrumbs />
        <SearchResultsModal />
        <SnackbarPrompt
              open={snackbarPromptOpen}
              snackbarPromptOptions={snackbarPromptOptions}
              handleClose={handleSnackBarClose}
          />
        <Box className={`${classes.canvas} ${notification?.message ? classes.banner : ''}`}>
          <Outlet />
        </Box>
      </>
    );
  };

  const getAppContent = () => {
    // Keeps the edi portal from being shown in the brief time before we identify if the user has an edi subscription
    if (isFetching || isLoading || isUninitialized) {
      return <div>Loading...</div>;
    } else {
      if (user) {
        if (!tenantId && !customerId) {
          return (
            <>
              <CustomersHeader viewerUser={user} />
              {notification?.message && (
                <NotificationBanner notification={notification} />
              )}
              <CustomersBreadCrumbs />
              <Box className={`${classes.canvas} ${notification?.message ? classes.banner : ''}`}>
                <Customers viewerUser={user} customers={user.portalUserCustomers as PortalUserCustomerResource[]} />
              </Box>
            </>
          );
        } else if (customerId && !tenantId) {
          return (
            <>
              <CustomersHeader viewerUser={user} />
              {notification?.message && (
                <NotificationBanner notification={notification}/>
              )}
              <CustomersBreadCrumbs inUserManagement/>
              <Box className={`${classes.canvas} ${notification?.message ? classes.banner : ''}`}>
                <Outlet />
              </Box>
            </>
          );
        } else {
          return getTenantHomeContent();
        }
      } else {
        return <div>Unable to retrieve user account. Please try again. If you continue to get this message, contact customer service.</div>;
      }
    }
  };

  return (
    <HelmetProvider context={helmetContext}>
    <Box className={classes.app}>
      {getAppContent()}
    </Box>
    </HelmetProvider>
  );
};

export default App;
