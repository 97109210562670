import { useAppSelector } from '../../app/hooks';
import { useClearActiveCustomerTenant } from '../../utils/hooks/useClearActiveCustomerTenant';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { Button, capitalize, Container, Grid, Stack, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import DataGrid, { Column, IColumnProps, IDataGridOptions } from 'devextreme-react/data-grid';
import {
  useDeleteV1CustomersByCustomerIdUsersAndUserIdMutation,
  useGetV1CustomersByCustomerIdUsersQuery,
  usePostV1CustomersByCustomerIdUsersMutation,
  usePutV1CustomersByCustomerIdUsersAndUserIdMutation,
  UserForCustomer,
  UserRole,
  UpdateUserResource,
  CreateUserResource,
  PortalSubscriptionResource
} from '../../api/users.api';
import { TableTemplate } from '../../components/organisms/TableTemplate';
import { Role } from '../../utils/Constants';
import { makeDataRowsFromUsers } from './UserManagementApi';
import { selectViewerUser, selectCustomer, selectCustomerId, selectIsUserCustomerAdmin, selectCustomerName, setTenantId, selectTenantId } from '../app/AppSlice';
import AddUserDialog from '../../components/organisms/AddUserDialog';
import DeleteDialog from '../../components/organisms/DeleteDialog';
import { isApteanSSOProvider } from '../../utils/security';
import { Helmet } from 'react-helmet-async';
import { buildAIContentDescription } from '../../utils/helpers/functions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      padding: theme.spacing(0),
    },
    userManagementButton: {
      paddingLeft: '0px',
      paddingRight: theme.spacing(2),
    },
  }),
);

const UserManagement: React.FC = () => {
  const classes = useStyles();
  const clearActiveCustomerTenant = useClearActiveCustomerTenant();
  const navigate = useNavigate();  
  const { t: translate } = useTranslation();
  const viewerUser = useAppSelector(selectViewerUser);
  const customerId = useAppSelector(selectCustomerId);
  const customer = useAppSelector(selectCustomer);
  const customerName = useAppSelector(selectCustomerName);
  const tenantId = useAppSelector(selectTenantId);
  const isAdmin = useAppSelector(selectIsUserCustomerAdmin);
  const [dataRows, setDataRows] = useState<any[]>([]);
  const [addEditOpen, setAddEditOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState<string | undefined>();
  const [addUserErrorMessage, setAddUserErrorMessage] = useState<string | undefined>();
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [numSelectedUsers, setNumSelectedUsers] = useState(0);
  const [userForEdit, setUserForEdit] = useState<UserForCustomer | undefined>();
  const [apiStatus, setApiStatus] = useState<QueryStatus>(QueryStatus.uninitialized);
  const [emails, setEmails] = useState<string[]>([]);
  const [customerSubs, setCustomerSubs] = useState<PortalSubscriptionResource[]>([]);
  // Because the delete api call returns a 204 on succesful delete, the network response does not provide a successful payload on delete. This state will be used to accurately close the dialog on succesful api call.
  const [deleteDialogToClose, setDeleteDialogToClose] = useState(false);
  
  const { data: users } = useGetV1CustomersByCustomerIdUsersQuery({ customerId: customerId || '' }, { skip: !customerId });
  const [createUser, createResult] = usePostV1CustomersByCustomerIdUsersMutation();
  const [deleteUser, deleteResult] = useDeleteV1CustomersByCustomerIdUsersAndUserIdMutation();
  const [updateUser, updateResult] = usePutV1CustomersByCustomerIdUsersAndUserIdMutation();

  const isIamUser = isApteanSSOProvider();

  const gridRef = useRef<DataGrid | null>(null);

  useEffect(() => {
    // this is to handle the case where the user navigates 
    // to the user management page directly from the customers page
    // and then hits the browser back button instead of using the
    // breadcrumbs or logo to navigate back to the customers page
    window.onpopstate = () => {
      if (customerId && !tenantId) {
        // when the user came from the customers page and 
        // wants to go back, remove the customer id from local storage
        // to force the customers page to load again
        clearActiveCustomerTenant(true);
        navigate('/');
      }
    };
  }, [customerId, tenantId]);
  
  useEffect(() => {
    if (users) {
      setDataRows(makeDataRowsFromUsers(users));
      const emails = users.reduce<string[]>((result, user) => {
        if (user.email) {
          result.push(user.email);
        }
        return result;
      }, []);
      setEmails(emails);
    }
  }, [users]);

  useEffect(() => {
    if (customer && !isAdmin) {
      navigate('/');
    }
  }, [customer, isAdmin]);

  useEffect(() => {
    if (customer) {    
      if (customer.portalCustomer && customer.portalCustomer.portalSubscriptions) {
        // save off the subscriptions for the customer as the available subscriptions for the user
        const subscriptions = customer.portalCustomer.portalSubscriptions;
        setCustomerSubs(subscriptions);
      }
    }
  }, [customer]);

  useEffect(() => {
    // If we have one user selected, use that user for updating/deleting
    if (selectedRowIds.length === 1) {
      const id = selectedRowIds[0];
      const user = users?.find(user => user.id === id);
      setUserForEdit(user);
    } else {
      setUserForEdit(undefined);
    }
  }, [selectedRowIds, users]);

  useEffect(() => {
    setApiStatus(createResult.status);
    if (createResult.status === QueryStatus.rejected) {
      setAddUserErrorMessage(`${translate('userManagement.errorMessages.createError')}`);
    } else {
      setAddUserErrorMessage(undefined);
    }
  }, [createResult.status]);

  useEffect(() => {
    if (deleteResult.status === QueryStatus.rejected) {
      setDeleteErrorMessage(`${translate('userManagement.errorMessages.deleteError')}`);
    } else {
      setDeleteErrorMessage(undefined);
      // Close the dialog if the delete was successful
      if (deleteResult.status === QueryStatus.fulfilled && openDelete && deleteDialogToClose) {
        setOpenDelete(false);
        setDeleteDialogToClose(false);
      }
    }
  }, [deleteResult.status, openDelete, deleteDialogToClose]);

  useEffect(() => {
    setApiStatus(updateResult.status);
    if (updateResult.status === QueryStatus.rejected) {
      setAddUserErrorMessage(`${translate('userManagement.errorMessages.updateError')}`);
    } else {
      setAddUserErrorMessage(undefined);
    }
  }, [updateResult.status]);

  const onAddUserClick = () => {
    setAddEditOpen(true);
  };

  const onRemoveUsersClick = () => {
    if (numSelectedUsers) {
      setOpenDelete(true);
    }
  };

  const onDeleteUserConfirm = async (id: string) => {
    if (viewerUser && id && customerId) {
      if (id === viewerUser.portalUserId) {
        setDeleteErrorMessage(`${translate('userManagement.errorMessages.noDeleteSelf')}`);
      } else {
        // Flag the delete user dialog to be closed on fulfilled api call
        setDeleteDialogToClose(true);
        await deleteUser({ customerId: customerId, userId: id });
      }
    }
  };

  const onDeleteDialogClose = () => {
    setDeleteErrorMessage(undefined);
    setOpenDelete(false);
  };

  const onSuccessfulEdit = (subs?: PortalSubscriptionResource[]) => {
    if (tenantId && userForEdit?.id === viewerUser?.portalUserId) {
      // if the user that was edited is the viewerUser and the active tenantId
      // is no longer in the user's subscriptions, remove the tenantId from local storage and state
      if (!subs?.find(sub => sub.tenantId === tenantId)) {
        //remove tenant id from local storage and state to remove links/breadcrumbs to the tenant
        clearActiveCustomerTenant();
      }
    }
    // If the api call is successful, close the dialog
    onAddClose();
  };

  const onAddEditSave = async (firstName: string, lastName: string, email: string, commerceRole?: UserRole, ediRole?: UserRole, subscriptions?: PortalSubscriptionResource[]) => {
    if (!customerId) {
      setAddUserErrorMessage(`${translate('userManagement.errorMessages.noAuthorization')}`);
      return;
    }
    // commenting this out for now since as of now, a subscription is not required to save
    // const viewerUserSubscriptions = customer?.portalUserCustomerSubscriptions;
    // if (!viewerUserSubscriptions) {
    //   setAddUserErrorMessage(`${translate('userManagement.errorMessages.noUserSubscriptions')}`);
    //   return;
    // }
    if (userForEdit && userForEdit.id) {
      // Grab the response from the api call
      const payload = await updateUser({
        customerId: customerId,
        userId: userForEdit.id,
        updateUserResource: { firstName, lastName, email, customerRole: commerceRole, subscriptionRole: ediRole, subscriptions } as UpdateUserResource,
      }).unwrap();
      if (payload) {
        onSuccessfulEdit(subscriptions);
      }
    } else {
      const payload = await createUser({
        customerId: customerId,
        createUserResource: { firstName, lastName, email, customerRole: commerceRole, subscriptionRole: ediRole, subscriptions } as CreateUserResource,
      }).unwrap();
      if (payload) {
        // If the api call is successful, close the dialog
        onAddClose();
      }
    }
  };

  const onAddClose = () => {
    setAddUserErrorMessage(undefined);
    // Clear any row selection and close the dialog
    gridRef?.current?.instance?.clearSelection();
    setAddEditOpen(false);
  };

  const onGridSelectionChanged = (e: any) => {
    if (e && e.hasOwnProperty('selectedRowKeys')) {
      setSelectedRowIds(e.selectedRowKeys);
      setNumSelectedUsers(e.selectedRowKeys.length);
    }
  };

  const mapRoleToString = (role?: UserRole) => {
    if (role) {
      if (role === 'unknown') {
        return undefined;
      } else {
        return capitalize(role);
      }
    }
    return undefined;
  };

  const renderCommerceRole = (rowData: { commerceRole: any }) => mapRoleToString(rowData.commerceRole);
  const renderEdiRole = (rowData: { ediRole: any }) => mapRoleToString(rowData.ediRole);

  const gridCols = [
    {
      dataField: 'name',
      caption: translate('userManagement.name'),
      sortOrder: 'asc',
    },
    {
      dataField: 'email',
      caption: translate('userManagement.email'),
    },
    {
      dataField: 'commerceRole',
      caption: translate('userManagement.portRole'),
      calculateCellValue: renderCommerceRole,
    },
    {
      dataField: 'ediRole',
      caption: translate('userManagement.ediRole'),
      calculateCellValue: renderEdiRole,
    },
  ] as IColumnProps[];

  const gridOptions: IDataGridOptions = {
    dataSource: dataRows,
    height: '60vh',
    rowAlternationEnabled: true,
    scrolling: {
      mode: 'virtual',
      preloadEnabled: true,
    },
    sorting: {
      mode: 'multiple',
    },
    selection: {
      mode: 'multiple',
      showCheckBoxesMode: 'always',
      allowSelectAll: false,
    },
    onSelectionChanged: onGridSelectionChanged,
  };

  const getGridColumns = () => {
    return gridCols.map((col, index) => <Column key={index} {...col} />);
  };

  return (
    <Container maxWidth={false} className={classes.rootContainer} disableGutters>
      <Helmet>
        <meta name="ai:viewId" content="userManagement"></meta>
        <meta name="ai:viewDescription" content={buildAIContentDescription(`${translate('userManagement.userManagement')} - ${customerName}`)}></meta>
      </Helmet>
      <Stack spacing={2}>
        <Typography component="h1" variant="h6" fontSize={28}>
          {customerName}
        </Typography>
        <Grid container justifyContent="flex-start">
          <Grid item className={classes.userManagementButton}>
            <Button 
              variant="outlined" 
              startIcon={<PersonAddIcon />} 
              onClick={onAddUserClick}
              disabled={isIamUser && !userForEdit}
            >
              {userForEdit || isIamUser ? translate('userManagement.editUser') : translate('userManagement.addUser')}
            </Button>
          </Grid>
          { !isIamUser &&
          <Grid item className={classes.userManagementButton}>
            <Button
              variant="outlined"
              startIcon={<PersonRemoveIcon />}
              onClick={onRemoveUsersClick}
              disabled={
                numSelectedUsers !== 1 ||
                userForEdit?.id === viewerUser?.portalUserId ||
                userForEdit?.role === Role.Primary
              } /** Disable delete button if more than one user is selected, is the viewer user, or has a primary role. */
            >
              {translate('userManagement.deleteUser')}
            </Button>
          </Grid>
          }
        </Grid>
        <TableTemplate ref={gridRef} gridOptions={gridOptions}>{getGridColumns()}</TableTemplate>
      </Stack>
      
      <AddUserDialog
        isOpen={addEditOpen}
        onSave={onAddEditSave}
        onClose={onAddClose}
        viewerUser={viewerUser}
        userForEdit={userForEdit}
        apiStatus={apiStatus}
        errorMessage={addUserErrorMessage}
        emails={emails}
        availableSubscriptions={customerSubs}
        isIamUser={isIamUser}
      />
      
      <DeleteDialog
        isOpen={openDelete}
        id={userForEdit?.id ?? ''}
        heading={translate('userManagement.delete')}
        message={`${translate('userManagement.confirmation')} ${userForEdit?.firstName} ${userForEdit?.lastName}`}
        onConfirm={onDeleteUserConfirm}
        onReject={onDeleteDialogClose}
        errorMessage={deleteErrorMessage}
      /> 
      
    </Container>
  );
};

export default UserManagement;