import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from "../../app/store";
import { DateTime } from 'luxon';
import { NormalizedDocumentType } from '../../api/customers.api';

export interface TransactionsFilterSet {
  filterOrderNum?: string;
  filterDateFrom?: DateTime;
  filterDateTo?: DateTime;
  filterDocType?: NormalizedDocumentType;
  filterPartner?: string;
  filterDocNum?: string;
  filterErpOrderNum?: string;
  filterTransactionType?: string;
  filterHasException?: boolean;
  filterIsDocumentResent?: boolean;
};

export interface TransactionsState {
    filters?: TransactionsFilterSet;
    documentTypes?: NormalizedDocumentType[];
    fetchForExportError?: Error;
    exportDocument?: string;
    getExportTransactionsStatus?: any;
};

const initialState: TransactionsState = {
    filters: undefined,
    documentTypes: undefined,
    fetchForExportError: undefined,
    exportDocument: undefined,
    getExportTransactionsStatus: undefined
};

export const slice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        clearTransactionFilters: state => {
          if (state.filters) {
              // eslint-disable-next-line no-param-reassign
              state.filters = undefined;
          }
        },
        clearFetchForExport: state => {
          // eslint-disable-next-line no-param-reassign
          state.fetchForExportError = undefined;
          // eslint-disable-next-line no-param-reassign
          state.exportDocument = undefined;
          // eslint-disable-next-line no-param-reassign
          state.getExportTransactionsStatus = undefined;
        },
        setTransactionFilters: (state, action: PayloadAction<TransactionsFilterSet>) => {
          // eslint-disable-next-line no-param-reassign
          state.filters = action.payload;
        },
        setDocumentTypes: (state, action: PayloadAction<NormalizedDocumentType[]>) => {
          // eslint-disable-next-line no-param-reassign
          state.documentTypes = action.payload;
        },
        setExportDocument: (state, action: PayloadAction<string | undefined>) => {
          // eslint-disable-next-line no-param-reassign
          state.exportDocument = action.payload;
        },
        setExportTransactionsStatus: (state, action: PayloadAction<any | undefined>) => {
          // eslint-disable-next-line no-param-reassign
          state.getExportTransactionsStatus = action.payload;
        },
        setFetchForExportError: (state, action: PayloadAction<Error | undefined>) => {
          // eslint-disable-next-line no-param-reassign
          state.fetchForExportError = action.payload;
        },
    },
    extraReducers: builder => {},
  });

  export const selectTransactionFilters = (state: RootState): TransactionsFilterSet | undefined => state.transactions.filters;
  export const selectDocumentTypes = (state: RootState): NormalizedDocumentType[] | undefined => state.transactions.documentTypes;
  export const selectFetchForExportError = (state: RootState): Error | undefined => state.transactions.fetchForExportError;
  export const selectExportDocument = (state: RootState): string | undefined => state.transactions.exportDocument;
  export const selectGetExportTransactionsStatus = (state: RootState): any | undefined => state.transactions.getExportTransactionsStatus;
  
  export const {
      clearTransactionFilters,
      clearFetchForExport,
      setTransactionFilters,
      setDocumentTypes,
      setFetchForExportError,
      setExportDocument,
      setExportTransactionsStatus
  } =  slice.actions;

export default slice.reducer;