import { t } from 'i18next';
import { capitalize } from '@mui/material';
import { DateTime, Interval } from 'luxon';
import { DatePeriod } from '../../static/datePeriods';
import { getShortDateStringFromDateTime } from './dateTimeUtil';
import { NormalizedDocumentType } from '../../api/customers.api';
import { AppTitle } from '../Constants';

export const reduceCallback = (acc: number, curr: { value: number }): number => {
  if (!curr.value) return acc;
  return acc + curr.value;
};

export const prepareRow = (e: any) => {
  if (e.rowType !== 'header') {
    e.rowElement.style.height = '75px';
  }
};

export const getDocumentTypeName = (documentType: NormalizedDocumentType | undefined) => {
  if (documentType) {
    let translationValue = `documentTypes.${documentType}`;
    return t(translationValue);
  } else {
    return '';
  }
};

export const getQuarter = (date: Date) => Math.floor(date.getMonth() / 3 + 1);

export const getCurrentQuarter = () => getQuarter(new Date());

export const generatePresetDatePeriods = () => {
  const allPeriods = Object.values(DatePeriod);
  const validPeriods: DatePeriod[] = [];
  const currentQuarter = DateTime.now().quarter;
  allPeriods.forEach(period => {
    if (
      period === DatePeriod.Custom ||
      (period === DatePeriod.FirstQuarter && currentQuarter <= 1) ||
      (period === DatePeriod.SecondQuarter && currentQuarter <= 2) ||
      (period === DatePeriod.ThirdQuarter && currentQuarter <= 3)
    ) {
      return;
    }
    validPeriods.push(period);
  });
  return validPeriods;
};

export const getDatePeriodString = (period: DatePeriod) => {
  switch (period) {
    default:
    case DatePeriod.Today:
      return "Today";
    case DatePeriod.Week:
      return "This Week";
    case DatePeriod.Month:
      return "This Month";
    case DatePeriod.Quarter:
      return "This Quarter";
    case DatePeriod.FirstQuarter:
      return "1st Quarter";
    case DatePeriod.SecondQuarter:
      return "2nd Quarter";
    case DatePeriod.ThirdQuarter:
      return "3rd Quarter";
    case DatePeriod.Year:
      return "This Year";
    case DatePeriod.LastWeek:
      return "Last Week";
    case DatePeriod.LastMonth:
      return "Last Month";
  }
}

export const getDatePeriodI18nKey = (period: DatePeriod) => {
  switch (period) {
    default:
    case DatePeriod.Today:
      return "datePickerDialog.periodOptions.today";
    case DatePeriod.Week:
      return "datePickerDialog.periodOptions.thisWeek";
    case DatePeriod.Month:
      return "datePickerDialog.periodOptions.thisMonth";
    case DatePeriod.Quarter:
      return "datePickerDialog.periodOptions.thisQuarter";
    case DatePeriod.FirstQuarter:
      return "datePickerDialog.periodOptions.firstQuarter";
    case DatePeriod.SecondQuarter:
      return "datePickerDialog.periodOptions.secondQuarter";
    case DatePeriod.ThirdQuarter:
      return "datePickerDialog.periodOptions.thirdQuarter";
    case DatePeriod.Year:
      return "datePickerDialog.periodOptions.thisYear";
    case DatePeriod.LastWeek:
      return "datePickerDialog.periodOptions.lastWeek";
    case DatePeriod.LastMonth:
      return "datePickerDialog.periodOptions.lastMonth";
  }
}

export const getDateRangeForPeriod = (period: DatePeriod) => {
  let startDate: DateTime;
  let endDate: DateTime;
  const now = DateTime.now().toUTC();
  switch (period) {
    default:
    case DatePeriod.Today:
      startDate = now.startOf('day');
      endDate = now.endOf('day');
      break;
    case DatePeriod.Week:
      startDate = now.startOf('week');
      endDate = now.endOf('week');
      break;
    case DatePeriod.Month:
      startDate = now.startOf('month');
      endDate = now.endOf('month');
      break;
    case DatePeriod.Quarter:
      startDate = now.startOf('quarter');
      endDate = now.endOf('quarter');
      break;
    case DatePeriod.FirstQuarter:
      const firstQuarterDate = DateTime.local(now.year, 1, 1);
      startDate = firstQuarterDate.startOf('quarter');
      endDate = firstQuarterDate.endOf('quarter');
      break;
    case DatePeriod.SecondQuarter:
      const secondQuarterDate = DateTime.local(now.year, 4, 1);
      startDate = secondQuarterDate.startOf('quarter');
      endDate = secondQuarterDate.endOf('quarter');
      break;
    case DatePeriod.ThirdQuarter:
      const thirdQuarterDate = DateTime.local(now.year, 7, 1);
      startDate = thirdQuarterDate.startOf('quarter');
      endDate = thirdQuarterDate.endOf('quarter');
      break;
    case DatePeriod.Year:
      startDate = now.startOf('year');
      endDate = now.endOf('year');
      break;
    case DatePeriod.LastWeek:
      const lastWeek = now.minus({ weeks: 1 });
      startDate = lastWeek.startOf('week');
      endDate = lastWeek.endOf('week');
      break;
    case DatePeriod.LastMonth:
      const lastMonth = now.minus({ months: 1 });
      startDate = lastMonth.startOf('month');
      endDate = lastMonth.endOf('month');
      break;
  }
  return Interval.fromDateTimes(startDate, endDate);
};

export const getEndDateForSpecificDate = (date: DateTime, globalInterval: Interval) => {
  let newStartDate = date;
  let newEndDate = newStartDate.endOf('day');
  let endDate = globalInterval.end;
  let startDate = globalInterval.start;
  let daysDiff = endDate.diff(startDate, 'days').days;

  // the backend creates sub-ranges for periodic activity that vary based
  // one the overall number of days in the global/cached range
  // so we need that same logic here to determine what the end date of the
  // specific date param should be to get the correct interval to display
  if (daysDiff <= 31) {
    return newEndDate;
  } else if (daysDiff <= 92) {
    return newStartDate.plus({days:7});
  } else if (daysDiff <= 545) {
    return newStartDate.plus({months:1});
  } else if (daysDiff > 545) {
    return newStartDate.plus({years:1});
  } else {
    return newEndDate;
  }
};

export const getDateRangeForSpecificDate = (date: string, globalInterval: Interval) => {
  let dateTimeFromISO = DateTime.fromISO(date);
  let startDate: DateTime;
  let endDate: DateTime;
 
  if (dateTimeFromISO.isValid) {
    startDate = dateTimeFromISO.startOf('day');
    endDate = getEndDateForSpecificDate(dateTimeFromISO, globalInterval);
    return Interval.fromDateTimes(startDate, endDate);
  }
  
  return undefined;
  
};

export const getDateFilterTitleForInterval = (dateInterval: Interval, locale?: string) => {
  if (dateInterval.isValid) {
    // only display the start date if end date is same day
    let daysDiff = dateInterval.end.diff(dateInterval.start, 'days').days;
    if (daysDiff < 1) {
      return getShortDateStringFromDateTime(dateInterval.start, locale);
    }
    return `${getShortDateStringFromDateTime(dateInterval.start, locale)} - ${getShortDateStringFromDateTime(dateInterval.end, locale)}`;
  }
  return undefined;
}

interface DateFilter {
  period: DatePeriod | undefined;
  title: string | undefined;
  interval: Interval | undefined;
}

export const getDateFilterFromLocalStorage = () => {
  const dateFilter: DateFilter = { period: undefined, title: undefined, interval: undefined };
  const localDateFilter = localStorage.getItem('dateFilter');
  if (!localDateFilter || !(Object.values(DatePeriod).includes(localDateFilter as DatePeriod))) {
    return dateFilter;
  }
  dateFilter.period = localDateFilter as DatePeriod;

  if (localDateFilter === DatePeriod.Custom) {
    const localDateInterval = Interval.fromISO(localStorage.getItem('dateFilterInterval') || '');
    if (localDateInterval.isValid) {
      dateFilter.interval = localDateInterval;
      dateFilter.title = getDateFilterTitleForInterval(localDateInterval);
    }
  } else {
    dateFilter.interval = getDateRangeForPeriod(localDateFilter as DatePeriod);
    dateFilter.title = getDatePeriodString(localDateFilter as DatePeriod);
  }
  return dateFilter;
}

export const capitalizeWithUnderscoreRemoval = (value: string, joiningCharacter?: string) => {
  // scenarios where could have for example, resolved_error value and want to return Resolved Error
  const valueParts = value?.split('_');
  let capitalizedParts: string[] = [];
  valueParts?.forEach(p => capitalizedParts.push(capitalize(p)));
  const displayValue = capitalizedParts.join(joiningCharacter ?? '');
  return displayValue;
}

export const buildAIContentDescription = (translatedPageTitle: string) => {
  return `${AppTitle()} - ${translatedPageTitle}`;
}
