import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useDataGridNoDataText } from '../../utils/hooks/useDataGridNoDataText';
import { DateTime } from 'luxon';
import { IconButton, Divider, Theme, Typography, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Column, IColumnProps, IDataGridOptions } from 'devextreme-react/data-grid';
import dxDataGrid, { ContentReadyEvent } from 'devextreme/ui/data_grid';
import LaunchIcon from '@mui/icons-material/Launch';
import ResendIcon from '@mui/icons-material/Redo';
import WarningIcon from '@mui/icons-material/Warning';
import { MobileDataCard } from '../../components/molecules/MobileDataCard';
import { MobileDataCardRow } from '../../components/atoms/MobileDataCardRow';
import { LinkRouter } from '../../components/atoms/LinkRouter';
import { LinkCell } from '../../components/atoms/LinkCell';
import { DownloadSplitButton } from '../../components/atoms/DownloadSplitButton';
import { selectTenantId, selectDateFilterInterval, selectDateFilterTitle, selectIsUserCustomerTenantAdmin } from '../app/AppSlice';
import { clearTransaction, selectTransactions, selectTransactionsPaginationCount, setTransactions, setTransactionsPaginationCount } from './DocumentTypeTransactionsSlice';
import { ExceptionFilterSet, clearExceptionFilters, setExceptionFilters } from '../exceptions/ExceptionsSlice';
import { setOrderException } from '../purchaseOrders/PurchaseOrdersSlice';
import { NormalizedDocumentType, TransactionResource, useGetV1CustomersByTenantIdTransactionsQuery } from '../../api/customers.api';
import CardSummaryTemplate from '../../components/organisms/CardSummaryTemplate';
import SummaryTemplate from '../../components/organisms/SummaryTemplate';
import ListWithCommonActions from '../../components/organisms/ListWithCommonActions';
import { getDateRangeForSpecificDate, getDateFilterTitleForInterval } from '../../utils/helpers/functions';
import { getFormattedDateTimeString } from '../../utils/helpers/dateTimeUtil';
import { useGetSummaryData } from '../../utils/summaryData';
import { useTranslation } from 'react-i18next';
import { getDataGridRowCurrencyDisplayValue, getSummaryTotalDisplayValue } from '../../utils/helpers/currencyFormatUtil';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        downloadAll: {
            color: theme.palette.primary.main,
            cursor: 'pointer',
        },
        resendButton: {
            color: theme.palette.primary.main,
            height: '16px',
            padding: '0',
            '&:hover': {
                color: theme.palette.primary.dark,
                fontWeight: 'bold'
            }
        },
        // this class will be used once we have a way to tell if a document was already previously resent
        resendAgainButton: {
            color: theme.palette.warning.main,
            height: '16px',
            padding: '0',
            '&:hover': {
                color: theme.palette.warning.dark,
                fontWeight: 'bold'
            }
        },
        exceptionIcon: {
            color: theme.palette.exceptionNotificationBar.main,
            height: '20px',
            fontSize: '20px',
            cursor: 'pointer',
        },
        exceptionIconMobile: {
            color: theme.palette.exceptionNotificationBar.main,
            height: '24px',
            fontSize: '24px',
            cursor: 'pointer',
        },
    }),
);

interface DocTypeTransactionsProps {
    timePeriod?: 'today' | 'quarterly' | undefined;
    documentType: NormalizedDocumentType;
    displayValueColumn?: boolean;
}

const DocumentTypeTransactions: FC<DocTypeTransactionsProps> = props => {
    const { documentType, displayValueColumn=false } = props;
    const isMobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { i18n, t: translate } = useTranslation();
    const { activityDate: activityDateFilter, order: orderNumber } = useParams();
    
    const isUserTenantAdmin = useAppSelector(selectIsUserCustomerTenantAdmin);
    const docTypeTransactions = useAppSelector(selectTransactions);
    const transactionsPaginationTotal = useAppSelector(selectTransactionsPaginationCount);
    const cachedDateFilterTitle = useAppSelector(selectDateFilterTitle);
    const cachedDateFilterInterval = useAppSelector(selectDateFilterInterval);
    const tenantId = useAppSelector(selectTenantId);

    const [isCustomLoading, setIsCustomLoading] = useState(false);
    const [isFetchingDocument, setIsFetchingDocument] = useState(false);
    const [offset, setOffset] = useState(0);
    const [pageSize, setPageSize] = useState(isMobileScreen ? 5 : 25);
    const [dateFilterTitle, setDateFilterTitle] = useState(cachedDateFilterTitle);
    const [dateFilterInterval, setDateFilterInterval] = useState(cachedDateFilterInterval);
    const [selectedDataRow, setSelectedDataRow] = useState<TransactionResource | undefined>(undefined);
    const [openResendDialog, setOpenResendDialog] = useState(false);
    const [downloadHtmlClicked, setDownloadHtmlClicked] = useState(false);
    const [downloadRawEdiClicked, setDownloadRawEdiClicked] = useState(false);
    const [download997Clicked, setDownload997Clicked] = useState(false);
    const [header, setHeader] = useState<string>('');

    // Get Documents for customer
    const { refetch, data: transactionsData, isLoading, isFetching } = useGetV1CustomersByTenantIdTransactionsQuery(
        {
            tenantId: tenantId as string,
            startDate: dateFilterInterval.start.toISO(),
            endDate: dateFilterInterval.end.toISO(),
            docType: documentType,
            orderNumber: orderNumber,
            limit: pageSize,
            offset: offset,
        },
        { 
            refetchOnMountOrArgChange: true,
            skip: !tenantId
        }
    );

    // Get transactions summary
    const summaryData = useGetSummaryData(tenantId as string, dateFilterInterval.start.toISO(), dateFilterInterval.end.toISO(), documentType, orderNumber);

    const titleKey = `documentTypeTransactions.title-${documentType}`;

    const setPageTitle = useCallback(() => {
        if (orderNumber) {
            setHeader(`${translate(titleKey)} ${translate("documentTypeTransactions.orderTitle")} ${orderNumber} (${dateFilterTitle})`);
        } else {
            setHeader(`${translate(titleKey)} (${dateFilterTitle})`);
        }
    }, [orderNumber, dateFilterTitle, titleKey, translate]);

    useEffect(() => {
        setPageTitle();
    }, [dateFilterInterval, dateFilterTitle, i18n.language, orderNumber, documentType, setPageTitle]);

    useEffect(() => {
        // if an activityDate was passed in the url params,
        // use that for the dateFilterInterval instead of the cached interval from the header filter
        if (activityDateFilter) {
            let filterInterval = getDateRangeForSpecificDate(activityDateFilter, cachedDateFilterInterval);
            if (filterInterval) {
                setDateFilterInterval(filterInterval);
                let filterTitle = getDateFilterTitleForInterval(filterInterval, i18n.language);
                if (filterTitle) {
                    setDateFilterTitle(filterTitle);
                }
            }
        } else {
            setDateFilterTitle(cachedDateFilterTitle);
        }
    }, [activityDateFilter, i18n.language, cachedDateFilterTitle, cachedDateFilterInterval]);
    
    useEffect(() => {
        if (!activityDateFilter && cachedDateFilterInterval.toISO() !== dateFilterInterval.toISO()) {
            setDateFilterInterval(cachedDateFilterInterval);
            setDateFilterTitle(cachedDateFilterTitle);
        }
    }, [cachedDateFilterInterval, cachedDateFilterTitle, dateFilterInterval, activityDateFilter])

    useEffect(() => {
        dispatch(clearTransaction());
        if (!!docTypeTransactions && transactionsData && transactionsData.items) {
            dispatch(setTransactions(transactionsData.items));
            dispatch(setTransactionsPaginationCount(transactionsData.totalCount || 0));
        }
    }, [transactionsData]);

    const getRowDocumentNumber = (rowData: { documentNumber: any }) => rowData.documentNumber;
    const getRowOrderNumber = (rowData: { orderNumber: any }) => rowData.orderNumber;
    const getRowTradingPartner = (rowData: { tradingPartnerName: any }) => rowData.tradingPartnerName;
    const getRowDate = (rowData: { date: any }) => getFormattedDateTimeString(rowData.date, { format: DateTime.DATETIME_MED });
    const getRowValue = (rowData: TransactionResource) => {
        // format as currency using specific row currency if have it
        const { value, currency } = rowData;
        let displayValue = getDataGridRowCurrencyDisplayValue(value, currency ?? summaryData.currency);
        return displayValue;
    };
    const getRowNumberOfLines = (rowData: { lines: any }) => rowData.lines;
    const getRowTotalQty = (rowData: { quantity: any }) => rowData.quantity;
    const getRowHasExceptions = (rowData: TransactionResource) => Object.keys(rowData.exceptions || {}).length > 0;
    
    const toggleIsFetchingDocument = (isFetching: boolean) => {
        setIsFetchingDocument(isFetching);
    };

    const getRootRoutePath = () => {
        let path = '/transactions';
        switch (documentType) {
            case 'warehouseShippingOrder':
                path = '/warehouse-shipping-order';
                break;
            case 'warehouseShippingAdvice':
                path = '/warehouse-shipping-advice';
                break;
            case 'warehouseInventoryAdjustmentAdvice':
                path = '/warehouse-inventory-advice';
                break;
            case 'stockTransfer':
                path = '/stock-transfer';
                break;
            case 'stockReceipt':
                path = '/stock-receipt';
                break;
            case 'purchaseOrderOutbound':
                path = '/purchase-orders-outbound';
                break;
            case 'invoiceInbound':
                path = '/invoices-inbound';
                break;
            case 'shipmentInbound':
                path = '/shipments-inbound';
                break;
            case 'acknowledgementInbound':
                path = '/acknowledgements-inbound';
                break;
        }
        return path;
    };

    const getPageViewId = () => {
        switch (documentType) {
            case 'warehouseShippingOrder':
                return 'warehouseShippingOrders';
            case 'warehouseShippingAdvice':
                return 'warehouseShippingAdvice';
            case 'warehouseInventoryAdjustmentAdvice':
                return 'warehouseInventoryAdjustmentAdvice';
            case 'stockTransfer':
                return 'stockTransfers';
            case 'stockReceipt':
                return 'stockReceipts';
            case 'purchaseOrderOutbound':
                return 'purchaseOrdersOutbound';
            case 'invoiceInbound':
                return 'invoicesInbound';
            case 'shipmentInbound':
                return 'shipmentsInbound';
            case 'acknowledgementInbound':
                return 'acknowledgementsInbound';
        }
        return 'DocumentTransactions';
    };

    const onExceptionIconClick  = (rowData: TransactionResource) => {
        // format as currency using specific row currency if have it
        const { purchaseOrderId, orderNumber, documentType, tradingPartnerId } = rowData;
        
        dispatch(setOrderException(purchaseOrderId));

        // clear and then set filters and navigation indicating want filtered result set on Exceptions
        dispatch(clearExceptionFilters());
        let filters: ExceptionFilterSet = {
            orderNumberFilter: orderNumber ?? undefined,
            docTypeFilter: documentType,
            tradingPartnerIdFilter: tradingPartnerId ?? undefined,
        };
        dispatch(setExceptionFilters(filters));

        let basePath = getRootRoutePath();
        navigate(`${basePath}/exceptions-filtered`);
    };

    const onDownloadHtmlClick = (selectedRow?: TransactionResource) => {
        // save off the selected row to use for the base html download process  
        // triggered by setting clicked to true
        if (selectedRow) {
            setSelectedDataRow(selectedRow);
            setDownloadHtmlClicked(true);
        }
    };

    const onDownloadRawEdiClick = (selectedRow?: TransactionResource) => {
        // save off the selected row to use for the base raw edi download process  
        // triggered by setting clicked to true
        if (selectedRow) {
            setSelectedDataRow(selectedRow);
            setDownloadRawEdiClicked(true);
        }
    };

    const onDownload997Click = (selectedRow?: TransactionResource) => {
        // save off the selected row to use for the acknowledgement 997 download process  
        // triggered by setting clicked to true
        if (selectedRow) {
            setSelectedDataRow(selectedRow);
            setDownload997Clicked(true);
        }
    };

    const onResendDocumentClick = (selectedRow?: TransactionResource) => {
        // save off the selected row to use for opening the 
        // dialog for confirming the resend
        if (selectedRow) {
            setSelectedDataRow(selectedRow);
            setOpenResendDialog(true);
        }
    };

    const resendSuccessHandler = () => {
        // need to refetch to bring the resent update into the grid
        refetch();
    };

    const resendDialogCloseHandler = () => {
        setOpenResendDialog(false);
        setSelectedDataRow(undefined);
    };

    const downloadCompletedHandler = () => {
        setDownloadHtmlClicked(false);
        setDownloadRawEdiClicked(false);
        setDownload997Clicked(false);
        setSelectedDataRow(undefined);
    };

    const dataGridColumns: IColumnProps[] = [
        {
            dataField: 'documentNumber',
            caption: translate('grids.documentNumber'),
            calculateCellValue: getRowDocumentNumber,
            minWidth: '145px',
        },
        {
            dataField: 'orderNumber',
            caption: translate('grids.orderNumber'),
            calculateCellValue: getRowOrderNumber,
            allowFiltering: false,
            cellRender: (params) => {
                const { value } = params;
                if (value) {
                    return (
                        <LinkCell
                            params={{
                                value: value,
                                data: { id: value },
                                to: getRootRoutePath(),
                            }}
                            extraPreText="order-"
                        />
                    );
                } else {
                    return (value);
                }
            },
            minWidth: '125px',
        },
        {
            dataField: 'tradingPartnerName',
            caption: translate('grids.tradingPartner'),
            calculateCellValue: getRowTradingPartner,
            allowFiltering: false,
            cellRender: params => (
                <LinkRouter to={`/trading-partners/tradingPartner-${params.data.tradingPartnerId}`}>{params.value}</LinkRouter>
            ),
            minWidth: '200px',
        },
        {
            dataField: 'date',
            caption: translate('grids.dateReceived'),
            calculateCellValue: getRowDate,
            allowFiltering: false,
            format: { year: 'numeric', month: 'short', day: '2-digit' },
            minWidth: '200px',
        },
        {
            dataField: 'value',
            caption: translate('grids.value'),
            visible: displayValueColumn,
            allowFiltering: false,
            calculateCellValue: getRowValue,
            minWidth: '100px',
        },
        {
            dataField: 'lines',
            allowFiltering: false,
            caption: translate('grids.numberOfLines'),
            calculateCellValue: getRowNumberOfLines,
            minWidth: '100px',
        },
        {
            dataField: 'quantity',
            caption: translate('grids.totalQuantity'),
            calculateCellValue: getRowTotalQty,
            allowFiltering: false,
            minWidth: '100px',
        },
        {
            dataField: 'exceptions',
            headerCellRender: () => {
                return (<WarningIcon aria-label={translate('grids.exceptions')}/>)
            },
            calculateCellValue: getRowHasExceptions,
            allowFiltering: false,
            minWidth: '55px',
            cellRender: (params: { value: any; data: TransactionResource }) => {
                if (params.value) {
                    return (
                        <WarningIcon
                            aria-label={`${translate('grids.exceptionCheckOrder')} ${params.data.orderNumber}`}
                            className={classes.exceptionIcon}
                            onClick={() => onExceptionIconClick(params.data)}
                        />
                    ); // Little triangle alert icon
                }
                return <></>;
            },
        },
        {
            dataField: 'id',
            caption: '',
            width: '100px',
            alignment: 'right',
            allowFiltering: false,
            cellRender: (params: { value: any; data: any }) => {
                let ackStatus = params.data.documentAcknowledgementStatus;
                return (
                    <DownloadSplitButton
                        onDownloadHtmlClick={() => onDownloadHtmlClick(params.data)}
                        onDownloadRawEdiClick={() => onDownloadRawEdiClick(params.data)}
                        onDownload997Click={ackStatus && ackStatus !== 'unacknowledged' ? () => onDownload997Click(params.data) : undefined}
                    />
                )
            },
            headerCellRender: () => {
                if (orderNumber) {
                    return (
                        <Typography
                            variant="button"
                            className={classes.downloadAll}
                            visibility={"hidden"}
                            onClick={() => {
                                // TODO: Hook to Download all files when available...hiding for now since backend not ready
                            }}>
                            {`${translate('grids.downloadAll')}`}
                        </Typography>
                    )
                } else {
                    return <></>
                }
            }
        },
        {
            dataField: 'purchaseOrderId',
            caption: '',
            visible: isUserTenantAdmin,
            width: '70px',
            allowFiltering: false,
            cellRender: (params) => {
                let resendCount = params.data.resendCount;
                let resendDate = getFormattedDateTimeString(params.data.lastResendDate, { format: DateTime.DATETIME_SHORT });

                return (
                    <IconButton
                        className={resendCount > 0 ? classes.resendAgainButton : classes.resendButton}
                        title={resendCount > 0 ? `${translate('grids.lastResent')}: ${resendDate}` : `${translate('grids.resend')}`}
                        onClick={() => {
                            onResendDocumentClick(params.data);
                        }}
                    ><ResendIcon/></IconButton>
                );
            }
        },
    ];

    /* 
      Override of grid function to ContentReadyEvent
      of the grid to be able to call our own spinner
      to display during load 
    */
    const onContentReady = (e: ContentReadyEvent) => {
        // get the DataGrid instance
        let dataGrid = e.component as dxDataGrid;
        
        // when data is being loaded or fetched, 
        // begin custom loading to display spinner 
        // to indicate grid is loading 
        if ((isLoading || isFetching)) {
            if (!isCustomLoading)  {
                dataGrid.beginCustomLoading('');
                setIsCustomLoading(true); 
            }
        } else if (isCustomLoading) {
            // when the rows are actually loaded and we are still in custom loading,
            // then call to end loading, which hides the spinner 
            dataGrid.endCustomLoading();
            setIsCustomLoading(false); 
            // when paging, if user has scrolled to bottom, move scroll back to top
            var scrollable = dataGrid.getScrollable();  
            //scroll to top  
            scrollable.scrollTo(0);
        }
    };

    const gridOptions: IDataGridOptions = {
        dataSource: docTypeTransactions,
        height: '55vh',
        rowAlternationEnabled: true,
        sorting: {
            mode: 'none',
        },
        filterRow: {
            visible: true,
        },
        pager: {
            visible: false,
        },
        paging: {
            enabled: false,
        },
        noDataText: useDataGridNoDataText(isLoading || isFetching),
        onContentReady: onContentReady,
    };

    let paginationInfo = {
        totalItems: transactionsPaginationTotal,
        currentPage: (offset / pageSize),
        pageSize: pageSize,
        pageSizeOptions: isMobileScreen ? [5, 10, 25, 50, 100, 200, 500] : [25, 50, 100, 200, 500],
        onPageChange: (event: any, page: number) => {
            setOffset(page * pageSize);
        },
        //pageSizeOptions?: number[];
        onPageSizeChange: (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
            setPageSize(parseInt(event.target.value, 10));
            setOffset(0);
        }
    };

    const getGridColumns = () => {
        return dataGridColumns.map((col, index) => <Column key={index} {...col} />);
    };

    const getCards = () => {
        return docTypeTransactions.map((row, i) => (
            <MobileDataCard key={i}>
                {dataGridColumns.map((column, colIndex) => {
                    return (
                        colIndex === 0 ? (
                            <>
                                <LinkRouter
                                    key={colIndex}
                                    fontSize="1rem"
                                    fontWeight="medium"
                                    to={`${getRootRoutePath()}/order-${row.orderNumber}`}
                                >
                                    {column.calculateCellValue(row)}
                                    <LaunchIcon sx={{ marginLeft: 0.25, fontSize: '0.875rem' }} color="primary" fontSize="small" />
                                    <Divider sx={{ marginBlock: 0.25 }} />
                                </LinkRouter>
                            </>
                        ) : (
                            <MobileDataCardRow
                                key={colIndex}
                                title={column.caption}
                                value={column.calculateCellValue ? column.calculateCellValue(row) : ''}
                                linkTo={
                                    column.dataField === "exceptions" && (row.exceptions !== null && row.exceptions && Object.keys(row.exceptions).length > 0)
                                        ? (
                                            <WarningIcon
                                                className={classes.exceptionIconMobile}
                                                onClick={() => onExceptionIconClick(row)}
                                            />
                                        ) : undefined
                                }
                            />
                        ))

                })}
            </MobileDataCard>
        ));
    };

    const getSummaryTemplate = () => {
        if (isMobileScreen) {
            return <CardSummaryTemplate
                isBusy={isFetchingDocument || isLoading || isFetching}
                pageTitleText={header}
                cards={getCards()}
                gridPagination={paginationInfo} />;
        } else {
            return <SummaryTemplate
                isBusy={isFetchingDocument}
                pageTitleText={header}
                dataCard={{
                    header: dateFilterTitle,
                    label1: translate(titleKey) as string,
                    value1: `${summaryData?.count}`,
                    label2: displayValueColumn ? translate('cardSummary.totalValue') as string : undefined,
                    value2: displayValueColumn ? getSummaryTotalDisplayValue(summaryData.total, summaryData.currency) : undefined,
                    style: { boxShadow: '1px 1px 3px #00000014' },
                    keepDashStyle: true
                }}
                gridOptions={gridOptions}
                gridColumns={getGridColumns()}
                isMobile={isMobileScreen}
                gridPagination={paginationInfo}
            />
        }
    };

    return (
        <ListWithCommonActions
            tenantId={tenantId}
            pageViewId={getPageViewId()}
            translatedPageTitle={translate(titleKey)}
            summaryTemplate={getSummaryTemplate()}
            selectedRowData={selectedDataRow}
            openResendDialog={openResendDialog}
            downloadHtmlClicked={downloadHtmlClicked}
            downloadRawEdiClicked={downloadRawEdiClicked}
            downloadAcknowledgementClicked={download997Clicked}
            toggleIsFetchingDocument={toggleIsFetchingDocument}
            onResendSuccess={resendSuccessHandler}
            onResendDialogClose={resendDialogCloseHandler}
            onDownloadComplete={downloadCompletedHandler}
        />
    );
}

export default DocumentTypeTransactions;