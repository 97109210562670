import { PortalUserResource } from "../api/users.api";
import { isApteanSSOProvider } from "./security";

const getUserKey = (activeUserId: string | undefined, key: string, genericPortal?: boolean) => {
    let userKey = `EDIPortal_${key}`;
    if (genericPortal) {
        userKey = `Portal_${key}`;
    }
    if (activeUserId) {
        userKey = `${activeUserId}_${userKey}`;
    }
    return userKey;
}

const getPortalValueForUser = (activeUserId: string | undefined, value: string) => {
    let cookie = getPortalCookie(activeUserId, value);
    if (cookie) {       // if cookie is found, use that
        return cookie;
    }
    let local = getPortalLocalStorageItem(activeUserId, value);
    if (local) {        // else if local storage found, use that
        return local;
    }
    return undefined;
}

const setPortalValueForUser = (activeUserId: string | undefined, key: string, value: string) => {    
    // Set to local and cookie so we have a fall back
    setPortalCookie(activeUserId, key, value);
    setPortalLocalStorageItem(activeUserId, key, value);
}

export const canManageUsers = (isAdmin?: boolean, viewerUser?: PortalUserResource) => {
    // If the user is an admin, they can manage users
    if (isAdmin === true) {
        return true;
    }
    return false;
}

export const setLocalStorageItem = (activeUserId: string | undefined, key: string, value: string) => {
    let userKey = getUserKey(activeUserId, key);
    localStorage.setItem(userKey, value);
}

export const getLocalStorageItem = (activeUserId: string | undefined, key: string) => {
    let userKey = getUserKey(activeUserId, key);
    return localStorage.getItem(userKey);
}

export const setTenantLocalStorageItem = (activeTenantId: string, key: string, value: string) => {
    let tenantKey = `${activeTenantId}_${key}`;
    localStorage.setItem(tenantKey, value);
}

export const getTenantLocalStorageItem = (activeTenantId: string, key: string) => {
    let tenantKey = `${activeTenantId}_${key}`;
    return localStorage.getItem(tenantKey);
}

export const getLocalStorageItemAsBoolean = (activeUserId: string | undefined, key: string) => {
    let userKey = getUserKey(activeUserId, key);
    let returnVal = localStorage.getItem(userKey);

    return Boolean(returnVal);
}

export const removeLocalStorageItem = (activeUserId: string | undefined, key: string) => {
    let userKey = getUserKey(activeUserId, key);
    localStorage.removeItem(userKey);
}

// Portal Local Storage Items have the generic 'Portal' key rather than 'EDIPortal'.  To be used across portals
export const setPortalLocalStorageItem = (activeUserId: string | undefined, key: string, value: string) => {
    let userKey = getUserKey(activeUserId, key, true);
    localStorage.setItem(userKey, value);
}

export const getPortalLocalStorageItem = (activeUserId: string | undefined, key: string) => {
    let userKey = getUserKey(activeUserId, key, true);
    return localStorage.getItem(userKey);
}

export const getPortalLocalStorageItemAsBoolean = (activeUserId: string | undefined, key: string) => {
    let userKey = getUserKey(activeUserId, key, true);
    let returnVal = localStorage.getItem(userKey);

    return Boolean(returnVal);
}

export const setPortalCookie = (activeUserId: string | undefined, key: string, value: string) => {
    let userKey = getUserKey(activeUserId, key, true);
    document.cookie = userKey + "=" + value + "; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT; domain=apteansharedservices.com";
}

export const getPortalCookie = (activeUserId: string | undefined, key: string) => {
    let userKey = getUserKey(activeUserId, key, true);
    // adapted from stack-overflow answer
    let match = document.cookie.match(new RegExp('(^| )' + userKey + '=([^;]+)'));
    return match ? match[2] : undefined;
}

export const getDarkModeForUser = (activeUserId: string | undefined) => {
    return getPortalValueForUser(activeUserId, "darkMode");
}

export const setDarkModeForUser = (activeUserId: string | undefined, value: string) => {
    setPortalValueForUser(activeUserId, "darkMode", value);
}

export const getLanguageForUser = (activeUserId: string | undefined) => {
    return getPortalValueForUser(activeUserId, "userLang");
}

export const setLanguageForUser = (activeUserId: string | undefined, value: string) => {
    setPortalValueForUser(activeUserId, "userLang", value);

}