import axios from 'axios';
import { EdiActionResult, GetV1CustomersEditClientTransactionApiArg, ClientTransactionResource } from '../../api/customers.api';
import { AppDispatch } from '../../app/store';
import { getAccessToken } from "../../api/empty.api";
import { setError, setSaveStatus } from './ClientTransactionsSlice';

export enum NotificationType {
  notify,
  bulkNotify,
  disabled
};

// extension of ClientTransactionResource to include notificationType
// to transform shouldNotify and shouldBulkNotify into a single field
export type ClientTransactionResourceExtended = ClientTransactionResource & {
  notificationType: NotificationType;
};

export const saveClientTransaction = (options: GetV1CustomersEditClientTransactionApiArg) =>
async (dispatch: AppDispatch): Promise<void> => {
  let url = `${process.env.REACT_APP_EDI_PORTAL_API_ENDPOINT}/v1/customers/edit-client-transaction`;
  const token = await getAccessToken();
    if (token) {
      axios({
        method: 'get',
        url: url as string,
        headers: { Authorization: `Bearer ${token}` },
        params: {
          clientTransactionId: options.clientTransactionId,
          shouldNotify: options.shouldNotify,
          shouldBulkNotify: options.shouldBulkNotify,
          userEmailAddress: options.userEmailAddress,
        }
      })
      .then (response => {
        if (response && response.data) {
          dispatch(setSaveStatus(response.data as EdiActionResult));
        }
    })
    .catch(ex => {
      dispatch(setError(ex));
    })
  }
};
