import { TopTradingPartnersChartData } from '../../components/molecules/TopTradingPartnersChart';
import {
  PeriodicActivityResource,
  TopTradingPartnerResource,
  NormalizedDocumentType,
  TransactionTotalResource,
} from '../../api/dashboard.api';
import { stringDisplay, numberDisplay } from '../../utils/dataDisplayPipe';
import { defaultCurrencyCode } from '../../utils/Constants';

export interface ActivityCoreChartData {
  date: string;
  orders: number;
  shipments: number;
  invoices: number;
  acknowledgements: number;
};

export interface ActivityVendorChartData {
  date: string;
  orders: number;
  shipments: number;
  invoices: number;
  acknowledgements: number;
};

export interface Activity3plChartData {
  date: string;
  shippingOrders: number;
  shippingAdvice: number;
  inventoryAdvice: number;
  transfers: number;
  receipts: number;
};

export const mapTopTradingPartners = (partners: TopTradingPartnerResource[] | undefined) => {
  if (!partners) return [];
  // Devextreme chart needs to take in the array of partners in reverse order in order to display the most active partner at the top.
  // To account for possible duplicate partner names, we are concatenating an ordered number before each partner name.
  const sortedPartners: TopTradingPartnersChartData[] = [...partners].reverse().map((p: TopTradingPartnerResource, count) => ({
    partner: `${partners.length - count}. ${stringDisplay(p.tradingPartnerName, 'trading partner name')}`,
    po: numberDisplay(p.purchaseOrderSum, 'purchase orders'),
    invoice: numberDisplay(p.invoiceSum, 'invoices'),
    id: stringDisplay(p.tradingPartnerId, 'trading partner id'),
  }));
  return sortedPartners;
};

export const getDocTypeCount = (data: TransactionTotalResource[] | undefined, docType: NormalizedDocumentType) => {
  if (!data) return 0;
  const doc = data.find(doc => {
    return doc.documentType === docType;
  });
  return numberDisplay(doc?.count, `${docType} count`);
};

export const getDocTypeAmount = (data: TransactionTotalResource[] | undefined, docType: NormalizedDocumentType) => {
  if (!data) return 0;
  const doc = data.find(doc => {
    return doc.documentType === docType;
  });
  if (!doc || !doc.amounts || !doc.amounts[0]) return 0;
  return numberDisplay(doc.amounts[0].amount, `${docType} amount`);
};

export const getDocTypeCurrency = (data: TransactionTotalResource[] | undefined, docType: NormalizedDocumentType) => {
  if (!data) return defaultCurrencyCode;
  const doc = data.find(doc => {
    return doc.documentType === docType;
  });
  if (!doc || !doc.amounts || !doc.amounts[0]) return defaultCurrencyCode;
  return doc.amounts[0].currency ?? defaultCurrencyCode;
};

export const mapCoreActivityData = (data: PeriodicActivityResource[] | undefined) => {
  if (!data) return [];
  const mappedActivity: ActivityCoreChartData[] = [];
  data.forEach(activity => {
    if (activity.startDate && activity.transactionTotals) {
      mappedActivity.push({
        date: activity.startDate, // leave in iso format and format within chart component for localization at points of display
        orders: getDocTypeCount(activity.transactionTotals, 'purchaseOrder'),
        invoices: getDocTypeCount(activity.transactionTotals, 'invoice'),
        shipments: getDocTypeCount(activity.transactionTotals, 'shipment'),
        acknowledgements: getDocTypeCount(activity.transactionTotals, 'acknowledgement'),
      });
    }
  });
  return mappedActivity;
};

export const mapVendorActivityData = (data: PeriodicActivityResource[] | undefined) => {
  if (!data) return [];
  const mappedActivity: ActivityVendorChartData[] = [];
  data.forEach(activity => {
    if (activity.startDate && activity.transactionVendorTotals) {
      mappedActivity.push({
        date: activity.startDate, // leave in iso format and format within chart component for localization at points of display
        orders: getDocTypeCount(activity.transactionVendorTotals, 'purchaseOrderOutbound'),
        invoices: getDocTypeCount(activity.transactionVendorTotals, 'invoiceInbound'),
        shipments: getDocTypeCount(activity.transactionVendorTotals, 'shipmentInbound'),
        acknowledgements: getDocTypeCount(activity.transactionVendorTotals, 'acknowledgementInbound'),
      });
    }
  });
  return mappedActivity;
};

export const map3plActivityData = (data: PeriodicActivityResource[] | undefined) => {
  if (!data) return [];
  const mappedActivity: Activity3plChartData[] = [];
  data.forEach(activity => {
    if (activity.startDate && activity.transaction3plTotals) {
      mappedActivity.push({
        date: activity.startDate, // leave in iso format and format within chart component for localization at points of display
        shippingOrders: getDocTypeCount(activity.transaction3plTotals, 'warehouseShippingOrder'),
        shippingAdvice: getDocTypeCount(activity.transaction3plTotals, 'warehouseShippingAdvice'),
        inventoryAdvice: getDocTypeCount(activity.transaction3plTotals, 'warehouseInventoryAdjustmentAdvice'),
        transfers: getDocTypeCount(activity.transaction3plTotals, 'stockTransfer'),
        receipts: getDocTypeCount(activity.transaction3plTotals, 'stockReceipt')
     });
    }
  });
  return mappedActivity;
};
