import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../app/hooks';
import { useDataGridNoDataText } from '../../utils/hooks/useDataGridNoDataText';

import { Grid, Theme, useMediaQuery } from '@mui/material';
import DataGrid, { Column, IColumnProps, IDataGridOptions } from 'devextreme-react/data-grid';

import { BillingAggregatesResource, useGetV1CustomersClientBillingQuery } from '../../api/customers.api';
import { selectIsUserCustomerTenantAdmin, selectTenantId } from '../app/AppSlice';

import { MobileDataCardRow } from '../../components/atoms/MobileDataCardRow';
import { PageTitle } from '../../components/atoms/PageTitle';
import { MobileDataCard } from '../../components/molecules/MobileDataCard';
import { TableTemplate } from '../../components/organisms/TableTemplate';
import CardSummaryTemplate from '../../components/organisms/CardSummaryTemplate';
import { Helmet } from 'react-helmet-async';
import { buildAIContentDescription } from '../../utils/helpers/functions';


export const ClientBillingList = () => {
  const isMobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const gridRef = useRef<DataGrid | null>(null);

  const [contentHeight, setContentHeight] = useState(0);
  const [titleHeight, setTitleHeight] = useState(0);
  
  const tenantId = useAppSelector(selectTenantId);
  const isUserTenantAdmin = useAppSelector(selectIsUserCustomerTenantAdmin);
  
  const { data: billingData, isLoading, isFetching } = useGetV1CustomersClientBillingQuery(
    {
      tenantId: tenantId as string,
    },
    { 
      skip: !tenantId
    }
  );
  
  const { t: translate } = useTranslation();

  const isDataProcessing = isLoading || isFetching;
  
  useEffect(() => {
    // once roles are loaded, validate the current user has the
    // admin role to view this screen
    // if not, redirect to home screen
    if (isUserTenantAdmin === false) {
      navigate("/");
    }
  }, [isUserTenantAdmin]);

  useEffect(() => {
    if (!isDataProcessing) {
      setContentHeight(document.getElementById('billing-list-content-area')?.clientHeight || 0);
      setTitleHeight(document.getElementById('billing-list-title-container')?.clientHeight || 0);
    }
  }, [isDataProcessing]);


  const dataGridColumns: IColumnProps[] = [
    {
      dataField: 'year',
      caption: translate('grids.billing.year'),
      allowSorting: true,
      sortIndex: 0,
      sortOrder: 'desc',
      alignment: 'left',
    },
    {
      dataField: 'month',
      caption: translate('grids.billing.month'),
      allowSorting: true,
      sortIndex: 1,
      sortOrder: 'desc',
      alignment: 'left',
    },
    { 
      dataField: 'currencyCode',
      caption: translate('grids.billing.currency'),
      allowSorting: false,
      alignment: 'left',
    },
    { 
      dataField: 'totalDocuments',
      caption: translate('grids.billing.totalDocs'),
      alignment: 'left',
    },
    { 
      dataField: 'totalLines',
      caption: translate('grids.billing.totalLines'),
      alignment: 'left',
    },
    { 
      dataField: 'serviceFee',
      caption: translate('grids.billing.fee'),
      alignment: 'left',
    },
    { 
      dataField: 'documentCharges',
      caption: translate('grids.billing.docCharges'),
      alignment: 'left',
    },
    { 
      dataField: 'lineCharges',
      caption: translate('grids.billing.lineCharges'),
      alignment: 'left',
    },
    { 
      dataField: 'totalCharges',
      caption: translate('grids.billing.totalCharges'),
      alignment: 'left',
    },
  ];

  // calculate the height of the grid based on the content area
  // to allow for scrolling of the grid with the header fixed
  const getGridHeight = () => {
    // allow for some padding at the bottom of the grid      
    let remainingHeightArea = contentHeight - titleHeight - 16;
    if (remainingHeightArea > 0) {
      return `${remainingHeightArea}px`;
    }
    return '100%';
  };

  const gridOptions: IDataGridOptions = {
    dataSource: billingData,
    loadPanel: {
      enabled: true,
      showIndicator: true,
    },
    keyExpr: 'billingAggregatesId',
    rowAlternationEnabled: true,
    height: getGridHeight(),
    scrolling: {
      mode: 'virtual',
    },
    sorting: {
      mode: 'multiple',
    },
    noDataText: useDataGridNoDataText(isLoading || isFetching),
  };

  const getDisplayText = (): string => {
    return translate('billing.title');
  };

  const getMobileCards = () => {
    return billingData?.map((billingAggregates: BillingAggregatesResource) => 
    (
      <MobileDataCard key={billingAggregates?.billingAggregatesId}>
        {dataGridColumns.map((column, index) =>
          (
            <MobileDataCardRow
              key={index}
              title={column.caption}
              value={''}
              linkTo={column.calculateDisplayValue ? column.calculateDisplayValue(billingAggregates) : column.cellRender ? column.cellRender({data: billingAggregates}) : undefined}
            />
          ),
        )}
      </MobileDataCard>
    ));
  };

  const getMobileTemplate = () => {
    if (isMobileScreen) {
        return (
            <Grid>
                <CardSummaryTemplate
                    isBusy={isLoading || isFetching}
                    cards={getMobileCards()}
                    pageTitleText={getDisplayText()} />
            </Grid>
        );
    }
    return undefined;
  };

  return (
    <>
    <Helmet>
      <meta name="ai:viewId" content="billing"></meta>
      <meta name="ai:viewDescription" content={buildAIContentDescription(translate('billing.title'))}></meta>
    </Helmet>
    {isMobileScreen ? (
      getMobileTemplate()
    ) : (
      <Grid id='billing-list-content-area' height={'100%'}>
        <Grid id='billing-list-title-container' container mb={1} justifyContent="space-between" alignItems="center">
          <PageTitle title={getDisplayText()} gutterBottom={false} />
        </Grid>
        <TableTemplate ref={gridRef} gridOptions={gridOptions}>
            {dataGridColumns.map((col, i) => (
              <Column key={i} {...col} />
            ))}
        </TableTemplate>
      </Grid>
    )}
    </>
  );
  
};
