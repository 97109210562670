import { FC, useCallback, useEffect } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { DateTime } from 'luxon';
import { setToastConfig } from '../../features/app/AppSlice';
import { TransactionResource } from '../../api/customers.api';
import { GetV1PurchaseOrdersByTenantIdHtmlDocumentApiArg, GetV1PurchaseOrdersByTenantIdResendDocumentApiArg, GetV1PurchaseOrdersByTenantIdRawEdiDocumentApiArg, GetV1PurchaseOrdersAcknowledgementDocumentApiArg } from '../../api/purchaseOrders.api';
import { getAcknowledgementDocument, getHtmlDocument, getRawEDIDocument, resendDocument } from '../../features/purchaseOrders/PurchaseOrdersAPI';
import { selectDocument, selectDocumentName, selectGetAcknowledgementDocumentStatus, selectGetHtmlDocumentStatus, selectGetRawEdiDocumentStatus, selectDocumentError, clearDownload, clearResend, selectResendFileStatus, selectResendError } from '../../features/purchaseOrders/PurchaseOrdersSlice';
import ResendDocumentDialog from './ResendDocumentDialog';
import { buildAIContentDescription, getDocumentTypeName } from '../../utils/helpers/functions';
import { getFormattedDateTimeString } from '../../utils/helpers/dateTimeUtil';
import { useTranslation } from 'react-i18next';
import { ToastSeverity } from '../../utils/Constants';
import { downloadDocument } from '../../utils/helpers/fileUtil';
import { Helmet } from 'react-helmet-async';



interface ListWithCommonActionsProps {
  tenantId?: string;
  pageViewId: string;
  translatedPageTitle: string;
  summaryTemplate: ReactJSXElement;
  selectedRowData: TransactionResource | undefined;
  openResendDialog: boolean;
  downloadHtmlClicked: boolean;
  downloadRawEdiClicked: boolean;
  downloadAcknowledgementClicked?: boolean;
  toggleIsFetchingDocument: (isFetching: boolean) => void;
  onResendSuccess: () => void;
  onResendDialogClose: () => void;
  onDownloadComplete: () => void;
  
}

const ListWithCommonActions: FC<ListWithCommonActionsProps> = props => {
  const { tenantId, pageViewId, translatedPageTitle, summaryTemplate, selectedRowData, openResendDialog, downloadAcknowledgementClicked=false, downloadHtmlClicked, downloadRawEdiClicked, onResendDialogClose, onDownloadComplete, toggleIsFetchingDocument, onResendSuccess } = props;
  const document = useAppSelector(selectDocument);
  const documentName = useAppSelector(selectDocumentName);
  const documentError = useAppSelector(selectDocumentError);
  const getHtmlStatus = useAppSelector(selectGetHtmlDocumentStatus);
  const getRawEdiStatus = useAppSelector(selectGetRawEdiDocumentStatus);
  const getAcknowledgementStatus = useAppSelector(selectGetAcknowledgementDocumentStatus);
  const resendDocStatus = useAppSelector(selectResendFileStatus);
  const resendDocError = useAppSelector(selectResendError);

  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
      
  const downloadHtml = useCallback(() => {
    if (getHtmlStatus && getHtmlStatus.wasSuccessful === true) {
      if (document) {
        var uniqueId = selectedRowData?.id ? selectedRowData.id : new Date().toISOString();
        var fileName = `${documentName ? documentName : 'htmlFile_' + uniqueId}.html`;
        downloadDocument(document, fileName, 'text/html', true);
      }
    }
  },[getHtmlStatus, document, documentName]);

  const downloadRawEdi = useCallback(() => {
    if (getRawEdiStatus && getRawEdiStatus.wasSuccessful === true) {
      if (document) {
        var fileName = `${documentName ? documentName : 'rawEdiFile'}.txt`;
        downloadDocument(document, fileName, 'text/plain');
      }
    }
  },[getRawEdiStatus, document, documentName]);

  const downloadAcknowledgement = useCallback(() => {
    if (getAcknowledgementStatus && getAcknowledgementStatus.wasSuccessful === true) {
      if (document) {
        var fileName = '997File.txt';
        downloadDocument(document, fileName, 'text/plain');
      }
    }
  },[getAcknowledgementStatus, document]);
  
  useEffect(() => {
    if (resendDocStatus) {
      if (resendDocStatus.wasSuccessful === true) {
        // confirmation that sent was successful 
          // canned response due to response only containing errors,
          // but we can translate client-side strings
        dispatch(setToastConfig({
          message: translate("resendDocumentDialog.resendSuccess"),
          severity: ToastSeverity.Success
        }));

        onResendSuccess();
      } else {
        dispatch(setToastConfig({
          message: resendDocStatus.message,
          severity: ToastSeverity.Error
        }));
      } 
      resetResendDocSequence();
    } else if(resendDocError) {
      dispatch(setToastConfig({
        message: resendDocError.message,
        severity: ToastSeverity.Error
      }));
      resetResendDocSequence();
    }
  },[resendDocStatus, resendDocError])

  useEffect(() => {
    var message;
    if (getHtmlStatus) {
      toggleIsFetchingDocument(false);
      if (getHtmlStatus.wasSuccessful === false) {
        message = getHtmlStatus.message;
      } else {
        downloadHtml();
      }
      resetDownloadDocSequence();
    } else if (getRawEdiStatus) {
      toggleIsFetchingDocument(false);
      if (getRawEdiStatus.wasSuccessful === false) {
        message = getRawEdiStatus.message;
      } else {
        downloadRawEdi();
      }
      resetDownloadDocSequence();
    } else if (getAcknowledgementStatus) {
      toggleIsFetchingDocument(false);
      if (getAcknowledgementStatus.wasSuccessful === false) {
        message = getAcknowledgementStatus.message;
      } else {
        downloadAcknowledgement();
      }
      resetDownloadDocSequence();
    } else if (documentError) {
      toggleIsFetchingDocument(false);
      message = documentError.message;
      resetDownloadDocSequence();
    }
    if (message) {
      // display error toast message with reason why not successful 
      dispatch(setToastConfig({
        message: message,
        severity: ToastSeverity.Error
      }));
    }
  },[getHtmlStatus, getRawEdiStatus, getAcknowledgementStatus, documentError, downloadHtml, downloadRawEdi, downloadAcknowledgement])
  
  useEffect(() => {
    if (downloadHtmlClicked) {
      onDownloadHtmlClick();
    }
  },[downloadHtmlClicked]);

  useEffect(() => {
    if (downloadRawEdiClicked) {
      onDownloadRawEdiClick();
    }
  },[downloadRawEdiClicked]);

  useEffect(() => {
    if (downloadAcknowledgementClicked) {
      onDownloadAcknowledgementClick();
    }
  },[downloadAcknowledgementClicked]);

  const onDownloadHtmlClick = () => {
    if (selectedRowData) {
      toggleIsFetchingDocument(true);

      dispatch(getHtmlDocument({
        tenantId: tenantId,
        transactionId: selectedRowData.id,
        internalTransactionId: selectedRowData.internalTransactionId
      } as GetV1PurchaseOrdersByTenantIdHtmlDocumentApiArg));
    }
  };

  const onDownloadRawEdiClick = () => {
    if (selectedRowData) {
      toggleIsFetchingDocument(true);

      dispatch(getRawEDIDocument({
        tenantId: tenantId,
        transactionId: selectedRowData.id,
        internalTransactionId: selectedRowData.internalTransactionId
      } as GetV1PurchaseOrdersByTenantIdRawEdiDocumentApiArg));
    }
  };

  const onDownloadAcknowledgementClick = () => {
    if (selectedRowData) {
      toggleIsFetchingDocument(true);

      dispatch(getAcknowledgementDocument({
        internalTransactionId: selectedRowData.internalTransactionId
      } as GetV1PurchaseOrdersAcknowledgementDocumentApiArg));
    }
  };

  const resetDownloadDocSequence = () => {
    dispatch(clearDownload());
    onDownloadComplete();
  };

  const resetResendDocSequence = () => {
    dispatch(clearResend());
    onResendDialogClose();
  };

  const onResendDialogConfirm = () => {
    // resend the selected document
    if (selectedRowData) {
            
      dispatch(resendDocument({
        tenantId: tenantId,
        transactionId: selectedRowData.id,
        internalTransactionId: selectedRowData.internalTransactionId
      } as GetV1PurchaseOrdersByTenantIdResendDocumentApiArg))
    }
  };

  const getResendDialogMessage = () => {
    let message = translate('grids.resendConfirmation');
    let resendCount = selectedRowData?.resendCount ?? 0;
    if (resendCount > 0) {
      let resendDate = getFormattedDateTimeString(selectedRowData?.lastResendDate as string, { format: DateTime.DATETIME_SHORT });
      message =  `${message}\n${translate('grids.lastResent')}: ${resendDate}`;
    }
    return message;
  };
  
  return (
    <>
      <Helmet>
            <meta name="ai:viewId" content={pageViewId}></meta>
            <meta name="ai:viewDescription" content={buildAIContentDescription(translatedPageTitle)}></meta>
      </Helmet>
      {summaryTemplate}
      <ResendDocumentDialog
        isOpen={openResendDialog}
        id={selectedRowData?.purchaseOrderId ?? ''}
        heading={`${translate('grids.resend')} ${getDocumentTypeName(selectedRowData?.documentType)}`}
        message={getResendDialogMessage()}
        resendCount={selectedRowData?.resendCount as number}
        onConfirm={onResendDialogConfirm}
        onReject={onResendDialogClose}
        errorMessage={resendDocError?.message}
      />
        
    </>
  );
}

export default ListWithCommonActions;