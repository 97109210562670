import axios from "axios";
import { AppDispatch } from "../../app/store";
import { getAccessToken } from "../../api/empty.api";
import { setFetchForExportError, setExportTransactionsStatus, setExportDocument } from "./TransactionsSlice";
import { GetV1CustomersByTenantIdExportTransactionsApiArg, NormalizedDocumentType } from "../../api/customers.api";


export const getDocumentTypes = () => {
    let types: NormalizedDocumentType[] = [];
    types.push('acknowledgement');
    types.push('applicationAdvice');
    types.push('control');
    types.push('creditDebitAdjustment');
    types.push('deliveryJustInTime');
    types.push('deliverySchedule');
    types.push('inventoryAdvice');
    types.push('invoice');
    types.push('organizationalRelationships');
    types.push('payment');
    types.push('productTransferResaleReport');
    types.push('purchaseOrder');
    types.push('purchaseOrderChange');
    types.push('remittanceAdvice');
    types.push('requestForQuotation');
    types.push('requestForRoutingInstructions');
    types.push('salesOrderStatus');
    types.push('salesOrderStatusRequest');
    types.push('shipment');
    types.push('shippingSchedule');
    types.push('stockReceipt');
    types.push('stockTransfer');
    types.push('taxcon');
    types.push('textMessage');
    types.push('warehouseInventoryAdjustmentAdvice');
    types.push('warehouseShippingAdvice');
    types.push('warehouseShippingOrder');
    
    return types;
  };



export const exportTransactions = (filters: GetV1CustomersByTenantIdExportTransactionsApiArg) => 
  async (dispatch: AppDispatch): Promise<void> =>{
    let url = `${process.env.REACT_APP_EDI_PORTAL_API_ENDPOINT}/v1/customers/${filters.tenantId}/export-transactions`;
    const token = await getAccessToken();
      if (token) {
        axios({
          method: 'get',
          url: url as string,
          headers: { Authorization: `Bearer ${token}` },
          params: {
            tenantId: filters.tenantId,
            startDate: filters.startDate,
            endDate: filters.endDate,
            orderNumber: filters.orderNumber,
            docNumber: filters.docNumber,
            docType: filters.docType,
            tradingPartnerName: filters.tradingPartnerName,
            hasException: filters.hasException,
            erpOrderNumber: filters.erpOrderNumber,
            transactionType: filters.transactionType
          }
        })
        .then (response => {
          if (response && response.data) {
            dispatch(setExportTransactionsStatus(response.data));
            if (response.data.wasSuccessful === true) {
              dispatch(setExportDocument(response.data.data as string));
            }
          }
      })
      .catch(ex => {
        dispatch(setFetchForExportError(ex));
      })
    }
  };
